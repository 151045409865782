// eslint-disable-next-line
import React from "react";
import { useSelector } from "react-redux";
import { AppState, IUserItemState } from "../../../interfaces/state";
import { IProviderData } from "../../../interfaces/components/user";
import { getLocalDate } from "../../../_helpers";

export function UserPageInfo() {
  const user: IUserItemState = useSelector((state: AppState) => state.user);

  return (
    <div className="card-body text-center">
      {user.item.user.photoURL ? (
        <img
          src={user.item.user.photoURL}
          style={{ width: "150px", height: "150px" }}
          className="card-img-top"
          alt="user"
        />
      ) : null}
      <div className="card-title">
        <h3 className="text-black h6">{user.item.user.displayName}</h3>
      </div>

      <div className="card-text">
        <h3 className="text-black h6" style={{ display: "inline-block" }}>
          &nbsp;&nbsp;{user.item.user.email}
        </h3>
      </div>

      <div className="card-text">
        <h3 className="text-black h6" style={{ display: "inline-block" }}>
          &nbsp;&nbsp;Creation Time: {getLocalDate(user.item.user.metadata.creationTime)}
        </h3>
      </div>

      <div className="card-text">
        <h3 className="text-black h6" style={{ display: "inline-block" }}>
          &nbsp;&nbsp;Last SignIn Time: {getLocalDate(user.item.user.metadata.lastSignInTime)}
        </h3>
      </div>

      <table className="table table-striped table-bordered text-center">
        <thead>
          <tr className="table-success">
            <th></th>
            <th>Login Provider</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {user.item.user.providerData.map((user: IProviderData, index: number) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{user.providerId}</td>
                <td>{user.email || user.phoneNumber}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
