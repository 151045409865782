// eslint-disable-next-line
import React from "react";
import { useDispatch } from "react-redux";
import { GoogleLogin } from "react-google-login";
import { AlertAction, AuthenticationAction } from "../../_actions";
import logo from "../../Assets/bringy.png";

export function Login({ location }: { location: any }) {
  const API_URI_CLIENT_ID: string = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
  const dispatch = useDispatch();
  const alertAction = new AlertAction();
  const authenticationAction = new AuthenticationAction();

  const handleGoogleSuccess = (response: any): void => {
    const tokenId: string = response.tokenId;
    dispatch(authenticationAction.getAgentProfile());
    dispatch(authenticationAction.toLogin(tokenId, location));
  };

  const handleGoogleFailure = () => {
    dispatch(alertAction.fail("Failed to Login with google. Please try again"));
  };

  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
      <img src={logo} alt="bringy" />
      <GoogleLogin
        clientId={API_URI_CLIENT_ID}
        buttonText="Login with Google"
        onSuccess={handleGoogleSuccess}
        onFailure={handleGoogleFailure}
        cookiePolicy={"single_host_origin"}
      />
    </div>
  );
}
