// eslint-disable-next-line
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { AlertAction, LocalizationAction } from "../../../_actions";
import { ILocalizationItem } from "../../../interfaces/components/localization";
import { ILocalizationProps } from "../../../interfaces/props";

export function LocalizationItem({ index, title, handleFileUpload }: ILocalizationItem) {
  const dispatch = useDispatch();
  const alertAction = new AlertAction();
  const localizationAction = new LocalizationAction();

  const [localizationProps, setLocalizationProps] = useState<ILocalizationProps>({ file: null, flag: false });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.files![0];
    const reader: any = new FileReader();
    reader.onload = function () {
      setLocalizationProps((localizationProps) => ({
        ...localizationProps,
        file: reader.result,
        flag: true,
      }));
    };
    reader.readAsText(input);
  };

  const downloadLocale = async (id: string) => {
    const data = await localizationAction.getDetails(id);
    if (data) {
      const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${id}.json`);
      document.body.appendChild(link);
      link.click();
      dispatch(alertAction.pass(`Successfuly download locale ${id}`));
    } else {
      dispatch(alertAction.fail(`Failed to import locale ${id} Please try later`));
    }
  };

  const downloadLocaleTemplate = async (id: string) => {
    const data = await localizationAction.getTranslationTemplate(id);
    if (data) {
      const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "translation.json");
      document.body.appendChild(link);
      link.click();
      dispatch(alertAction.pass("Successfully Download Translation"));
    } else {
      dispatch(alertAction.fail("Failed to import translation template. Please try later"));
    }
  };

  const handleRemoveLocale = (id: string) => {
    dispatch(localizationAction.remove(id));
  };

  return (
    <React.Fragment>
      <tr>
        <td>{index}</td>
        <td>{title}</td>
        <td className="d-flex flex-row justify-content-between">
          <Button variant="info" type="button" onClick={() => downloadLocale(title)}>
            Export JSON
          </Button>
          <Button variant="info" type="button" onClick={() => downloadLocaleTemplate(title)}>
            Translation
          </Button>
        </td>
        <td>
          <div className="custom-file">
            <input type="file" accept=".json" onChange={(event) => handleFileChange(event)} />
            {localizationProps.flag ? (
              <Button variant="info" type="button" onClick={() => handleFileUpload(title, localizationProps.file)}>
                Import JSON
              </Button>
            ) : (
              <Button variant="info" type="button" disabled={true}>
                Import JSON
              </Button>
            )}
          </div>
        </td>
        <td>
          <Button variant="danger" type="button" onClick={() => handleRemoveLocale(title)}>
            Delete
          </Button>
        </td>
      </tr>
    </React.Fragment>
  );
}
