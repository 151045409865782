import { orderConstants } from "../_constants";
import { IOrderAction, IOrderListAction } from "../interfaces/actions";
import { IOrderListState, IOrderItemState } from "../interfaces/state";
import { OrderStatus, OrderType, Sort } from "../_helpers/enums";
import { arrayUnique } from "../_helpers";

const initialOrderItemState: IOrderItemState = {
  item: null,
};

const initialOrderListState: IOrderListState = {
  items: [],
  flags: {
    sortBy: Sort.DESC,
    status: OrderStatus.FULFILLED,
    type: OrderType.INSURANCE,
  },
  startDate: null,
  endDate: null,
};

export function order(state: IOrderItemState = initialOrderItemState, action: IOrderAction) {
  switch (action.type) {
    case orderConstants.FETCH_ORDER_ITEM_REQUEST:
      return {
        item: {},
      };
    case orderConstants.FETCH_ORDER_ITEM_SUCCESS:
      return {
        item: action.order,
      };
    case orderConstants.FETCH_ORDER_ITEM_FAILURE:
      return {
        item: {},
      };
    default:
      return state;
  }
}

export function orders(state: IOrderListState = initialOrderListState, action: IOrderListAction) {
  switch (action.type) {
    case orderConstants.FETCH_ORDER_LIST_REQUEST:
      return {
        items: [],
        flags: action.flags,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case orderConstants.FETCH_ORDER_LIST_SUCCESS:
      return {
        ...state,
        items: arrayUnique(state.items.concat(action.orders || []), "orderId"),
      };
    case orderConstants.FETCH_ORDER_LIST_FAILURE:
      return {
        ...state,
        items: action.orders,
      };
    case orderConstants.ADD_ORDER_DATE_RANGE:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    default:
      return state;
  }
}
