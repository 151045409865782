// eslint-disable-next-line
import React from "react";
import { ICardProps } from "../../interfaces/props";

export function Card({ heading, body }: ICardProps) {
  return (
    <div className="d-flex flex-row justify-content-center align-content-center">
      <h6>{heading}: </h6> &nbsp;
      <text>{body}</text>
    </div>
  );
}
