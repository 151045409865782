// eslint-disable-next-line
import React from "react";
import { ITicketItemProps } from "../../../interfaces/props";
import { getLocalDate } from "../../../_helpers";

export function TicketItem({ ticket }: ITicketItemProps) {
  return (
    <React.Fragment>
      <tr>
        <td style={{ cursor: "pointer" }}>
          <a href={`/tickets/${ticket.uid}/${ticket.id}`}>{ticket.id}</a>
        </td>
        <td>{ticket.subject}</td>
        <td style={{ cursor: "pointer" }}>
          <a href={`/users/${ticket.uid}`}>{ticket.uid}</a>
        </td>
        <td style={{ whiteSpace: "pre-wrap" }}>
          {ticket.author?.hasOwnProperty("user")
            ? `${ticket.author?.user || "Not Provided"}\n( user )`
            : `${ticket.author?.agent || "Not Provided"}\n( agent )`}
        </td>
        <td>{getLocalDate(ticket.timestamp)}</td>
        <td>{ticket.hasNewUserResponse ? "Yes" : "No"}</td>
        <td>{ticket.isOpen ? "Opened" : "Closed"}</td>
      </tr>
    </React.Fragment>
  );
}
