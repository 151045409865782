// eslint-disable-next-line
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { UserPageID, UserDataEdit, UserDataList, UserPageAction } from "..";
import { checkState, UserStatus, VerificationType } from "../../../_helpers";
import { IUserPageVerificationProps } from "../../../interfaces/props";

export function UserPageVerificationID({ user, userId }: IUserPageVerificationProps) {
  const IdState: string = user.nationalId.state;
  const [editFlag, setEditFlag] = useState(false);

  const handleEditUserData = () => {
    setEditFlag(true);
  };

  const changeEditFlag = () => {
    setEditFlag(false);
  };

  return (
    <div
      className={"d-flex flex-column align-items-center card-body text-white"}
      style={
        checkState(IdState, UserStatus.VERIFIED)
          ? { backgroundColor: "darkseagreen", margin: "10px", padding: "10px" }
          : { backgroundColor: "darkgray", margin: "10px", padding: "10px" }
      }
    >
      <div>
        <h3 className="h6">ID Verification</h3>
        <FontAwesomeIcon icon={checkState(IdState, UserStatus.VERIFIED) ? faCheckCircle : faExclamationCircle} />
        &nbsp;&nbsp;{IdState}
      </div>
      {checkState(IdState, UserStatus.VERIFIED) && !editFlag ? (
        <div className="w-25">
          <Button block variant="info" type="button" onClick={() => handleEditUserData()}>
            Edit
          </Button>
        </div>
      ) : null}
      {checkState(IdState, UserStatus.VERIFIED) ? (
        <div className="container-fluid w-75">
          <div className="d-flex flex-row align-items-center">
            <UserPageID imagesUrls={[user.nationalId.request!.nationalIdFrontUrl!, user.nationalId.request!.nationalIdBackUrl!]} />
            {editFlag ? (
              <UserDataEdit userId={userId} idData={user.nationalId.request!.idData!} verificationId={user.nationalId.request!.verificationId!} action={() => changeEditFlag()} />
            ) : (
              <UserDataList width="col-6" idData={user.nationalId.request!.idData!} />
            )}
          </div>
        </div>
      ) : null}
      &nbsp;
      {checkState(IdState, UserStatus.PENDING) ? (
        <div className="container-fluid w-75">
          <div className="d-flex flex-row align-items-center">
            <UserPageID imagesUrls={[user.nationalId.request!.nationalIdFrontUrl!, user.nationalId.request!.nationalIdBackUrl!]}/>
            <UserPageAction
              userId={userId}
              requestId={user.nationalId.request!.requestId!}
              type={VerificationType.NATIONALID}
              timestamp={user.nationalId.request!.timestamp!}
              bucketPath={user.nationalId.request!.bucketPath!}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
