// eslint-disable-next-line
import React from "react";
import { FormGroup, Label, Input, Form, Button } from "reactstrap";
import { IDateSearchProps } from "../../interfaces/props";

export function DateFilter({ startDate, endDate, handleFormData }: IDateSearchProps) {
  return (
    <Form>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row justify-content-end align-content-end">
          <FormGroup>
            <Label>Start Date</Label>
            <Input type="date" id="startDate" defaultValue={startDate} />
          </FormGroup>
          <FormGroup>
            <Label>End Date</Label>
            <Input type="date" id="endDate" defaultValue={endDate} />
          </FormGroup>
        </div>
        <div style={{ marginLeft: "10px", marginBottom: "-15px" }}>
          <Button color="danger" type="button" onClick={handleFormData}>
            Filter
          </Button>
        </div>
      </div>
    </Form>
  );
}
