import { verificationConstants } from "../_constants";
import { arrayUnique } from "../_helpers";
import { IVerificationListAction, IVerificationAction, IVerificationDetailsAction } from "../interfaces/actions";
import { IVerificationListState, IUserVerificationState } from "../interfaces/state";

const initialVerificationListState: IVerificationListState = {
  items: [],
  flags: {
    sortBy: "desc",
    status: "agent",
    type: "nationalId",
  },
};

export function verifications(
  state: IVerificationListState = initialVerificationListState,
  action: IVerificationListAction,
) {
  switch (action.type) {
    case verificationConstants.FETCH_VERIFICATION_LIST_REQUEST:
      return {
        items: [],
        flags: action.flags,
      };
    case verificationConstants.FETCH_VERIFICATION_LIST_SUCCESS:
      return {
        items: arrayUnique(state.items.concat(action.verifications || []), "verificationId"),
        flags: action.flags,
      };
    case verificationConstants.FETCH_VERIFICATION_LIST_FAILURE:
      return {
        items: action.verifications,
      };
    case verificationConstants.UPDATE_VERIFICATION_STATUS:
      return {
        ...state,
        items: [
          ...state.items.map((item) => {
            if (item.verificationId === action.payload?.id) item.status = action.payload?.status;
            return item;
          }),
        ],
      };
    default:
      return state;
  }
}

const initialVerificationState: IUserVerificationState = {
  items: [],
};

export function userVerifications(
  state: IUserVerificationState = initialVerificationState,
  action: IVerificationAction,
) {
  switch (action.type) {
    case verificationConstants.FETCH_USER_VERIFICATIONS_REQUEST:
      return {
        items: [],
      };
    case verificationConstants.FETCH_USER_VERIFICATIONS_SUCCESS:
      return {
        items: arrayUnique(state.items.concat(action.verifications || []), "verificationId"),
      };
    case verificationConstants.FETCH_USER_VERIFICATIONS_FAILURE:
      return {
        items: action.verifications,
      };
    default:
      return state;
  }
}

export function verificationDetails(state = {}, action: IVerificationDetailsAction) {
  switch (action.type) {
    case verificationConstants.FETCH_VERIFICATION_DETAILS_SUCCESS:
      return {
        items: action.verification,
      };
    case verificationConstants.FETCH_VERIFICATION_DETAILS_FAILURE:
      return {
        items: action.verification,
      };
    default:
      return state;
  }
}
