export const ticketConstants = {
  FETCH_TICKET_LIST_SUCCESS: "FETCH_TICKET_LIST_SUCCESS",
  FETCH_TICKET_LIST_FAILURE: "FETCH_TICKET_LIST_FAILURE",
  FETCH_TICKET_LIST_REQUEST: "FETCH_TICKET_LIST_REQUEST",

  FETCH_TICKET_ITEM_SUCCESS: "FETCH_TICKET_ITEM_SUCCESS",
  FETCH_TICKET_ITEM_FAILURE: "FETCH_TICKET_ITEM_FAILURE",
  FETCH_TICKET_ITEM_REQUEST: "FETCH_TICKET_ITEM_REQUEST",

  FETCH_TICKET_RESPONSES_SUCCESS: "FETCH_TICKET_RESPONSES_SUCCESS",
  FETCH_TICKET_RESPONSES_FAILURE: "FETCH_TICKET_RESPONSES_FAILURE",

  ADD_TICKET_DATE_RANGE: "ADD_TICKET_DATE_RANGE",
};
