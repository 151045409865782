// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Button, ButtonGroup } from "react-bootstrap";
import { PartnerItem } from "..";
import { Loading } from "../../Loading";
import { SearchBar } from "../../SearchBar";
import { NoDataFound } from "../../NoDataFound";
import { PartnerAction } from "../../../_actions";
import { isNotEmpty, history } from "../../../_helpers";
import { IPartnerListState, ILoadingState, AppState } from "../../../interfaces/state";
import { IPartnerListProps } from "../../../interfaces/props";
import { IPartnerData, IUsersPartnerData } from "../../../interfaces/components/partner";

export function PartnerList() {
  const dispatch = useDispatch();
  const partnerAction = new PartnerAction();

  const { partners, loading }: { partners: IPartnerListState; loading: ILoadingState } = useSelector(
    (state: AppState) => ({
      partners: state.partners,
      loading: state.loading,
    }),
  );

  const [partnerListProps, setPartnerListProps] = useState<IPartnerListProps>({
    searchTerm: "",
    searchResults: [],
    flags: {
      partnerId: null,
    },
  });

  const createUserForPartner = () => {
    history.push("/partners/createUser");
  };

  const createPartner = () => {
    history.push("/partners/createPartner");
  };

  useEffect(() => {
    dispatch(partnerAction.getUsers(partnerListProps.flags.partnerId!));
    setPartnerListProps((partnerListProps) => ({
      ...partnerListProps,
      searchResults: [],
      searchTerm: "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, partnerListProps.flags]);

  const handleSearchByEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (isNotEmpty(partners.users)) {
      const results = partners.users.filter((user: IUsersPartnerData) =>
        user.email.toLowerCase().includes(event.target.value),
      );
      setPartnerListProps((partnerListProps) => ({
        ...partnerListProps,
        searchTerm: event.target.value,
        searchResults: [...results],
      }));
    }
  };

  const showPartners = (partnerId?: string | null) => {
    setPartnerListProps((partnerListProps) => ({
      ...partnerListProps,
      flags: { ...partnerListProps.flags, partnerId: partnerId ? partnerId : null },
    }));
  };

  const renderData = () => {
    if (loading.isLoading) {
      return (
        <tr>
          <td colSpan={7}>
            <Loading />
          </td>
        </tr>
      );
    } else {
      if (partnerListProps.searchResults.length !== 0) {
        return partnerListProps.searchResults.map((user: IUsersPartnerData, index: number) => (
          <PartnerItem key={index} user={user} index={index + 1} showPartners={showPartners}></PartnerItem>
        ));
      } else if (
        isNotEmpty(partners.users) &&
        partners.users !== null &&
        typeof partners.users === "object" &&
        partners.users.length !== 0 &&
        !partnerListProps.searchTerm
      ) {
        return partners.users.map((user: IUsersPartnerData, index: number) => (
          <PartnerItem key={index} user={user} index={index + 1} showPartners={showPartners}></PartnerItem>
        ));
      } else {
        return (
          <tr>
            <td colSpan={7}>
              <NoDataFound data={"No partners are found"} />
            </td>
          </tr>
        );
      }
    }
  };

  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
      <div className="container-fluid d-flex flex-row justify-content-between align-items-center">
        <nav className="navbar navbar-light bg-light">
          <SearchBar title="Search by user email" value={partnerListProps.searchTerm} action={handleSearchByEmail} />

          <Dropdown as={ButtonGroup}>
            <Button variant="light">Partner : </Button>
            <Dropdown.Toggle variant="info" id="dropdown-split-basic">
              {partnerListProps.flags.partnerId ? partnerListProps.flags.partnerId : "not assigned"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {partners.partnerList.map((partner: IPartnerData, index: number) => {
                return (
                  <Dropdown.Item key={index} onClick={() => showPartners(partner.id)}>
                    {partner.id}
                  </Dropdown.Item>
                );
              })}
              <Dropdown.Item onClick={() => showPartners()}>not assigned</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </nav>
        <button className="btn btn-success" onClick={() => createUserForPartner()}>
          Add User
        </button>
        <button className="btn btn-success" onClick={() => createPartner()}>
          Add Partner
        </button>
      </div>

      <table className="table table-striped table-bordered text-center">
        <thead>
          <tr>
            <th>#</th>
            <th>Email</th>
            <th>Partner</th>
            <th>Creation Time</th>
            <th>Last SignIn Time</th>
            <th>Reset password</th>
            <th>Assign Partner</th>
          </tr>
        </thead>
        <tbody>{renderData()}</tbody>
      </table>
    </div>
  );
}
