import React, { useEffect, useState } from "react";
import { CarBrand } from "../../../Models/CarBrand";
import { Button, Input, Modal } from "reactstrap";
import { CarOriginOptions } from "../CarOriginOptions/CarOriginOptions";
import { CarVendorMapper } from "../CarVendorMapper/CarVendorMapper";

import "./CarBrandDetails.css";
import { CarOrigin } from "../../../_helpers";
import { CarBrandService } from "../../../Services/CarListServices/carBrandService";
import { AlertAction } from "../../../_actions";
import { CarBrandAdapter } from "../../../Services/ModelsAdapter/CarBrandAdapter";

/**
 * 
 * @param carBrand if Null means popup will open to create new Brand 
 * @returns 
 */
export function CarBrandDetails({carBrand, closeCarBrandDetailsDelegate}:{carBrand:CarBrand|null, closeCarBrandDetailsDelegate:any}) {
  
  const [isModalOpened, setIsModalOpened] = useState(true);
  const [aCarBrand, setACarBrand] = useState(new CarBrand());
  const [loading, setLoading] = useState(false);
  const [nothing, setNoThing] = useState(false);

  useEffect(()=>{
    if (!carBrand) {
      const newCarBrand = new CarBrand();
      newCarBrand.name = "";
      newCarBrand.origin = CarOrigin.OTHER;
      
      setACarBrand(newCarBrand);
    } else {
      const carBrandAdapter = new CarBrandAdapter();
      const cloneBrand = carBrandAdapter.fromAPI(carBrandAdapter.toAPI(carBrand));
      setACarBrand(cloneBrand);
    }
  },[]);

  function closeModal() {
    setIsModalOpened(false);
  }

  async function saveBrand() {
    try {
      setLoading(true);
      const carBrandService = new CarBrandService();
      if (aCarBrand.key && aCarBrand.key.length) {
        //EDIT brand
        carBrandService.editBrand(aCarBrand);
      } else {
        //Create new brand
        await carBrandService.addBrand(aCarBrand);
      }
      setLoading(false);
      closeModal();
    } catch (error) {
      const alert = new AlertAction();
      alert.fail(JSON.stringify(error));
      setLoading(false);
      closeModal();
    }
  }

  const onBrandNameChange = (event:any) => {
    aCarBrand.name = event.target.value;
    setNoThing(!nothing);
  };

  function originChanged(selectedOrigin:CarOrigin) {
    aCarBrand.origin = selectedOrigin;
  }

  return (
    <Modal
    isOpen={isModalOpened}
    onClosed={closeCarBrandDetailsDelegate}
    >
      <div className="container-fluid car-brand-details">
        <div className="row" style={{marginBottom:"5px"}}>
          <span className="col-2 my-auto">Brand: </span>
          <Input className="col my-auto" type="text" value={aCarBrand?.name} onChange={(event) => onBrandNameChange(event)}/>
        </div>
        <div style={{marginBottom:"5px"}} className="row">
         <span>Origin: </span><CarOriginOptions selectedOrigin={aCarBrand?.origin} disabled={false} onChange={originChanged}></CarOriginOptions>
        </div>
        <div className="row">
          <CarVendorMapper vendorMapper={aCarBrand.vendorMapper}></CarVendorMapper>
        </div>
        <div className="row" style={{marginTop:"10px"}}>
          <Button className="btn btn-success col" onClick={saveBrand} disabled={loading}>Save</Button>
          <Button className="btn btn-warning col" onClick={closeModal} disabled={loading}>Cancel</Button>
        </div>
      </div>
    </Modal>
  );

}