// eslint-disable-next-line
import React from "react";
import { INotificationProps } from "../../../interfaces/props";

export function NotificationItem({ index, item }: INotificationProps) {
  return (
    <div className="toast show bg-info w-50" role="alert" aria-live="assertive" aria-atomic="true">
      <div className="toast-header">
        <strong className="mr-auto">#{index}</strong>
        <small>{item.title}</small>
      </div>
      <div className="toast-body text-center">{item.body}</div>
    </div>
  );
}
