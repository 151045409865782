// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { UserDataVerify, UserPageVideo, ReasonsItem } from "..";
import { UserAction, RejectReasonsAction, VerificationAction } from "../../../_actions";
import { isNotEmpty, checkState, VerificationType, VerificationStatus, ActionType } from "../../../_helpers";
import { IUserItemState, IVerificationDetailsState, ICommentState, AppState } from "../../../interfaces/state";
import { IUserPageActionProps } from "../../../interfaces/props";
import { IdData } from "../../../interfaces/components/user";
import { IVerificationRequest } from "../../../interfaces/components/verification";

export function UserPageAction({ userId, requestId, type, bucketPath, timestamp }: IUserPageActionProps) {
  const dispatch = useDispatch();
  const userAction = new UserAction();
  const verificationAction = new VerificationAction();
  const rejectReasonsAction = new RejectReasonsAction();

  const {
    user,
    verificationDetails,
    reasonsList,
  }: {
    user: IUserItemState;
    verificationDetails: IVerificationDetailsState;
    reasonsList: ICommentState;
  } = useSelector((state: AppState) => ({
    user: state.user,
    verificationDetails: state.verificationDetails,
    reasonsList: state.commentList,
  }));

  const [flags, setActionFlag] = useState({
    acceptFlag: 0,
    rejectFlag: 0,
  });
  const selectedReasons: Array<any> = [];

  useEffect(() => {
    dispatch(verificationAction.getDetails(userId, requestId, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId, requestId, user]);

  const handleRequestAccept = (formData: IdData | undefined) => {
    const verificationRequest: IVerificationRequest = {
      userId: userId,
      requestId: requestId,
      action: ActionType.ACCEPT,
      info: {
        type: type,
        data:
          type === VerificationType.NATIONALID
            ? {
                bucketPath: bucketPath,
                idData: formData,
              }
            : {
                timestamp: timestamp,
              },
      },
    };
    dispatch(userAction.verify(verificationRequest, user.item));
  };

  const handleRequestReject = () => {
    const verificationRequest: IVerificationRequest = {
      userId: userId,
      requestId: requestId,
      action: ActionType.REJECT,
      info: {
        type: type,
      },
      reason: selectedReasons,
    };
    dispatch(userAction.verify(verificationRequest, user.item));
  };

  const submitReasons = () => {
    const list = document.getElementById("list") as HTMLFormElement;
    const reasons = list.getElementsByTagName("input");
    const others = document.getElementById("others") as HTMLFormElement;
    for (let i = 0, len = reasons.length; i < len; i++) {
      if (reasons[i].type === "checkbox" && reasons[i].checked) {
        selectedReasons.push(reasonsList.comments[i].value);
      }
    }
    if (others.value) {
      selectedReasons.push(others.value);
    }
  };

  const displayReasons = (type: string) => {
    dispatch(rejectReasonsAction.get(type));
    setActionFlag((flags) => ({ ...flags, acceptFlag: 0, rejectFlag: 1 }));
  };

  const displayForm = () => {
    setActionFlag((flags) => ({ ...flags, acceptFlag: 1, rejectFlag: 0 }));
  };

  return (
    <div className="container-fluid col-6">
      <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex flex-row justify-content-center align-items-center">
          {checkState(type, VerificationType.NATIONALID) ? (
            <div>
              {isNotEmpty(verificationDetails) &&
              isNotEmpty(verificationDetails.items.status === VerificationStatus.AGENT) ? (
                <div>Requires Agent Verification</div>
              ) : null}
              <Button variant="success" type="button" onClick={() => displayForm()}>
                Verify
              </Button>
              <Button variant="danger" type="button" onClick={() => displayReasons(type)}>
                Reject
              </Button>
            </div>
          ) : (
            <div>
              <Button variant="success" type="button" onClick={() => handleRequestAccept(undefined)}>
                Verify
              </Button>
              <Button variant="danger" type="button" onClick={() => displayReasons(type)}>
                Reject
              </Button>
            </div>
          )}
        </div>
        &nbsp;
        {flags.rejectFlag && isNotEmpty(reasonsList.comments) ? (
          <form className="d-flex flex-column justify-content-center text-dark bg-light" style={{ padding: "10px" }}>
            <p>Select the reject reasons:</p>
            <div className="d-flex flex-column justify-content-start align-items-start" id="list">
              {isNotEmpty(reasonsList.comments)
                ? reasonsList.comments.map((item, index) => {
                    return <ReasonsItem key={index} item={item} />;
                  })
                : null}
            </div>
            <input type="textarea" className="form-control" id="others" placeholder="Others" />
            <Button
              variant="success"
              type="button"
              onClick={() => {
                submitReasons();
                handleRequestReject();
              }}
            >
              Submit Reject
            </Button>
          </form>
        ) : null}
        {checkState(type, VerificationType.NATIONALID) && flags.acceptFlag ? (
          <UserDataVerify
            handleRequestAccept={handleRequestAccept}
            idData={verificationDetails.items.valifyRaw ? verificationDetails.items.valifyRaw : {}}
          />
        ) : null}
        {checkState(type, VerificationType.VIDEO) ? (
          <UserPageVideo
            code={user.item.video.request!.code as string}
            src={user.item.video.request?.video as string}
          />
        ) : null}
      </div>
    </div>
  );
}
