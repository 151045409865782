interface IHeader {
  Authorization: string;
}

export function authHeader(): Object {
  const tokenId: string = localStorage.getItem("tokenId") || "";
  if (tokenId) {
    const header: IHeader = { Authorization: "Bearer " + tokenId };
    return header;
  } else {
    return {};
  }
}
