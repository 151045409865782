import React, { useState } from "react";
import { Input } from "reactstrap";
import { VendorMapper } from "../../../Models/VendorMapper";

export function CarVendorMapper({vendorMapper}:{vendorMapper:Array<VendorMapper>}) {

  const [nothing, setNoThing] = useState(false);

  const onVendorMapperValueChange = (event:any, selectedVendorMapper:VendorMapper) => {
    selectedVendorMapper.mappingValue = event.target.value;
    setNoThing(!nothing);
  };

  return (
    <table>
        <tbody>
        {vendorMapper && vendorMapper.map((aVendorMapper) => (
          <tr key={aVendorMapper.vendorKey}>
            <td>
              {aVendorMapper.vendorKey}
            </td>
            <td>
              <Input type="text" 
              value={aVendorMapper.mappingValue}
              onChange={(event) => onVendorMapperValueChange(event, aVendorMapper)} 
              />
            </td>
          </tr>
        ))}
          </tbody>
      </table>
  );
}