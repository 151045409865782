import { CarModel } from "../../Models/CarModel";
import { apiConstants } from "../../_constants";
import { fetchRequest, authHeader } from "../../_helpers";
import { CarModelAdapter } from "../ModelsAdapter/CarModelAdapter";

export class CarModelService {
  
  URLWithBrandKey(brandKey:string, url:string){
    const updatedURL = url.replace(":brand", brandKey);
    return updatedURL;
  }

  async addModel(carModel: CarModel, brandKey:string|undefined) : Promise<CarModel> {
    if (brandKey) {
      const body = new CarModelAdapter().toAPI(carModel);
      const carListResponse = await fetchRequest("POST", this.URLWithBrandKey(brandKey, apiConstants.API_ADD_MODEL_CAR_LIST), authHeader(), body, {});
      
      if (carListResponse.success) {
        return new CarModelAdapter().fromAPI(carListResponse.data);
      } else {
        throw new Error("Something went wrong");
      }
    } else {
      throw new Error("Missing brandKey");
    }
  }

  async editModel(carModel: CarModel, brandKey:string|undefined) : Promise<CarModel> {
    if (brandKey) {
      const body = new CarModelAdapter().toAPI(carModel);
      const carListResponse = await fetchRequest("PUT", this.URLWithBrandKey(brandKey, apiConstants.API_EDIT_MODEL_CAR_LIST), authHeader(), body, {});
      
      if (carListResponse.success) {
        return new CarModelAdapter().fromAPI(carListResponse.data);
      } else {
        throw new Error("Something went wrong");
      }
    } else {
      throw new Error("Missing brandKey");
    }
  }

  async deleteModel(carModel:CarModel, brandKey:string|undefined) : Promise<boolean> {
    if (brandKey) {
      const body = new CarModelAdapter().toAPI(carModel);
      const carListResponse = await fetchRequest("DELETE", this.URLWithBrandKey(brandKey, apiConstants.API_DELETE_MODEL_CAR_LIST), authHeader(), body, {});
      
      if (carListResponse.success) {      
        return true;
      } else {
        throw new Error("Something went wrong");
      }
    } else {
      throw new Error("Missing brandKey");
    }
  }

  
}