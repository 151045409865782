// eslint-disable-next-line
import React from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AlertAction, TicketAction } from "../../../_actions";
import { IAttachment } from "../../../interfaces/components/attachment";

export function AttachmentItem({ item, removeAttachment }: IAttachment) {
  const dispatch = useDispatch();
  const alertAction = new AlertAction();
  const ticketAction = new TicketAction();

  const downloadAttachment = async () => {
    const url = await ticketAction.getAttachmentUrl(item.bucketPath);
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = item.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      dispatch(alertAction.fail("Failed to download attachment"));
    }
  };

  // eslint-disable-next-line
  return (
    <div className="toast-header show" role="alert" aria-live="assertive" aria-atomic="true">
      {removeAttachment ? (
        <button className="close" onClick={() => removeAttachment(item.name)}>
          <FontAwesomeIcon icon={faTrash} className="mr-2" />
        </button>
      ) : (
        <button className="close" onClick={() => downloadAttachment()}>
          <FontAwesomeIcon icon={faDownload} className="mr-2" />
        </button>
      )}
      <div className="toast-body text-center">{item.name}</div>
    </div>
  );
}
