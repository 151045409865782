import React, { useState } from "react";
import { Button, Modal } from "reactstrap";

/**
 * 
 * @param onCancelButtonAction fired when user press on cancel button
 * @param onConfirmButtonAction fired when user press on confirm button
 * @returns 
 */
export function ConfirmationDialog({message, onCancelButtonAction, onConfirmButtonAction}:{message: string, onCancelButtonAction:any, onConfirmButtonAction:any}) {
  
const [isModalOpened, setIsModalOpened] = useState(true);

  function cancelButtonOnClickAction() {
    setIsModalOpened(false);
    onCancelButtonAction();
  }

  function confirmButtonOnClickAction() {
    setIsModalOpened(false);
    onConfirmButtonAction();
  }
  
  return (
    <Modal
    isOpen={isModalOpened}
    >
      <span>{message}</span>
      <Button className="btn btn-warning" onClick={cancelButtonOnClickAction}>Cancel</Button>
      <Button className="btn btn-danger" onClick={confirmButtonOnClickAction}>Confirm</Button>
    </Modal>
  );
}