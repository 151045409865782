import React, { useState } from "react";
import { Input, Modal } from "reactstrap";
import { Dropdown, Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import { ProductId, Vendor } from "../../../_helpers";
import { AlertAction } from "../../../_actions";
import { ManualOrderService } from "../../../Services/ManualOrderService";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input-mobile";
import "react-phone-number-input/style.css";

import "./CreateManualOrder.css";
import { ManualOrder } from "../../../Models/ManualOrder";

export default function CreateManualOrder({closeModalDelegate}:{closeModalDelegate:any}) {

  //Manual Order attributes
  const [partnerId, setPartnerId] = useState(Vendor.AXA);
  const [productId, setProductId] = useState(ProductId.MOTOR);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [amount, setAmount] = useState(0);

  //Manual Policy attributes
  const [title, setTitle] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [description, setDescription] = useState("");
  const [coverage, setCoverage] = useState(0);

  //UI Helpers attributes
  const [loading, setLoading] = useState("");
  const [isModalOpened, setIsModalOpened] = useState(true);
  const [showManualPolicySection, setShowManualPolicySection] = useState(true);

  function closeModal() {
    setIsModalOpened(false);
  }

  const createManualOrderAction = () => {
    const alert = new AlertAction();
    //TODO: Validate on fullName too & policyData if manual PolicyData checked
    if (phoneNumber && phoneNumber.length && isValidPhoneNumber(phoneNumber) && amount && !isNaN(amount) && amount > 0) {
      // setLoading("Loading...");

      const manualOrder = new ManualOrder();
      manualOrder.amount = amount;
      manualOrder.phoneNumber = phoneNumber;
      manualOrder.productId = productId;
      manualOrder.partnerId = partnerId;
      manualOrder.fullName = fullName;
      manualOrder.shouldCreateManualPolicy = showManualPolicySection;

      if (showManualPolicySection) {
        manualOrder.manualPolicy = {
          title: title,
          serialNumber : serialNumber,
          description : description,
          coverage : coverage
        };
      }

      const manualOrderService = new ManualOrderService();
      manualOrderService.createManualOrder(manualOrder)
      .then(()=>{
        setLoading("Success");
        alert.pass("Manual Order created succefully");
      })
      .catch((err:Error)=>{
        setLoading("");
        alert.fail(err.message);
      });
    } else {
      alert.fail("Fill all fileds with correct values");
    }
  };

  const onAmountChange = (event:any) => {
    setAmount(event.target.valueAsNumber);
  };

  return (
    <Modal
    isOpen={isModalOpened}
    onClosed={closeModalDelegate}
    className="wide-modal"
    >
      <div className="container-fluid create-manaul-order">
        <h4>Manual Order</h4>
          FullName <br />
        <Input type="text" onChange={(event) => { setFullName(event.target.value); }} />
          Phone number <br />
          <PhoneInput
            placeholder="Enter phone number"
            value={phoneNumber}
            country="EG"
            withCountryCallingCode={true}
            international={true}
            onChange={(value: string) => {
              setPhoneNumber(value);
            }}
          />
          <br />
          Amount <Input type="number" className="col my-auto" value={amount} onChange={(event) => onAmountChange(event)} />
          <br />
        <span style={{ marginRight: "10px" }}>PartnerId</span>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle variant="info" id="dropdown-filter">
              {partnerId}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setPartnerId(Vendor.AXA)}>
                AXA
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setPartnerId(Vendor.ALLIANZ)}>
                Allianz
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setPartnerId(Vendor.SARWA)}>
                Sarwa
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setPartnerId(Vendor.WETHAQ)}>
                Wethaq
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setPartnerId(Vendor.MOHANDES)}>
                Mohandes
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setPartnerId(Vendor.MISR_TKAFUL)}>
                Misr Tkaful
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <span style={{marginRight:"10px", marginLeft:"10px"}}>ProductId</span>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle variant="info" id="dropdown-filter">
              motor
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => alert("motor")}>
                    motor
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <ToggleButton
            id="manual-policy-toggle-check"
            type="checkbox"
            variant="light"
            checked={showManualPolicySection}
            value={showManualPolicySection}
          onChange={(e) => setShowManualPolicySection(e.currentTarget.checked)} >
            <b> Create manual policy</b>
          </ToggleButton>

          {
          showManualPolicySection &&

            <div>
              <h4>Manual Policy</h4>
              Polciy Title (Example : Car Insurance Policy) <br />
              <Input type="text" onChange={(event)=>{setTitle(event.target.value);}}/>

              Serial Number <br />
              <Input type="text" onChange={(event)=>{setSerialNumber(event.target.value);}}/>

              Description (details like car brand, model, year, ... etc) <br />
              <Input type="text" onChange={(event)=>{setDescription(event.target.value);}}/>

              Coverage <br />
              <Input type="number" onChange={(event)=>{setCoverage(event.target.valueAsNumber);}}/>

            </div>
          }

          <br />
          <div style={{marginTop:"10px", marginBottom:"10px"}}>
            {loading.length ? loading : <Button className="btn btn-success" onClick={()=>createManualOrderAction()}>Create Manual Order</Button> }
          </div>
          <div className="note">SMS will be sent to this mobile number <b>{phoneNumber}</b> contains Payment Link</div>
          <div className="note">NOTE: Sometimes manaual order take few seconds to be generated, so if you would like to see it in orders list please wait for few seconds then refresh it.</div>

          <br />
          <Button variant="danger" style={{marginTop:"10px"}} onClick={()=>closeModal()}>Close</Button>
        </div>

      </Modal>
  );

}