import { ManualOrder } from "../Models/ManualOrder";
import { apiConstants } from "../_constants";
import { ProductId, Vendor, fetchRequest, authHeader } from "../_helpers";

export class ManualOrderService {

  async createManualOrder(manualOrder: ManualOrder) {

    const body : {
      phoneNumber: string,
      partnerId: Vendor | undefined,
      productId: ProductId | undefined,
      amount: number,
      currency: string,
      fullName : string,
      manualPolicy?: {
        title: string;
        serialNumber: string;
        description: string;
        coverage: number;
      }
    } = {
      phoneNumber: manualOrder.phoneNumber,
      partnerId: manualOrder.partnerId,
      productId: manualOrder.productId,
      amount: manualOrder.amount,
      currency: manualOrder.currency,
      fullName : manualOrder.fullName,
    };

    if (manualOrder.shouldCreateManualPolicy) {
      body.manualPolicy = {
        title: manualOrder.manualPolicy.title,
        serialNumber: manualOrder.manualPolicy.serialNumber,
        description: manualOrder.manualPolicy.description,
        coverage: manualOrder.manualPolicy.coverage,
      };
    }

    console.log(JSON.stringify(body));

    const response = await fetchRequest("POST", apiConstants.API_CREATE_MANUAL_ORDER, authHeader(), body, {});
    if (response && response.success) {
      return;
    } else {
      throw new Error("Something went wrong");
    }
  }
}