// eslint-disable-next-line
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../Loading";
import { IMatchProps } from "../../../interfaces/props";
import { IOrderItemState, ILoadingState, AppState } from "../../../interfaces/state";
import { AlertAction, OrderAction } from "../../../_actions";
import { getLocalDate, isNotEmpty } from "../../../_helpers";
import { NoDataFound } from "../../NoDataFound";
import { Card } from "../../Card";
import { IOrderTransactions, IOrderTableItems } from "../../../interfaces/components/order";
import { Button } from "react-bootstrap";

export function OrderPage({ match }: IMatchProps) {
  const dispatch = useDispatch();
  const orderAction = new OrderAction();

  const {
    params: { orderId, userId },
  } = match;

  const { order, loading }: { order: IOrderItemState; loading: ILoadingState } = useSelector((state: AppState) => ({
    order: state.order,
    loading: state.loading,
  }));

  useEffect(() => {
    dispatch(orderAction.getByID(orderId!, userId!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, orderId, userId]);

  const displayTransactionDetails = () => {
    return order.item?.transaction!.map((item: IOrderTransactions, index: number) => {
      return (
        <tr key={index}>
          <td> {item.gatewayTransactionId} </td>
          <td>
            {" "}
            {item.amount} {item.currency}
          </td>
          <td>{item.transactionType ? item.transactionType : "Not provided"}</td>
          <td>{item.gateway}</td>
          <td>{item.gatewayResponse ? "Yes" : "No"}</td>
          <td>{item.createTime ? getLocalDate(item.createTime) : "Not provided"}</td>
          <td>{item.responseTime ? getLocalDate(item.responseTime) : "Not provided"}</td>
        </tr>
      );
    });
  };

  const displayItemDetails = () => {
    return order.item?.mysqlObj!.map((item: IOrderTableItems, index: number) => {
      return (
        <tr key={index}>
          <td> {item.itemId} </td>
          <td>
            {item.total_amount} {item.currency}
          </td>
          <td>{item.description}</td>
          <td>{item.vendor}</td>
          <td>{item.fulfilled ? "True" : "False"}</td>
          <td>{item.paid ? "True" : "False"}</td>
          <td>{item.refunded ? "True" : "False"}</td>
          <td>{item.orderTimestamp ? getLocalDate(item.orderTimestamp) : "Not provided"}</td>
        </tr>
      );
    });
  };

  const copyToClipboard = (text:string|undefined) => {
    const textToBeCopied = text?text:"";
    navigator.clipboard.writeText(textToBeCopied);
    new AlertAction().pass("Copied !");
  };

  const renderData = () => {
    if (loading.isLoading) {
      return <Loading />;
    } else {
      if (typeof order.item === "object" && order.item !== null && isNotEmpty(order.item)) {
        return (
          <div className="d-flex flex-column justify-content-center align-items-start">
            <Card heading="Amount" body={`${order.item.firestoreObj.amount} ${order.item.firestoreObj.currency}`} />
            <Card heading="Status" body={`${order.item.firestoreObj.status}`} />
            <Card heading="Type" body={`${order.item.firestoreObj.type}`} />
            <Card heading="Creation Date" body={getLocalDate(order.item.firestoreObj.timestamp)} />
            {
              order.item?.firestoreObj.payLink && 
              <div>
                <span>PayLink</span>
                <Button variant="secondary" style={{marginRight:"10px", marginLeft:"10px"}} onClick={()=>copyToClipboard(order.item?.firestoreObj.payLink)}>Copy PayLink</Button>
                <span>{`${order.item.firestoreObj.payLink}`}</span>
              </div>
            }
            
            <br />
            <Card heading="Item Details" body={""} />
            {order.item.mysqlObj.length > 0 ? (
              <div className="container fill">
                <table className="table table-striped table-bordered text-center">
                  <thead>
                    <tr>
                      <th className="th-sm">Item Id</th>
                      <th>Amount</th>
                      <th>Description</th>
                      <th>Vendor</th>
                      <th>Fulfilled</th>
                      <th>Paid</th>
                      <th>Refunded</th>
                      <th>Creation Date</th>
                    </tr>
                  </thead>
                  <tbody>{displayItemDetails()}</tbody>
                </table>
              </div>
            ) : (
              <NoDataFound data={"No Order items are found"} />
            )}

            <Card heading="Transaction Details" body={""} />
            {order.item.transaction.length > 0 ? (
              <div className="container fill">
                <table className="table table-striped table-bordered text-center">
                  <thead>
                    <tr>
                      <th>Transaction Id</th>
                      <th>Amount</th>
                      <th>Transaction Type</th>
                      <th>Gateway</th>
                      <th>Gateway response</th>
                      <th>Creation Date</th>
                      <th>Response Date</th>
                    </tr>
                  </thead>
                  <tbody>{displayTransactionDetails()}</tbody>
                </table>
              </div>
            ) : (
              <NoDataFound data={"No transaction details are found"} />
            )}
          </div>
        );
      } else {
        return <NoDataFound data={"No Order data is found"} />;
      }
    }
  };

  return <div className="container-fluid">{renderData()}</div>;
}
