// eslint-disable-next-line
import React from "react";
import { useSelector } from "react-redux";
import { Loading } from "../../Loading";
import { NoDataFound } from "../../NoDataFound";
import { DataElement } from "../../DataElement";
import { isNotEmpty } from "../../../_helpers";
import { IDataElement, IUserDataListProps } from "../../../interfaces/props";
import { ILoadingState, AppState } from "../../../interfaces/state";

export function UserDataList({ idData, width }: IUserDataListProps) {
  const { loading }: { loading: ILoadingState } = useSelector((state: AppState) => ({
    loading: state.loading,
  }));

  const renderData = () => {
    if (loading.isLoading) {
      return <Loading />;
    } else {
      if (isNotEmpty(idData) && idData !== null && typeof idData === "object") {
        const data: Array<IDataElement> = [
          {
            title: "FirstName",
            value: idData.firstName,
          },
          {
            title: "FamilyName",
            value: idData.familyName,
          },
          {
            title: "FullName",
            value: idData.fullName!,
          },
          {
            title: "EnglishName",
            value: idData.englishName!,
          },
          {
            title: "Gender",
            value: idData.gender!,
          },
          {
            title: "National ID",
            value: idData.nationalIdNumber,
          },
          {
            title: "Street",
            value: idData.street,
          },
          {
            title: "Area",
            value: idData.area,
          },
          {
            title: "Address",
            value: idData.address!,
          },
          {
            title: "Birth Date",
            value: idData.dateOfBirth,
          },
          {
            title: "ID Expiry",
            value: idData.expiryDate,
          },
        ];

        return (
          <div className="container-fluid card-header">
            {data.map((item: IDataElement, index) => {
              return <DataElement key={index} title={item.title} value={item.value} />;
            })}
          </div>
        );
      } else {
        return <NoDataFound data={"No Available Data for this user"} />;
      }
    }
  };

  return (
    <div className={`container-fluid ${width}`}>
      <div className="container-fluid d-flex flex-column justify-content-center align-items-center">{renderData()}</div>
    </div>
  );
}
