// eslint-disable-next-line
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import { IUserPageIDProps } from "../../../interfaces/props";

export function UserPageID({ imagesUrls }: IUserPageIDProps) {
  
  const [clickedImageUrl, setClickedImageUrl] = useState("");

  return (
    <div className="container-fluid col-6">
      <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
        {imagesUrls.map((imageURL, index) => {return <img key={index} className="img-fluid" alt="National ID"  src={imageURL} onClick={() => setClickedImageUrl(imageURL)} />;}) }
      </div>
      {clickedImageUrl.length && <Lightbox mainSrc={clickedImageUrl} onCloseRequest={() => setClickedImageUrl("")} />}

    </div>
  );
}