import { apiConstants, policyConstants } from "../_constants";
import { authHeader, handleAxiosDispatch, fetchRequest } from "../_helpers";
import { LoadingAction } from ".";
import { IPolicy } from "../interfaces/components/policy";
import { Dispatch } from "redux";
import { IPolicyAction } from "../interfaces/actions";

export class PolicyAction {
  private dispatchPolicyListRequest = (
    type: string,
    status: string,
    sortBy: string,
    vendor: string,
    startDate: string,
    endDate: string,
  ) => {
    return {
      type: policyConstants.FETCH_POLICY_LIST_REQUEST,
      flags: {
        type,
        status,
        sortBy,
        vendor,
      },
      startDate,
      endDate,
    };
  };

  private dispatchPolicyListSuccess = (policies: Array<IPolicy>) => {
    return {
      type: policyConstants.FETCH_POLICY_LIST_SUCCESS,
      policies,
    };
  };

  private dispatchPolicyListFailure = () => {
    return {
      type: policyConstants.FETCH_POLICY_LIST_FAILURE,
      policies: [],
    };
  };

  addDateRange = (startDate: string, endDate: string) => {
    return {
      type: policyConstants.ADD_POLICY_DATE_RANGE,
      startDate,
      endDate,
    };
  };

  getAll = (type: string, status: string, sortBy: string, vendor: string, startDate: string, endDate: string) => {
    const loading = new LoadingAction();
    return async (dispatch: Dispatch<IPolicyAction>) => {
      try {
        dispatch(this.dispatchPolicyListRequest(type, status, sortBy, vendor, startDate, endDate));
        dispatch(loading.isLoading(true));
        const response = await fetchRequest(
          "GET",
          apiConstants.API_GET_POLICIES,
          authHeader(),
          {},
          {
            sort: sortBy,
            filterStatus: status,
            filterType: type,
            filterVendor: vendor,
            startDate,
            endDate,
          },
        );

        if (response.success) {
          dispatch(this.dispatchPolicyListSuccess(response.data));
          dispatch(loading.isLoading(false));
        } else {
          throw new Error();
        }
      } catch (error) {
        handleAxiosDispatch(error, "Failed to get policies", dispatch, this.getAll, this.dispatchPolicyListFailure, [
          type,
          status,
          sortBy,
          startDate,
          endDate,
        ]);
        dispatch(loading.isLoading(false));
      }
    };
  };
}
