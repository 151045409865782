// eslint-disable-next-line
import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Login } from "./Components/Login";
import { PrivateRoute } from "./Components/PrivateRoute";
import { NoMatching } from "./Components/NoMatching";
import { AlertBanner } from "./Components/AlertBanner";
import { history } from "./_helpers";
import { routes } from "./Routes";

export function App() {
  return (
    <div
      className="container-fluid h-100 w-100"
      style={{ paddingRight: "0px", paddingLeft: "0px", overflow: "hidden" }}
    >
      <AlertBanner />
      <Router history={history}>
        <Switch>
          {routes.map((route) => {
            return <PrivateRoute key={route.path} exact path={route.path} component={route.component} />;
          })}
          {/* <Route path="/login" component={Login} /> */}
          <Route path="/login" render={(props) => <Login {...props} />} />
          <Route component={NoMatching} />
        </Switch>
      </Router>
    </div>
  );
}
