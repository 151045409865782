export const validateDate = (date: string): { success: boolean; data: string } => {
  // format yyyy/mm/dd
  const regex = /^\d{4}\/\d{1,2}(\/\d{1,2})?$/;
  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  if (!regex.test(date)) return { success: false, data: "" };

  const parts = date.split("/");
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  let day = parseInt(parts[2], 10);

  if (!day) {
    day = monthLength[month - 1];
  }

  // Check the ranges of month and year
  if (year < 1900 || year > 3000 || month === 0 || month > 12) return { success: false, data: "" };

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;

  // Check the range of the day
  if (day > 0 && day <= monthLength[month - 1]) {
    return { success: true, data: `${year}/${month}/${day}` };
  }
  return { success: false, data: "" };
};
