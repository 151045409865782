import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { users, user } from "./user.reducer";
import { alert } from "./alert.reducer";
import { commentList } from "./rejectReason.reducer";
import { locales } from "./localization.reducer";
import { tickets, responses, ticket } from "./ticket.reducer";
import { verifications, userVerifications, verificationDetails } from "./verification.reducer";
import { loading } from "./loading.reducer";
import { partners } from "./partner.reducer";
import { orders, order } from "./order.reducer";
import { policies } from "./policy.reducer";

import { AppState } from "../interfaces/state";

const rootReducer = combineReducers<AppState>({
  authentication,
  users,
  user,
  userVerifications,
  alert,
  commentList,
  locales,
  tickets,
  ticket,
  responses,
  verifications,
  verificationDetails,
  loading,
  partners,
  orders,
  order,
  policies,
});

export default rootReducer;
