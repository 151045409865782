import { Dispatch } from "redux";
import { partnerConstants, apiConstants } from "../_constants";
import { authHeader, handleAxiosDispatch, fetchRequest, handleAxios } from "../_helpers";
import { LoadingAction } from ".";
import { ILoadingAction, IPartnersAction } from "../interfaces/actions";
import { IPartnerData, IUsersPartnerData } from "../interfaces/components/partner";

export class PartnerAction {
  private dispatchPartnerRequest = () => {
    return {
      type: partnerConstants.FETCH_PARTNER_LIST_REQUST,
    };
  };

  private dispatchPartnerSuccess = (partners: Array<IPartnerData>) => {
    return {
      type: partnerConstants.FETCH_PARTNER_LIST_SUCCESS,
      partners,
    };
  };

  private dispatchPartnerFailure = () => {
    return {
      type: partnerConstants.FETCH_PARTNER_LIST_FAILURE,
      partners: [],
    };
  };

  private dispatchUserRequest = () => {
    return {
      type: partnerConstants.FETCH_PARTNER_USERS_REQUST,
    };
  };

  private dispatchUserSuccess = (users: Array<IUsersPartnerData>) => {
    return {
      type: partnerConstants.FETCH_PARTNER_USERS_SUCCESS,
      users,
    };
  };

  private dispatchUserFailure = () => {
    return {
      type: partnerConstants.FETCH_PARTNER_USERS_FAILURE,
      users: [],
    };
  };

  getPartners = () => {
    const loading = new LoadingAction();
    return async (dispatch: Dispatch<ILoadingAction | IPartnersAction>) => {
      try {
        dispatch(this.dispatchPartnerRequest());
        dispatch(loading.isLoading(true));
        const response = await fetchRequest("GET", `${apiConstants.API_PARTNERS}`, authHeader());
        if (response.success) {
          dispatch(this.dispatchPartnerSuccess(response.data.partners));
          dispatch(loading.isLoading(false));
        } else {
          throw new Error();
        }
      } catch (error) {
        handleAxiosDispatch(error, "Failed to get partners", dispatch, this.getPartners, this.dispatchPartnerFailure);
        dispatch(loading.isLoading(false));
      }
    };
  };

  getUsers = (vendorId?: string) => {
    const loading = new LoadingAction();
    return async (dispatch: Dispatch<ILoadingAction | IPartnersAction>) => {
      try {
        dispatch(this.dispatchUserRequest());
        dispatch(loading.isLoading(true));
        const response = await fetchRequest("GET", `${apiConstants.API_PARTNERS}/users`, authHeader(), null, {
          vendorId,
        });
        if (response.success) {
          dispatch(this.dispatchUserSuccess(response.data.partners));
          dispatch(loading.isLoading(false));
        } else {
          throw new Error();
        }
      } catch (error) {
        handleAxiosDispatch(error, "Failed to get partners", dispatch, this.getUsers, this.dispatchUserFailure);
        dispatch(loading.isLoading(false));
      }
    };
  };

  addUser = async (email: string, password: string, partnerId: string) => {
    try {
      const response = await fetchRequest("POST", `${apiConstants.API_PARTNERS}/signup/user`, authHeader(), {
        email,
        password,
        vendorId: partnerId,
      });
      if (response.success) {
        return response.success;
      } else {
        throw new Error("Failed to add User's Partner. Please try later");
      }
    } catch (error) {
      return handleAxios(error, this.addUser, [email, password, partnerId]);
    }
  };

  resetUserPassword = async (email:string, password:string) => {
    const response = await fetchRequest("POST", `${apiConstants.API_PARTNERS}/reset/password`, authHeader(), {
      email,
      password
    });
    
    if (response.success) {
      return response.success;
    } else {
      throw new Error("Failed to reset User's password. Please try later");
    }
  }

  addPartner = async (partnerId: string) => {
    try {
      const response = await fetchRequest("POST", `${apiConstants.API_PARTNERS}/signup`, authHeader(), {
        vendorId: partnerId,
      });
      if (response.success) {
        return response.success;
      } else {
        throw new Error("Failed to add Partner. Please try later");
      }
    } catch (error) {
      return handleAxios(error, this.addUser, [partnerId]);
    }
  };

  addCustomClaim = async (partnerId: string, vendorId: string) => {
    try {
      const response = await fetchRequest(
        "POST",
        `${apiConstants.API_PARTNERS}/${vendorId}/customClaim`,
        authHeader(),
        { userId: partnerId },
      );
      if (response.success) {
        return response.success;
      } else {
        throw new Error("Failed to add Claim. Please try later");
      }
    } catch (error) {
      return handleAxios(error, this.addCustomClaim, [partnerId, vendorId]);
    }
  };

  removeCustomClaim = async (partnerId: string, vendorId: string) => {
    try {
      const response = await fetchRequest(
        "DELETE",
        `${apiConstants.API_PARTNERS}/${vendorId}/customClaim`,
        authHeader(),
        { userId: partnerId },
      );
      if (response.success) {
        return response.success;
      } else {
        throw new Error("Failed to remove Claim. Please try later");
      }
    } catch (error) {
      return handleAxios(error, this.removeCustomClaim, [partnerId, vendorId]);
    }
  };
}
