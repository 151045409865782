// eslint-disable-next-line
import React from "react";
import { IPolicyItemProps } from "../../../interfaces/props";
import { getLocalDate } from "../../../_helpers";

export function PolicyItem({ policy }: IPolicyItemProps) {
  return (
    <React.Fragment>
      <tr>
        <td>{policy.policyId}</td>
        <td style={{ cursor: "pointer" }}>
          <a href={`/orders/${policy.orderId}/user/${policy.userId}`}>{policy.orderId}</a>
        </td>
        <td style={{ cursor: "pointer" }}>
          <a href={`/users/${policy.userId}`}>{policy.userId}</a>
        </td>
        <td>{policy.productId}</td>
        <td>{policy.serialNumber}</td>
        <td>{policy.quotation + policy.currency}</td>
        <td>{policy.coverage + policy.currency}</td>
        <td>{policy.status}</td>
        <td>{getLocalDate(policy.timestamp)}</td>
      </tr>
    </React.Fragment>
  );
}
