import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import lodash from "lodash";
import rootReducer from "../_reducers";
import { loadState, saveState } from "../LocalStorage";

const loggerMiddleware: any = createLogger();
const persistedState: any = loadState();

const middleware = [thunkMiddleware];
if (process.env.REACT_APP_ENVIRONMENT !== "production") {
  middleware.push(loggerMiddleware);
}

export const store = createStore(rootReducer, persistedState, applyMiddleware(...middleware));

store.subscribe(
  lodash.throttle(() => {
    saveState({
      partners: store.getState().partners,
      orders: store.getState().orders,
      policies: store.getState().policies,
      tickets: store.getState().tickets,
    });
  }, 1000),
);
