// eslint-disable-next-line
import React from "react";

export function Home() {
  return (
    <div className="container-fluid">
      <h3 className="text-black h3 text-center">Welcome To Bringy BackOffice</h3>
    </div>
  );
}
