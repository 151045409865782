// eslint-disable-next-line
import React from "react";
import { Nav } from "reactstrap";
import classNames from "classnames";
import { SidebarItem } from "..";
import { SideBarComponents } from "../../../Links";
import { ToggleContext } from "../../PrivateRoute";
import logo from "../../../Assets/bringy.png";
import "./Sidebar.css";

export function SideBar() {
  return (
    <ToggleContext.Consumer>
      {(value) => (
        <div
          className={classNames("sidebar d-flex flex-column justify-content-start", {
            "is-open": !value.isOpen,
          })}
        >
          <div
            className="container-fluid d-flex flex-column align-items-center"
            style={{ backgroundColor: "white", width: "100%" }}
          >
            <img src={logo} alt="bringy" width="70%" />
          </div>
          <div className="container-fluid d-flex flex-column justify-content-center align-items-right">
            <Nav vertical className="list-unstyled pb-3">
              {SideBarComponents.map((item, index) => {
                return <SidebarItem key={index} item={item} />;
              })}
            </Nav>
          </div>
        </div>
      )}
    </ToggleContext.Consumer>
  );
}
