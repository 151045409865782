// eslint-disable-next-line
import React from "react";
import { useDispatch } from "react-redux";
import { AlertAction } from "../../../_actions";
import { IUserDataVerifyProps } from "../../../interfaces/props";
import { IdData } from "../../../interfaces/components/user";
import { UserForm } from "../UserForm";

export function UserDataVerify({ handleRequestAccept, idData }: IUserDataVerifyProps) {
  const dispatch = useDispatch();
  const alertAction = new AlertAction();

  const submitAction = (data: IdData) => {
    handleRequestAccept(data);
  };

  const rejectAction = () => {
    dispatch(alertAction.fail("Please fill all fields. Date format should be yyyy/mm/dd"));
  };

  return (
    <UserForm
      edit={false}
      valifyData={idData}
      submitAction={(data) => submitAction(data)}
      buttonName={"Submit Data"}
      rejectAction={() => rejectAction()}
    />
  );
}
