// eslint-disable-next-line
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faHandsHelping, faSignOutAlt, faCogs, faBell } from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  Button,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Media,
} from "reactstrap";

import { AuthenticationAction } from "../../_actions";
import { history } from "../../_helpers";
import { AppState } from "../../interfaces/state";

export function NavBar(props: any) {
  const dispatch = useDispatch();
  const authenticationAction = new AuthenticationAction();

  const { profile } = useSelector((state: AppState) => ({
    profile: state.authentication.profile,
  }));

  const handleLogout = () => {
    dispatch(authenticationAction.toLogout());
    history.push("/login");
  };

  return (
    <Navbar
      color="light"
      light
      className="container-fluid d-flex flex-row shadow-sm p-3 mb-4 bg-light rounded"
      expand="none"
    >
      <Button color="danger" onClick={props.toggle}>
        <FontAwesomeIcon icon={faAlignLeft} />
      </Button>
      <Nav className="d-flex flex-row justify-content-around">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div>{profile ? profile.name : localStorage.getItem("name")}</div>
          <div>{profile ? profile.email : localStorage.getItem("email")}</div>
        </div>

        <NavItem>
          <NavLink tag={Link} to={"/notifications"}>
            <FontAwesomeIcon icon={faBell} className="mr-2" />
          </NavLink>
        </NavItem>

        <NavItem>
          <Media
            src={profile ? profile.imageUrl : localStorage.getItem("image")}
            style={{ maxHeight: 37, maxWidth: 37 }}
          />
        </NavItem>

        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle caret color="success">
            <FontAwesomeIcon icon={faCogs} className="mr-2" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag={Link} to={"/help"}>
              <FontAwesomeIcon icon={faHandsHelping} className="mr-2" />
              Help
            </DropdownItem>
            <DropdownItem onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
              Logout
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Navbar>
  );
}
