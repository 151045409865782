import axios from "axios";

interface IFetchRequest {
  success: boolean;
  data: any;
}

export const fetchRequest = (
  method: "GET" | "POST" | "DELETE" | "PUT",
  url: string,
  headers: any,
  body?: any,
  params?: any,
): Promise<IFetchRequest> => {
  const result = axios({
    method,
    url,
    data: body,
    params,
    headers,
  })
  .then((response) => response.data)
  .catch((error) => {
    throw error;
  });
  return result;
};