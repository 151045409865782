import { userConstants } from "../_constants";
import { VerificationType, UserStatus } from "../_helpers";
import { IUsersAction, IUserAction } from "../interfaces/actions";
import { IUserListState, IUserItemState } from "../interfaces/state";

const initialUsersState = {
  items: [],
};

export function users(state: IUserListState = initialUsersState, action: IUsersAction) {
  switch (action.type) {
    case userConstants.FETCH_USER_LIST_REQUST:
      return {
        items: [],
      };
    case userConstants.FETCH_USER_LIST_SUCCESS:
      return {
        items: action.users,
      };
    case userConstants.FETCH_USER_LIST_FAILURE:
      return {
        items: action.users,
      };
    default:
      return state;
  }
}

const initialUserState: IUserItemState = {
  item: {
    user: {
      uid: "",
      displayName: "",
      email: "",
      disabled: false,
      emailVerified: false,
      metadata: {
        creationTime: "",
        lastSignInTime: "",
      },
      photoURL: "",
      providerData: [],
      tokensValidAfterTime: "",
    },
    success: false,
    nationalId: {
      state: "",
    },
    video: {
      state: "",
    },
  },
  verificationAction: false,
};

export function user(state: IUserItemState = initialUserState, action: IUserAction) {
  switch (action.type) {
    case userConstants.FETCH_USER_ITEM_REQUST:
      return {
        item: {},
        verificationAction: false,
      };
    case userConstants.FETCH_USER_ITEM_SUCCESS:
      return {
        item: action.user,
        verificationAction: false,
      };
    case userConstants.UPDATE_USER_DATA:
      if (action.verificationType === VerificationType.NATIONALID)
        return {
          ...state,
          item: {
            ...state.item,
            nationalId: {
              ...state.item.nationalId,
              request: {
                ...state.item.nationalId.request,
                idData: action.idData,
              },
              state: UserStatus.VERIFIED,
            },
          },
        };
      else
        return {
          ...state,
          item: {
            ...state.item,
            video: {
              ...state.item.video,
              request: {
                ...state.item.video.request,
              },
              state: UserStatus.VERIFIED,
            },
          },
        };
    case userConstants.UPDATE_VERIFICATION_STATE:
      if (action.rejectType === VerificationType.NATIONALID)
        return {
          ...state,
          item: {
            ...state.item,
            nationalId: {
              ...state.item.nationalId,
              request: {
                ...state.item.nationalId.request,
              },
              state: UserStatus.UNVERIFIED,
            },
          },
        };
      else
        return {
          ...state,
          item: {
            ...state.item,
            video: {
              ...state.item.video,
              request: {
                ...state.item.video.request,
              },
              state: UserStatus.UNVERIFIED,
            },
          },
        };
    case userConstants.FETCH_USER_ITEM_FAILURE:
      return {
        item: {},
        verificationAction: false,
      };
    case userConstants.ACTION_FAILURE:
      return {
        item: action.user,
        verificationAction: false,
      };
    default:
      return state;
  }
}
