// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { Dropdown, Button, ButtonGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { PolicyItem } from "..";
import { Loading } from "../../Loading";
import { NoDataFound } from "../../NoDataFound";
import { SearchBar } from "../../SearchBar";
import { PolicyAction } from "../../../_actions";
import { isNotEmpty, PolicyStatus, PolicyType, Vendor, Sort, getCurrentDate } from "../../../_helpers";
import { IPolicyListState, ILoadingState, AppState } from "../../../interfaces/state";
import { IPolicyListProps } from "../../../interfaces/props";
import { IPolicy } from "../../../interfaces/components/policy";
import { DateFilter } from "../../DateFilter";

export function PolicyList() {
  const dispatch = useDispatch();
  const policyAction = new PolicyAction();
  const policyTypes = Object.keys(PolicyType).map((key) => PolicyType[key as keyof typeof PolicyType]);
  const policyStatus = Object.keys(PolicyStatus).map((key) => PolicyStatus[key as keyof typeof PolicyStatus]);
  const vendors = Object.keys(Vendor).map((key) => Vendor[key as keyof typeof Vendor]);
  const sortingBy = Object.keys(Sort).map((key) => Sort[key as keyof typeof Sort]);

  const {
    policies,
    loading,
  }: {
    policies: IPolicyListState;
    loading: ILoadingState;
  } = useSelector((state: AppState) => ({
    policies: state.policies,
    loading: state.loading,
  }));

  const [policyListProps, setPolicyListProps] = useState<IPolicyListProps>({
    flags: {
      type:
        isNotEmpty(policies.flags) && policies.flags !== null && typeof policies.flags === "object"
          ? policies.flags.type
          : PolicyType.All,
      status:
        isNotEmpty(policies.flags) && policies.flags !== null && typeof policies.flags === "object"
          ? policies.flags.status
          : PolicyStatus.All,
      orderBy:
        isNotEmpty(policies.flags) && policies.flags !== null && typeof policies.flags === "object"
          ? policies.flags.sortBy
          : Sort.DESC,
      vendor:
        isNotEmpty(policies.flags) && policies.flags !== null && typeof policies.flags === "object"
          ? policies.flags.vendor
          : Vendor.All,
    },
    startDate: policies.startDate || getCurrentDate().start,
    endDate: policies.endDate || getCurrentDate().end,
    searchTerm: "",
    searchResults: [],
  });

  useEffect(() => {
    dispatch(
      policyAction.getAll(
        policyListProps.flags.type,
        policyListProps.flags.status,
        policyListProps.flags.orderBy,
        policyListProps.flags.vendor,
        policyListProps.startDate,
        policyListProps.endDate,
      ),
    );
    setPolicyListProps((policyListProps) => ({
      ...policyListProps,
      searchTerm: "",
      searchResults: [],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, policyListProps.flags, policyListProps.startDate, policyListProps.endDate]);

  const handleSearchByUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (isNotEmpty(policies.items)) {
      const results = policies.items.filter((policy: IPolicy) => policy.serialNumber?.includes(event.target.value));
      setPolicyListProps((policyListProps) => ({
        ...policyListProps,
        searchTerm: event.target.value,
        searchResults: [...results],
      }));
    }
  };

  const showPolicies = (flag: string, value: string) => {
    switch (flag) {
      case "type":
        setPolicyListProps((policyListProps) => ({
          ...policyListProps,
          flags: { ...policyListProps.flags, type: value },
        }));
        break;
      case "status":
        setPolicyListProps((policyListProps) => ({
          ...policyListProps,
          flags: { ...policyListProps.flags, status: value },
        }));
        break;
      case "sort":
        setPolicyListProps((policyListProps) => ({
          ...policyListProps,
          flags: { ...policyListProps.flags, orderBy: value },
        }));
        break;
      case "vendor":
        setPolicyListProps((policyListProps) => ({
          ...policyListProps,
          flags: { ...policyListProps.flags, vendor: value },
        }));
        break;
      default:
        break;
    }
  };

  const renderData = () => {
    if (loading.isLoading) {
      return (
        <tr>
          <td colSpan={9}>
            <Loading />
          </td>
        </tr>
      );
    } else {
      if (policyListProps.searchResults.length !== 0) {
        return policyListProps.searchResults.map((policy: IPolicy, index: number) => (
          <PolicyItem key={index} policy={policy} index={index}></PolicyItem>
        ));
      }
      if (
        isNotEmpty(policies.items) &&
        policies.items !== null &&
        typeof policies.items === "object" &&
        policies.items.length !== 0 &&
        !policyListProps.searchTerm
      ) {
        return policies.items.map((policy: IPolicy, index: number) => (
          <PolicyItem key={index} policy={policy} index={index}></PolicyItem>
        ));
      } else {
        return (
          <tr>
            <td colSpan={9}>
              <NoDataFound data={"No Policies are found"} />
            </td>
          </tr>
        );
      }
    }
  };

  const handleFormData = (event: any) => {
    event.preventDefault();
    const startDate: string = (document.getElementById("startDate") as HTMLFormElement).value;
    const endDate: string = (document.getElementById("endDate") as HTMLFormElement).value;
    dispatch(policyAction.addDateRange(startDate, endDate));
    setPolicyListProps({ ...policyListProps, startDate, endDate });
  };

  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
      <DateFilter
        startDate={policyListProps.startDate}
        endDate={policyListProps.endDate}
        handleFormData={handleFormData}
      />
      <nav className="navbar navbar-light bg-light">
        <SearchBar title="Search by serial number" value={policyListProps.searchTerm} action={handleSearchByUser} />

        <Dropdown as={ButtonGroup}>
          <Button variant="light">Partner : </Button>
          <Dropdown.Toggle variant="info" id="dropdown-split-basic">
            {policyListProps.flags.vendor}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {vendors.map((vendor: any, index: number) => {
              return (
                <Dropdown.Item key={index} onClick={() => showPolicies("vendor", vendor)}>
                  {vendor}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as={ButtonGroup}>
          <Button variant="light">Policy Type : </Button>
          <Dropdown.Toggle variant="info" id="dropdown-split-basic">
            {policyListProps.flags.type}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {policyTypes.map((type: any, index: number) => {
              return (
                <Dropdown.Item key={index} onClick={() => showPolicies("type", type)}>
                  {type}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as={ButtonGroup}>
          <Button variant="light">Policy Status : </Button>
          <Dropdown.Toggle variant="info" id="dropdown-filter">
            {policyListProps.flags.status}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {policyStatus.map((status: any, index: number) => {
              return (
                <Dropdown.Item key={index} onClick={() => showPolicies("status", status)}>
                  {status}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as={ButtonGroup}>
          <Button variant="light">Sort : </Button>
          <Dropdown.Toggle variant="info" id="dropdown-filter">
            {policyListProps.flags.orderBy}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {sortingBy.map((sort: any, index: number) => {
              return (
                <Dropdown.Item key={index} onClick={() => showPolicies("sort", sort)}>
                  {sort}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </nav>

      <table className="table table-striped table-bordered text-center">
        <thead>
          <tr>
            <th>Policy ID</th>
            <th>Order ID</th>
            <th>User ID</th>
            <th>Type</th>
            <th>Serial Number</th>
            <th>Quotation</th>
            <th>Coverage</th>
            <th>Status</th>
            <th>Creation Time</th>
          </tr>
        </thead>
        <tbody>{renderData()}</tbody>
      </table>
    </div>
  );
}
