import { CarBrand } from "../../Models/CarBrand";
import { VendorMapper } from "../../Models/VendorMapper";
import { CarOrigin, CarVendorsEnumForMapping } from "../../_helpers";
import { CarModelAdapter } from "./CarModelAdapter";
import { ModelAdapter } from "./ModelAdapter.interface";

export class CarBrandAdapter implements ModelAdapter{
  /**
   * @param jsonObject from backend API
   * @returns Instance of CarBrand after mapping jsonObject
   */
  fromAPI(jsonObject: any): CarBrand {
    const aCarBrand = new CarBrand();
    aCarBrand.key = jsonObject.key;
    aCarBrand.name = jsonObject.name;
    aCarBrand.origin = (jsonObject.origin.toLowerCase() === "chinese") ? CarOrigin.CHINESE : CarOrigin.OTHER;
    aCarBrand.models = [];

    const carModelAdapter = new CarModelAdapter();
    if (jsonObject.models) {
      for (const aModel of jsonObject.models) {
        aCarBrand.models.push(carModelAdapter.fromAPI(aModel));
      };
    }

    if (jsonObject["vendorMapper"]) {
      for (const vendorMapperKey of Object.keys(jsonObject["vendorMapper"])) {
        aCarBrand.vendorMapper.forEach(element => {
          if (element.vendorKey == vendorMapperKey) {
            element.mappingValue = jsonObject["vendorMapper"][`${vendorMapperKey}`];
          }
        });
      }
    }

    return aCarBrand;
  }
  
  /**
   * @param carBrand 
   * @return jsonObject as defined by backend APIs after mapping
   */
  toAPI(carBrand:CarBrand):any {

    const jsonObject: {key?:string, name: any, origin: any, models:Array<any>, vendorMapper:any} = {
      name: carBrand.name ,
      origin: carBrand.origin,
      models: [],
      vendorMapper: {}
    };

    if (carBrand.key && carBrand.key.length) {
      jsonObject.key = carBrand.key;
    }

    const carModelAdapter = new CarModelAdapter();
    if (carBrand.models) {
      for (const aModel of carBrand.models) {
        jsonObject.models.push(carModelAdapter.toAPI(aModel));
      };
    }

    if (carBrand.vendorMapper) {
      carBrand.vendorMapper.forEach(vendorMapper => {
        if (vendorMapper.vendorKey && vendorMapper.mappingValue && vendorMapper.mappingValue.length) {
          jsonObject.vendorMapper[vendorMapper.vendorKey] = vendorMapper.mappingValue;
        }
      });
    }

    return jsonObject;
  }


}