import { policyConstants } from "../_constants";
import { IPolicyAction } from "../interfaces/actions";
import { IPolicyListState } from "../interfaces/state";
import { arrayUnique } from "../_helpers";
import { PolicyType, PolicyStatus, Vendor, Sort } from "../_helpers/enums";

const initialPolicyListState: IPolicyListState = {
  items: [],
  flags: {
    sortBy: Sort.DESC,
    status: PolicyStatus.All,
    type: PolicyType.All,
    vendor: Vendor.All,
  },
  startDate: null,
  endDate: null,
};

export function policies(state: IPolicyListState = initialPolicyListState, action: IPolicyAction) {
  switch (action.type) {
    case policyConstants.FETCH_POLICY_LIST_REQUEST:
      return {
        items: [],
        flags: action.flags,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case policyConstants.FETCH_POLICY_LIST_SUCCESS:
      return {
        ...state,
        items: arrayUnique(state.items.concat(action.policies || []), "policyId"),
      };
    case policyConstants.FETCH_POLICY_LIST_FAILURE:
      return {
        ...state,
        items: action.policies,
      };
    case policyConstants.ADD_POLICY_DATE_RANGE:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    default:
      return state;
  }
}
