// eslint-disable-next-line
import React from "react";
import { IUserPageVideoProps } from "../../../interfaces/props";

export function UserPageVideo({ code, src }: IUserPageVideoProps) {
  return (
    <div className="container-fluid text-center">
      <h3 className="text-black h4">Code: {code}</h3>
      <div className="embed-responsive embed-responsive-16by9">
        <iframe title="VideoFrame" className="embed-responsive-item" src={src}></iframe>
      </div>
    </div>
  );
}
