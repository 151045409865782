// eslint-disable-next-line
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPartnerData } from "../../../interfaces/components/partner";
import { IPartnerListState, ILoadingState, AppState } from "../../../interfaces/state";
import { AlertAction, PartnerAction } from "../../../_actions";
import { isNotEmpty } from "../../../_helpers";
import { NoDataFound } from "../../NoDataFound";
import { Loading } from "../../Loading";

export function PartnerCreation() {
  const dispatch = useDispatch();
  const alertAction = new AlertAction();
  const partnerAction = new PartnerAction();

  const { partners, loading }: { partners: IPartnerListState; loading: ILoadingState } = useSelector(
    (state: AppState) => ({
      partners: state.partners,
      loading: state.loading,
    }),
  );

  useEffect(() => {
    dispatch(partnerAction.getPartners());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const addPartner = async () => {
    const partnerId: string = (document.getElementById("partnerID") as HTMLFormElement).value;
    if (partnerId) {
      const addingPartnerResponse = await partnerAction.addPartner(partnerId);
      if (addingPartnerResponse) {
        dispatch(alertAction.pass("Successfuly add partners"));
        window.location.reload(false);
      } else {
        dispatch(alertAction.fail("Failed to add Partner"));
      }
    } else {
      dispatch(alertAction.fail("Please enter partner data"));
    }
  };

  const renderData = () => {
    if (loading.isLoading) {
      return (
        <tr>
          <td colSpan={4}>
            <Loading />
          </td>
        </tr>
      );
    } else {
      if (
        isNotEmpty(partners.partnerList) &&
        partners.partnerList !== null &&
        typeof partners.partnerList === "object" &&
        partners.partnerList.length !== 0
      ) {
        return partners.partnerList.map((partner: IPartnerData, index: number) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{partner.id}</td>
              <td>{new Date(partner.createTime).toUTCString()}</td>
              <td>{new Date(partner.updateTime).toUTCString()}</td>
            </tr>
          );
        });
      } else {
        return (
          <tr>
            <td colSpan={4}>
              <NoDataFound data={"No partners are found"} />
            </td>
          </tr>
        );
      }
    }
  };

  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
      <form
        className="container-fluid d-flex flex-column justify-content-center align-items-center text-dark text-wrap bg-light"
        style={{ padding: "10px" }}
      >
        <div className="input-group input-group-sm mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-sm">
              Partner ID:
            </span>
          </div>
          <input type="text" className="form-control" placeholder="Enter Partner ID" id="partnerID" />
        </div>
        <button className="btn btn-success w-25" type="button" onClick={() => addPartner()}>
          Add Partner
        </button>
      </form>

      <table className="table table-striped table-bordered text-center">
        <thead>
          <tr>
            <th>#</th>
            <th>Partner ID</th>
            <th>Creation Time</th>
            <th>Update Time</th>
          </tr>
        </thead>
        <tbody>{renderData()}</tbody>
      </table>
    </div>
  );
}
