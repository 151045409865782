export const orderConstants = {
  FETCH_ORDER_LIST_REQUEST: "FETCH_ORDER_LIST_REQUEST",
  FETCH_ORDER_LIST_SUCCESS: "FETCH_ORDER_LIST_SUCCESS",
  FETCH_ORDER_LIST_FAILURE: "FETCH_ORDER_LIST_FAILURE",

  FETCH_ORDER_ITEM_REQUEST: "FETCH_ORDER_ITEM_REQUEST",
  FETCH_ORDER_ITEM_SUCCESS: "FETCH_ORDER_ITEM_SUCCESS",
  FETCH_ORDER_ITEM_FAILURE: "FETCH_ORDER_ITEM_FAILURE",

  ADD_ORDER_DATE_RANGE: "ADD_ORDER_DATE_RANGE",
};
