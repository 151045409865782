import { localizationConstants } from "../_constants";
import { ILocalizationAction } from "../interfaces/actions";
import { ILocalizationListState } from "../interfaces/state";

const initialLocalizationListState = {
  items: [],
};

export function locales(state: ILocalizationListState = initialLocalizationListState, action: ILocalizationAction) {
  switch (action.type) {
    case localizationConstants.FETCH_LOCALIZATION_LIST_REQUEST:
      return {
        items: [],
      };
    case localizationConstants.FETCH_LOCALIZATION_LIST_SUCCESS:
      return {
        items: action.locales,
      };
    case localizationConstants.FETCH_LOCALIZATION_LIST_FAILURE:
      return {
        items: action.locales,
      };
    default:
      return state;
  }
}
