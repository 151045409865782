// eslint-disable-next-line
import React, { useState } from "react";
import { Button } from "reactstrap";
import { IdData, IdValifyData } from "../../../interfaces/components/user";
import { validateNationalId, validateDate } from "../../../_helpers";
import { calculateBirthDate } from "../../../_helpers/calculateBirthDate";

interface IUserFormProps {
  idData?: IdData;
  valifyData?: IdValifyData;
  edit: boolean;
  buttonName: string;
  submitAction(data: IdData): any;
  rejectAction(): any;
}

interface IFormFieldProps {
  value: string;
  error: boolean;
}

interface IFormData {
  firstName: IFormFieldProps;
  familyName: IFormFieldProps;
  nationalIdNumber: IFormFieldProps;
  area: IFormFieldProps;
  street: IFormFieldProps;
  dateOfBirth: IFormFieldProps;
  expiryDate: IFormFieldProps;
  gender: IFormFieldProps;
  englishName: IFormFieldProps;
}

export function UserForm({ idData, valifyData, buttonName, edit, submitAction, rejectAction }: IUserFormProps) {
  const [formData, setFormData] = useState<IFormData>({
    firstName: {
      value: edit ? idData!.firstName : valifyData!.first_name,
      error: false,
    },
    familyName: {
      value: edit ? idData!.familyName : valifyData!.full_name,
      error: false,
    },
    englishName: {
      value: edit ? idData!.englishName : "",
      error: false,
    },
    nationalIdNumber: {
      value: edit ? idData!.nationalIdNumber : valifyData!.front_nid || valifyData!.back_nid,
      error: false,
    },
    area: {
      value: edit ? idData!.area : valifyData!.area,
      error: false,
    },
    street: {
      value: edit ? idData!.street : valifyData!.street,
      error: false,
    },
    dateOfBirth: {
      value: edit
        ? idData!.dateOfBirth
        : validateNationalId(valifyData!.front_nid)
        ? calculateBirthDate(valifyData!.front_nid)
        : "",
      error: false,
    },
    expiryDate: {
      value: edit ? idData!.expiryDate : valifyData!.expiration,
      error: false,
    },
    gender: {
      value: edit ? idData!.gender : valifyData!.gender || "male",
      error: false,
    },
  });

  const handleNationalIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (validateNationalId(event.target.value)) {
      setFormData({ ...formData, nationalIdNumber: { value: event.target.value, error: false } });
    } else {
      setFormData({ ...formData, nationalIdNumber: { value: event.target.value, error: true } });
    }
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setFormData({ ...formData, firstName: { value: event.target.value, error: false } });
    } else {
      setFormData({ ...formData, firstName: { value: event.target.value, error: true } });
    }
  };

  const handleFamilyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setFormData({ ...formData, familyName: { value: event.target.value, error: false } });
    } else {
      setFormData({ ...formData, familyName: { value: event.target.value, error: true } });
    }
  };

  const handleEnglishNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setFormData({ ...formData, englishName: { value: event.target.value, error: false } });
    } else {
      setFormData({ ...formData, englishName: { value: event.target.value, error: true } });
    }
  };

  const handleAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setFormData({ ...formData, area: { value: event.target.value, error: false } });
    } else {
      setFormData({ ...formData, area: { value: event.target.value, error: true } });
    }
  };

  const handleStreetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setFormData({ ...formData, street: { value: event.target.value, error: false } });
    } else {
      setFormData({ ...formData, street: { value: event.target.value, error: true } });
    }
  };

  const handleBirthDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (validateDate(event.target.value).success) {
      setFormData({ ...formData, dateOfBirth: { value: validateDate(event.target.value).data, error: false } });
    } else {
      setFormData({ ...formData, dateOfBirth: { value: event.target.value, error: true } });
    }
  };

  const handleExpiryDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (validateDate(event.target.value).success) {
      setFormData({ ...formData, expiryDate: { value: validateDate(event.target.value).data, error: false } });
    } else {
      setFormData({ ...formData, expiryDate: { value: event.target.value, error: true } });
    }
  };

  const handleGenderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value) {
      setFormData({ ...formData, gender: { value: event.target.value, error: false } });
    } else {
      setFormData({ ...formData, gender: { value: event.target.value, error: true } });
    }
  };

  const validateForm = (data: any) => {
    return data.firstName &&
      data.familyName &&
      data.nationalIdNumber &&
      data.area &&
      data.street &&
      data.gender &&
      validateDate(data.dateOfBirth).success &&
      validateDate(data.expiryDate).success
      ? true
      : false;
  };

  const submitForm = () => {
    const data: IdData = {
      firstName: formData.firstName.value,
      familyName: formData.familyName.value,
      englishName: formData.englishName.value,
      nationalIdNumber: formData.nationalIdNumber.value,
      area: formData.area.value,
      street: formData.street.value,
      dateOfBirth: formData.dateOfBirth.value,
      expiryDate: formData.expiryDate.value,
      gender: formData.gender.value,
      profession: "",
    };
    if (validateForm(data)) {
      submitAction(data);
    } else {
      rejectAction();
    }
  };

  return (
    <form
      className="d-flex flex-column justify-content-center text-dark text-wrap bg-light"
      style={{ padding: "10px" }}
    >
      <div className="input-group input-group-sm mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            First Name
          </span>
        </div>
        <input
          id="firstName"
          type="text"
          defaultValue={formData.firstName.value}
          placeholder="Please Enter client first name"
          onChange={(event) => handleFirstNameChange(event)}
          className={formData.firstName.error ? "form-control is-invalid" : "form-control"}
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
        />
      </div>
      {formData.firstName.error ? <div className="error text-danger">Client first name is required</div> : null}

      <div className="input-group input-group-sm mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            Family Name
          </span>
        </div>
        <input
          id="familyName"
          type="text"
          defaultValue={formData.familyName.value}
          placeholder="Please Enter client family name"
          onChange={(event) => handleFamilyNameChange(event)}
          className={formData.familyName.error ? "form-control is-invalid" : "form-control"}
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
        />
      </div>
      {formData.familyName.error ? <div className="error text-danger">Client family name is required</div> : null}

      <div className="input-group input-group-sm mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            English Name
          </span>
        </div>
        <input
          id="englishName"
          type="text"
          defaultValue={formData.englishName.value}
          placeholder="Please Enter client english name"
          onChange={(event) => handleEnglishNameChange(event)}
          className={formData.englishName.error ? "form-control is-invalid" : "form-control"}
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
        />
      </div>
      {formData.englishName.error ? <div className="error text-danger">Client english name is required</div> : null}

      <div className="input-group input-group-sm mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            Gender
          </span>
        </div>
        <select
          className={formData.street.error ? "form-control is-invalid" : "form-control"}
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
          defaultValue={formData.gender.value}
          onChange={(event) => handleGenderChange(event)}
        >
          <option disabled value="Select gender">
            Select gender
          </option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="others">Other</option>
        </select>
      </div>
      {formData.gender.error ? <div className="error text-danger">Client gender is required</div> : null}

      <div className="input-group input-group-sm mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            National ID
          </span>
        </div>
        <input
          id="nationalId"
          type="text"
          maxLength={14}
          defaultValue={formData.nationalIdNumber.value}
          placeholder="Please Enter client id number (14 digits)"
          onChange={(event) => handleNationalIdChange(event)}
          className={formData.nationalIdNumber.error ? "form-control is-invalid" : "form-control"}
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
        />
      </div>
      {formData.nationalIdNumber.error ? (
        <div className="error text-danger">Invalid National ID .. enter 14 digits</div>
      ) : null}

      <div className="input-group input-group-sm mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            Street
          </span>
        </div>
        <input
          id="street"
          type="text"
          defaultValue={formData.street.value}
          placeholder="Please Enter client street"
          onChange={(event) => handleStreetChange(event)}
          className={formData.street.error ? "form-control is-invalid" : "form-control"}
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
        />
      </div>
      {formData.street.error ? <div className="error text-danger">Client street is required</div> : null}

      <div className="input-group input-group-sm mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            Area
          </span>
        </div>
        <input
          id="area"
          type="text"
          defaultValue={formData.area.value}
          placeholder="Please Enter client area"
          onChange={(event) => handleAreaChange(event)}
          className={formData.area.error ? "form-control is-invalid" : "form-control"}
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
        />
      </div>
      {formData.area.error ? <div className="error text-danger">Client area is required</div> : null}

      <div className="input-group input-group-sm mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            Birth Date
          </span>
        </div>
        <input
          id="birthDate"
          type="text"
          defaultValue={formData.dateOfBirth.value}
          placeholder="yyyy/mm/dd"
          onChange={(event) => handleBirthDateChange(event)}
          className={formData.dateOfBirth.error ? "form-control is-invalid" : "form-control"}
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
        />
      </div>
      {formData.dateOfBirth.error ? (
        <div className="error text-danger">Please enter valid birth date yyyy/mm/dd</div>
      ) : null}

      <div className="input-group input-group-sm mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            ID Expiry Date
          </span>
        </div>
        <input
          id="expiryDate"
          type="text"
          defaultValue={formData.expiryDate.value}
          placeholder="yyyy/mm/dd"
          onChange={(event) => handleExpiryDateChange(event)}
          className={formData.expiryDate.error ? "form-control is-invalid" : "form-control"}
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
        />
      </div>
      {formData.expiryDate.error ? (
        <div className="error text-danger">Please enter valid expiry date yyyy/mm/dd</div>
      ) : null}

      {formData.firstName.error ||
      formData.familyName.error ||
      formData.gender.error ||
      formData.street.error ||
      formData.area.error ||
      formData.nationalIdNumber.error ||
      formData.expiryDate.error ||
      formData.dateOfBirth.error ? (
        <Button variant="info" type="button" disabled>
          {buttonName}
        </Button>
      ) : (
        <Button variant="info" type="button" onClick={() => submitForm()}>
          {buttonName}
        </Button>
      )}
    </form>
  );
}
