// eslint-disable-next-line
import React from "react";
import { NotificationItem } from "..";

export function NotificationList() {
  const notifications = [
    {
      title: "notification1",
      body: "This is Notification 1",
    },
    {
      title: "notification2",
      body: "This is Notification 2",
    },
    {
      title: "notification3",
      body: "This is Notification 3",
    },
  ];

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      className="d-flex flex-column justify-content-center align-items-center bg-light"
      style={{ minHeight: "200px", maxHeight: "450px" }}
    >
      {notifications.map((item, index) => {
        return <NotificationItem key={index} index={index + 1} item={item} />;
      })}
    </div>
  );
}
