// eslint-disable-next-line
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../Loading";
import { NoDataFound } from "../../NoDataFound";
import { AlertAction, UserAction } from "../../../_actions";
import { isNotEmpty, VerificationType } from "../../../_helpers";
import { AppState, ILoadingState } from "../../../interfaces/state";
import { IUserDataEditProps } from "../../../interfaces/props";
import { IdData } from "../../../interfaces/components/user";
import { UserForm } from "../UserForm";

export function UserDataEdit({ userId, idData, verificationId, action }: IUserDataEditProps) {
  const dispatch = useDispatch();
  const alertAction = new AlertAction();
  const userAction = new UserAction();

  const loading: ILoadingState = useSelector((state: AppState) => state.loading);

  const changeEditFlag = () => {
    action();
  };

  const submitAction = (data: IdData) => {
    dispatch(userAction.updateData(userId, data, verificationId, VerificationType.NATIONALID));
    changeEditFlag();
  };

  const rejectAction = () => {
    dispatch(alertAction.fail("Please fill all fields. Date format should be yyyy/mm/dd"));
  };

  const renderData = () => {
    if (loading.isLoading) {
      return <Loading />;
    } else {
      if (isNotEmpty(idData) && idData !== null && typeof idData === "object") {
        return (
          <UserForm
            edit={true}
            idData={idData}
            submitAction={(data: IdData) => submitAction(data)}
            rejectAction={() => rejectAction()}
            buttonName={"Update"}
          />
        );
      } else {
        return <NoDataFound data={"No Available Data for this user"} />;
      }
    }
  };

  return <div className="container-fluid col-6">{renderData()}</div>;
}
