// eslint-disable-next-line
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPartnerData } from "../../../interfaces/components/partner";
import { IPartnerListState, AppState } from "../../../interfaces/state";
import { AlertAction, PartnerAction } from "../../../_actions";
import { history } from "../../../_helpers";

export function UserCreation() {
  const dispatch = useDispatch();
  const alertAction = new AlertAction();
  const partnerAction = new PartnerAction();

  const [partnerId, setPartnerId] = useState<string | null>(null);

  const { partners }: { partners: IPartnerListState } = useSelector((state: AppState) => ({
    partners: state.partners,
    loading: state.loading,
  }));

  const handlePartnerSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPartnerId(event.target.value);
  };

  const addUser = async () => {
    const email: string = (document.getElementById("userEmail") as HTMLFormElement).value;
    const password: string = (document.getElementById("userPassword") as HTMLFormElement).value;

    if (email && password && partnerId) {
      const addingPartnerResponse = await partnerAction.addUser(email, password, partnerId);
      if (addingPartnerResponse) {
        dispatch(alertAction.pass(`Successfuly add user to partner ${partnerId}`));
        history.push("/partners");
      } else {
        dispatch(alertAction.fail(`Failed to add user to Partner ${partnerId}`));
      }
    } else {
      dispatch(alertAction.fail("Please fill the form"));
    }
  };

  return (
    <form
      className="container-fluid d-flex flex-column justify-content-center align-items-center text-dark text-wrap bg-light"
      style={{ padding: "10px" }}
    >
      <div className="input-group input-group-sm mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            Email:
          </span>
        </div>
        <input type="text" className="form-control" placeholder="Enter User Email" id="userEmail" />
      </div>
      <div className="input-group input-group-sm mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            Password:
          </span>
        </div>
        <input type="text" className="form-control" placeholder="Enter User Password" id="userPassword" />
      </div>
      <div className="input-group input-group-sm mb-3">
        <select
          className="form-control"
          defaultValue="Select Partner"
          onChange={(event) => handlePartnerSelection(event)}
        >
          <option disabled value="Select Partner">
            Select Partner
          </option>
          {partners.partnerList.map((partner: IPartnerData, index: number) => {
            return (
              <option key={index} value={partner.id}>
                {partner.id}
              </option>
            );
          })}
        </select>
      </div>
      <button className="btn btn-success w-25" type="button" onClick={() => addUser()}>
        Add User
      </button>
    </form>
  );
}
