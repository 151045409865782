// eslint-disable-next-line
import React from "react";
import { ToastContainer, toast } from "react-toastify";

export function AlertBanner() {
  return (
    <ToastContainer
      autoClose={3000}
      position={toast.POSITION.TOP_RIGHT}
      closeOnClick={true}
      pauseOnHover={true}
      newestOnTop={true}
      draggable={true}
    />
  );
}
