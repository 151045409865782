import React, { useEffect, useState } from "react";
import { CarBrand } from "../../../Models/CarBrand";
import { CarModel } from "../../../Models/CarModel";
import { CarBrandService } from "../../../Services/CarListServices/carBrandService";
import { CarListService } from "../../../Services/CarListServices/carlistService";
import { Loading } from "../../Loading";
import { CarBrandDetails } from "../CarBrandDetailsPopup/CarBrandDetails";
import { CarBrandRow } from "./CarBrandRow";

import "./CarListPage.css";

export function CarListAdministrationPage() {

  const [carList, setCarList] = useState([] as CarBrand[]);
  const [isAddCarBrandOpened, setIsAddCarBrandOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCarList();
  }, []);

  async function getCarList() {
    try {
      setLoading(true);
      const carListService = new CarListService();
      const carListData = await carListService.getCarList();
      setCarList(carListData);
      setLoading(false);
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  }

  function closeBrandModal() {
    setIsAddCarBrandOpened(false);
    getCarList();//TODO add attribute to know if require force refresh or no
  }

  function carBrandModifiedDelegate () {
    getCarList();
  }

  return (
      <div className="car-list container-fluid">
        {loading && <Loading></Loading>}
        {isAddCarBrandOpened && <CarBrandDetails carBrand={null} closeCarBrandDetailsDelegate={closeBrandModal}></CarBrandDetails>}
        {!loading && <button className="btn btn-success col-2" onClick={()=>setIsAddCarBrandOpened(true)}>Add Brand</button>}
        <div className="car-list-accordion">
            {carList.map((carBrand)=> (
              <CarBrandRow key={carBrand.key} carBrand={carBrand} carBrandModifiedDelegate={carBrandModifiedDelegate}></CarBrandRow>
            ))}
        </div>
      </div>
  );
}