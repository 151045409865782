import { ticketConstants } from "../_constants";
import { ITicketAction, ITicketResponsesAction, IAllTicketsAction } from "../interfaces/actions";
import { ITicketItemState, ITicketResponseState, ITicketListState } from "../interfaces/state";
import { Sort, TicketStatus } from "../_helpers/enums";

const initialTicketItemState: ITicketItemState = {
  item: {
    uid: "",
    userEmail: "",
    isOpen: false,
    isRead: false,
    hasNewUserResponse: false,
    id: "",
    author: {
      user: "",
    },
    body: "",
    subject: "",
    createTime: 11,
    timestamp: "11",
    updateTime: 11,
    exists: false,
    parent: null,
  },
};

const initialTicketListState = {
  items: [],
  flags: {
    sortBy: Sort.DESC,
    status: TicketStatus.OPENED,
  },
  startDate: null,
  endDate: null,
};

const initialTicketResponsesState = {
  items: [],
};

export function ticket(state: ITicketItemState = initialTicketItemState, action: ITicketAction) {
  switch (action.type) {
    case ticketConstants.FETCH_TICKET_ITEM_REQUEST:
      return {
        item: {},
      };
    case ticketConstants.FETCH_TICKET_ITEM_SUCCESS:
      return {
        item: action.ticket,
      };
    case ticketConstants.FETCH_TICKET_ITEM_FAILURE:
      return {
        item: {},
      };
    default:
      return state;
  }
}

export function tickets(state: ITicketListState = initialTicketListState, action: IAllTicketsAction) {
  switch (action.type) {
    case ticketConstants.FETCH_TICKET_LIST_REQUEST:
      return {
        items: [],
        flags: action.flags,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case ticketConstants.FETCH_TICKET_LIST_SUCCESS:
      return {
        ...state,
        items: action.tickets,
      };
    case ticketConstants.FETCH_TICKET_LIST_FAILURE:
      return {
        ...state,
        items: [],
      };
    case ticketConstants.ADD_TICKET_DATE_RANGE:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    default:
      return state;
  }
}

export function responses(state: ITicketResponseState = initialTicketResponsesState, action: ITicketResponsesAction) {
  switch (action.type) {
    case ticketConstants.FETCH_TICKET_RESPONSES_SUCCESS:
      return {
        items: action.responses,
      };
    case ticketConstants.FETCH_TICKET_RESPONSES_FAILURE:
      return {
        items: [],
      };
    default:
      return state;
  }
}
