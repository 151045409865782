import { verificationConstants, apiConstants } from "../_constants";
import { authHeader, handleAxiosDispatch, isNotEmpty, fetchRequest } from "../_helpers";
import { LoadingAction } from ".";
import { IVerificationDetails, IVerificationData } from "../interfaces/components/verification";
import { Dispatch } from "redux";
import {
  IVerificationAction,
  IVerificationListAction,
  IVerificationDetailsAction,
  ILoadingAction,
} from "../interfaces/actions";

export class VerificationAction {
  private dispatchVerificationListRequest = (type: string, status: string, sortBy: string) => {
    return {
      type: verificationConstants.FETCH_VERIFICATION_LIST_REQUEST,
      flags: {
        type,
        status,
        sortBy,
      },
    };
  };

  private dispatchVerificationListSuccess = (
    verifications: Array<IVerificationData>,
    type: string,
    status: string,
    sortBy: string,
  ) => {
    return {
      type: verificationConstants.FETCH_VERIFICATION_LIST_SUCCESS,
      verifications,
      flags: {
        type,
        status,
        sortBy,
      },
    };
  };

  private dispatchVerificationListFailure = () => {
    return {
      type: verificationConstants.FETCH_VERIFICATION_LIST_FAILURE,
      verifications: {},
    };
  };

  private dispatchUserVerificationRequest = () => {
    return {
      type: verificationConstants.FETCH_USER_VERIFICATIONS_REQUEST,
    };
  };

  private dispatchUserVerificationSuccess = (verifications: Array<IVerificationData>) => {
    return {
      type: verificationConstants.FETCH_USER_VERIFICATIONS_SUCCESS,
      verifications,
    };
  };

  private dispatchUserVerificationFailure = () => {
    return {
      type: verificationConstants.FETCH_USER_VERIFICATIONS_FAILURE,
      verifications: {},
    };
  };

  private dispatchDetailsSuccess = (verification: IVerificationDetails) => {
    return {
      type: verificationConstants.FETCH_VERIFICATION_DETAILS_SUCCESS,
      verification,
    };
  };

  private dispatchDetailsFailure = () => {
    return {
      type: verificationConstants.FETCH_VERIFICATION_DETAILS_FAILURE,
      verification: {},
    };
  };

  dispatchStatus = (id: string, status: string) => {
    return {
      type: verificationConstants.UPDATE_VERIFICATION_STATUS,
      payload: {
        id,
        status,
      },
    };
  };

  getAll = (
    type: string,
    status: string,
    sortBy: string,
    verifications: Array<IVerificationData>,
    append: boolean,
    newData: boolean,
  ) => {
    const loading = new LoadingAction();
    return async (dispatch: Dispatch<IVerificationListAction>) => {
      try {
        let nextBatch, nextUserId, url;
        if (newData && append) {
          if (
            isNotEmpty(verifications) &&
            verifications !== null &&
            typeof verifications === "object" &&
            verifications.length !== 0
          ) {
            // reload with verifications
            url = apiConstants.API_GET_NEW_VERIFICATIONS;
            nextBatch = verifications[verifications.length - 1].verificationId;
            nextUserId = verifications[verifications.length - 1].userId;
          } else {
            // reload without verifications
            dispatch(this.dispatchVerificationListRequest(type, status, sortBy));
            url = apiConstants.API_GET_VERIFICATIONS;
            nextBatch = null;
            nextUserId = null;
          }
        } else {
          url = apiConstants.API_GET_VERIFICATIONS;
          if (!append) {
            dispatch(this.dispatchVerificationListRequest(type, status, sortBy));
            nextBatch = null;
            nextUserId = null;
          } else {
            if (
              isNotEmpty(verifications) &&
              verifications !== null &&
              typeof verifications === "object" &&
              verifications.length !== 0
            ) {
              nextBatch = verifications[verifications.length - 1].verificationId;
              nextUserId = verifications[verifications.length - 1].userId;
            } else {
              dispatch(this.dispatchVerificationListRequest(type, status, sortBy));
              nextBatch = null;
              nextUserId = null;
            }
          }
        }
        dispatch(loading.isLoading(true));

        const response = await fetchRequest(
          "GET",
          url,
          authHeader(),
          {},
          {
            sort: sortBy,
            filterStatus: status,
            filterType: type,
            nextBatch,
            nextUserId,
          },
        );

        if (response.success) {
          dispatch(this.dispatchVerificationListSuccess(response.data, type, status, sortBy));
          dispatch(loading.isLoading(false));
        } else {
          throw new Error();
        }
      } catch (error) {
        handleAxiosDispatch(
          error,
          "Failed to get verifications",
          dispatch,
          this.getAll,
          this.dispatchVerificationListFailure,
          [type, status, sortBy, verifications, append, newData],
        );
        dispatch(loading.isLoading(false));
      }
    };
  };

  getByUser = (userId: string, sortBy: string, nextBatch: string | null, append: boolean) => {
    const loading = new LoadingAction();
    return async (dispatch: Dispatch<IVerificationAction | ILoadingAction>) => {
      try {
        if (!append) {
          dispatch(this.dispatchUserVerificationRequest());
        }
        dispatch(loading.isLoading(true));
        const response = await fetchRequest(
          "GET",
          `${apiConstants.API_GET_USER_ID}/${userId}/history`,
          authHeader(),
          {},
          {
            sort: sortBy,
            nextBatch,
          },
        );

        if (response.success) {
          dispatch(this.dispatchUserVerificationSuccess(response.data));
          dispatch(loading.isLoading(false));
        } else {
          throw new Error();
        }
      } catch (error) {
        handleAxiosDispatch(
          error,
          "Failed to get verifications",
          dispatch,
          this.getByUser,
          this.dispatchUserVerificationFailure,
          [userId, sortBy, nextBatch, append],
        );
        dispatch(loading.isLoading(false));
      }
    };
  };

  getDetails = (userId: string, verificationId: string, isLoading: boolean = true) => {
    const loading = new LoadingAction();
    return async (dispatch: Dispatch<ILoadingAction | IVerificationDetailsAction>) => {
      try {
        if (isLoading) dispatch(loading.isLoading(true));
        const response = await fetchRequest(
          "GET",
          `${apiConstants.API_GET_USER_ID}/${userId}/verification/${verificationId}`,
          authHeader(),
        );

        if (response.success) {
          dispatch(this.dispatchDetailsSuccess(response.data));
          if (isLoading) dispatch(loading.isLoading(false));
        } else {
          throw new Error();
        }
      } catch (error) {
        handleAxiosDispatch(
          error,
          "Failed to get verifications",
          dispatch,
          this.getDetails,
          this.dispatchDetailsFailure,
          [userId, verificationId],
        );
        if (isLoading) dispatch(loading.isLoading(false));
      }
    };
  };
}
