import { partnerConstants } from "../_constants";
import { IPartnersAction } from "../interfaces/actions";
import { IPartnerListState } from "../interfaces/state";

const initialPartnerListState = {
  users: [],
  partnerList: [],
  flags: {
    vendor: "gig",
  },
};

export function partners(state: IPartnerListState = initialPartnerListState, action: IPartnersAction) {
  switch (action.type) {
    case partnerConstants.FETCH_PARTNER_USERS_REQUST:
      return {
        ...state,
        users: [],
      };
    case partnerConstants.FETCH_PARTNER_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
      };
    case partnerConstants.FETCH_PARTNER_USERS_FAILURE:
      return {
        ...state,
        users: action.users,
      };
    case partnerConstants.FETCH_PARTNER_LIST_REQUST:
      return {
        ...state,
        partnerList: [],
      };
    case partnerConstants.FETCH_PARTNER_LIST_SUCCESS:
      return {
        ...state,
        partnerList: action.partners,
      };
    case partnerConstants.FETCH_PARTNER_LIST_FAILURE:
      return {
        ...state,
        partnerList: action.partners,
      };
    default:
      return state;
  }
}
