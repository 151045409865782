// eslint-disable-next-line
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Nav } from "react-bootstrap";
import { Loading } from "../../Loading";
import { NoDataFound } from "../../NoDataFound";
import { UserPageVideo, UserPageID } from "../../Users";
import { DataElement } from "../../DataElement";
import { checkState, isNotEmpty, VerificationType, VerificationStatus, getLocalDate } from "../../../_helpers";
import { VerificationAction, UserAction } from "../../../_actions";
import { AppState, IVerificationDetailsState, ILoadingState } from "../../../interfaces/state";
import { IMatchProps } from "../../../interfaces/props";

export function VerificationPage({ match }: IMatchProps) {
  const dispatch = useDispatch();
  const userAction = new UserAction();
  const verificationAction = new VerificationAction();

  const {
    params: { userId, verificationId },
  } = match;
  const {
    verificationDetails,
    loading,
  }: {
    verificationDetails: IVerificationDetailsState;
    loading: ILoadingState;
  } = useSelector((state: AppState) => ({
    verificationDetails: state.verificationDetails,
    loading: state.loading,
  }));

  useEffect(() => {
    dispatch(userAction.getByID(userId!, true));
    dispatch(verificationAction.getDetails(userId!, verificationId!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId, verificationId]);

  const applyVerification = () => {
    dispatch(userAction.changeVerificationToPending(userId!, verificationId!));
  };

  const renderData = () => {
    if (loading.isLoading) {
      return <Loading />;
    } else {
      if (
        typeof verificationDetails.items === "object" &&
        verificationDetails.items !== null &&
        isNotEmpty(verificationDetails.items)
      ) {
        return (
          <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
            <Nav>
              <Nav.Item>
                <Nav.Link href={`/users/${userId}`}>User Page</Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="container-fluid d-flex flex-column justify-content-center align-items-left">
              <div className="card-header">
                <DataElement title="Verification Type" value={verificationDetails.items.type} />
                <DataElement title="Verification Status" value={verificationDetails.items.status} />
                <DataElement title="Verification Date" value={getLocalDate(verificationDetails.items.timestamp)} />

                {isNotEmpty(verificationDetails.items.reason!) &&
                verificationDetails.items.reason! !== null &&
                typeof verificationDetails.items.reason! === "object" &&
                verificationDetails.items.reason!.length !== 0 ? (
                  <div>
                    Status Reasons:
                    <div className="card-body">
                      {verificationDetails.items.reason.map((reason, index) => {
                        return <DataElement key={index} value={reason} />;
                      })}
                    </div>
                  </div>
                ) : null}

                {isNotEmpty(verificationDetails.items.valifyRejectReasons!) &&
                verificationDetails.items.valifyRejectReasons! !== null &&
                typeof verificationDetails.items.valifyRejectReasons! === "object" &&
                verificationDetails.items.valifyRejectReasons!.length !== 0 ? (
                  <div>
                    Valify Reject Reasons:
                    <div className="card-body">
                      {verificationDetails.items.valifyRejectReasons.map((reason, index) => {
                        return <DataElement key={index} value={reason} />;
                      })}
                    </div>
                  </div>
                ) : null}

                {isNotEmpty(verificationDetails.items.valifyRaw!) &&
                verificationDetails.items.valifyRaw! !== null &&
                typeof verificationDetails.items.valifyRaw! === "object" ? (
                  <div>
                    Valify Data:
                    <div className="card-body">
                      {Object.keys(verificationDetails.items.valifyRaw!).map((key: string) => (
                        <DataElement key={key} title={key} value={`${verificationDetails.items.valifyRaw![key]}`} />
                      ))}
                    </div>
                  </div>
                ) : null}

                {isNotEmpty(verificationDetails.items.valifyTimestamp!) &&
                verificationDetails.items.valifyTimestamp! !== null &&
                typeof verificationDetails.items.valifyTimestamp! === "object" ? (
                  <div>
                    Valify TimeStamp:
                    <div className="card-body">
                      {Object.keys(verificationDetails.items.valifyTimestamp!).map((key) => (
                        <DataElement
                          key={key}
                          title={key}
                          value={
                            verificationDetails.items.valifyTimestamp![key]
                              ? `${verificationDetails.items.valifyTimestamp![key]}`
                              : "no data available"
                          }
                        />
                      ))}
                    </div>
                  </div>
                ) : null}

                <div className="container-fluid col-6">
                  {checkState(verificationDetails.items.type, VerificationType.NATIONALID) ? (
                    <UserPageID imagesUrls={[verificationDetails.items.nationalIdFrontUrl!, verificationDetails.items.nationalIdBackUrl!]} />
                  ) : null}

                  {checkState(verificationDetails.items.type, VerificationType.VIDEO) ? (
                    <UserPageVideo
                      code={verificationDetails.items.videCodes!}
                      src={verificationDetails.items.videoUrl!}
                    />
                  ) : null}
                </div>
              </div>
            </div>

            <div>
              {checkState(verificationDetails.items.status, VerificationStatus.REJECTED) ? (
                <Button variant="success" type="button" onClick={() => applyVerification()}>
                  Verify
                </Button>
              ) : null}
            </div>
          </div>
        );
      } else {
        return <NoDataFound data={"No Verification Details are found"} />;
      }
    }
  };

  return <div className="container-fluid">{renderData()}</div>;
}