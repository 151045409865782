import React, { useState } from "react";
import { CarModelRow } from "./CarModelRow";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import { CarBrand } from "../../../Models/CarBrand";
import { CarModelDetails } from "../CarModelDetailsPopup/CarModelDetails";
import { ConfirmationDialog } from "../../ConfirmationDialog/ConfirmationDialog";
import { CarBrandDetails } from "../CarBrandDetailsPopup/CarBrandDetails";
import { CarOriginOptions } from "../CarOriginOptions/CarOriginOptions";
import { CarBrandService } from "../../../Services/CarListServices/carBrandService";
import { Loading } from "../../Loading";

export function CarBrandRow({carBrand, carBrandModifiedDelegate}:{carBrand:CarBrand, carBrandModifiedDelegate:any}) {
  const [isModelsRowsOpen, setIsModelsRowsOpen] = useState(false);
  const [isAddModelOpened, setIsAddModelOpened] = useState(false);
  const [isEditBrandOpened, setIsEditBrandOpened] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  function deleteDialogCancelAction(){
    setShowDeleteConfirmation(false);
  }

  async function deleteDialogConfirmAction(){
    try {
      setLoading(true);
      const carBrandService = new CarBrandService();
      await carBrandService.deleteBrand(carBrand);
      carBrandModifiedDelegate();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  
  function closeCarModelDetailsDelegate() {
    setIsAddModelOpened(false);
    carBrandModifiedDelegate();
  }

  function carModelModifiedDelegate() {
    setIsAddModelOpened(false);
    carBrandModifiedDelegate();
  }

  function closeCarBrandDetailsDelegate() {
    setIsEditBrandOpened(false);
    carBrandModifiedDelegate();
  }

  return(
      <div className="container-fluid">
        {isAddModelOpened && <CarModelDetails carModel={null} carBrandKey={carBrand.key} closeCarModelDetailsDelegate={closeCarModelDetailsDelegate}></CarModelDetails>}
        {isEditBrandOpened && <CarBrandDetails carBrand={carBrand} closeCarBrandDetailsDelegate={closeCarBrandDetailsDelegate}></CarBrandDetails>}
        {showDeleteConfirmation && <ConfirmationDialog message="Are you sure ?" onCancelButtonAction={deleteDialogCancelAction} onConfirmButtonAction={deleteDialogConfirmAction}></ConfirmationDialog>}

        <div className="row">
            <Button
              className="btn btn-light col brand-name-button"
              onClick={() => setIsModelsRowsOpen(!isModelsRowsOpen)}
              aria-controls="models-collapse-rows"
              aria-expanded={isModelsRowsOpen}
            >
              {carBrand.name}
            </Button>

            <div className="col my-auto">
              <span>Origin : </span>
              {carBrand.origin}
            </div>

            <div className="col my-auto">
              <span style={(!carBrand.vendorMapper.length ? {color:"red"} : {})}>{(carBrand.vendorMapper.length) ? carBrand.vendorMapperToString() : "not mapped"}</span>
            </div>

            {loading ? 
            <Loading></Loading>
            : 
            <div className="col my-auto" >
              <Button className="btn btn-success " onClick={()=>setIsAddModelOpened(true)} >Add Model</Button>
              <Button className="btn btn-warning " onClick={()=>setIsEditBrandOpened(true)}>Edit</Button>
              <Button className="btn btn-danger " onClick={()=>setShowDeleteConfirmation(true)}>Delete</Button>
            </div>
            }

        </div>
        <Collapse in={isModelsRowsOpen}>
          <div id="models-collapse-rows">
            {carBrand.models.map((carModel)=> (
                <CarModelRow carBrandKey={carBrand.key} key={carModel.key} carModel={carModel} carModelModifiedDelegate={carModelModifiedDelegate}></CarModelRow>
            ))}
          </div>
        </Collapse>
    </div>
  );

}