import { ProductId, Vendor } from "../_helpers";

export class ManualOrder {
  phoneNumber  = "";
  partnerId : Vendor | undefined;
  productId : ProductId | undefined;
  amount = 0;
  currency = "EGP";
  fullName = "";
  shouldCreateManualPolicy  = true;
  manualPolicy!: {
    title: string;
    serialNumber: string;
    description: string;
    coverage: number;
  }
}