// eslint-disable-next-line
import React from "react";
import { INoDataProps } from "../../interfaces/props";

export function NoDataFound({ data }: INoDataProps) {
  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
      <p>{data}</p>
    </div>
  );
}
