import { Dispatch } from "redux";
import { ticketConstants, apiConstants } from "../_constants";
import {
  authHeader,
  handleAxiosDispatch,
  handleAxios,
  history,
  createAccessToken,
  fetchRequest,
  TicketStatus,
  Sort,
} from "../_helpers";
import { AlertAction, LoadingAction } from ".";
import { ILoadingAction, ITicketAction, ITicketResponsesAction } from "../interfaces/actions";
import { ITicket, ITicketResponses } from "../interfaces/components/ticket";

export class TicketAction {
  private dispatchTicketItemRequest = () => {
    return {
      type: ticketConstants.FETCH_TICKET_ITEM_REQUEST,
    };
  };

  private dispatchTicketItemSuccess = (ticket: ITicket) => {
    return {
      type: ticketConstants.FETCH_TICKET_ITEM_SUCCESS,
      ticket,
    };
  };

  private dispatchTicketItemFailure = () => {
    return {
      type: ticketConstants.FETCH_TICKET_ITEM_FAILURE,
    };
  };

  private dispatchTicketListRequest = (status: TicketStatus, sortBy: Sort, startDate: string, endDate: string) => {
    return {
      type: ticketConstants.FETCH_TICKET_LIST_REQUEST,
      flags: {
        status,
        sortBy,
      },
      startDate,
      endDate,
    };
  };

  private dispatchTicketListSuccess = (tickets: Array<ITicket>) => {
    return {
      type: ticketConstants.FETCH_TICKET_LIST_SUCCESS,
      tickets,
    };
  };

  private dispatchTicketListFailure = () => {
    return {
      type: ticketConstants.FETCH_TICKET_LIST_FAILURE,
    };
  };

  private dispatchTicketResponseSuccess = (responses: Array<ITicketResponses>) => {
    return {
      type: ticketConstants.FETCH_TICKET_RESPONSES_SUCCESS,
      responses,
    };
  };

  private dispatchTicketResponseFailure = () => {
    return {
      type: ticketConstants.FETCH_TICKET_RESPONSES_FAILURE,
    };
  };

  addDateRange = (startDate: string, endDate: string) => {
    return {
      type: ticketConstants.ADD_TICKET_DATE_RANGE,
      startDate,
      endDate,
    };
  };

  getByID = (userId: string, ticketId: string) => {
    const loading = new LoadingAction();
    return async (dispatch: Dispatch<ILoadingAction | ITicketAction>) => {
      try {
        dispatch(this.dispatchTicketItemRequest());
        dispatch(loading.isLoading(true));
        const response = await fetchRequest(
          "GET",
          `${apiConstants.API_GET_TICKETS}/${ticketId}/user/${userId}`,
          authHeader(),
        );
        if (response.success) {
          dispatch(this.dispatchTicketItemSuccess(response.data.ticket));
          dispatch(loading.isLoading(false));
        } else {
          throw new Error();
        }
      } catch (error) {
        handleAxiosDispatch(
          error,
          `Failed to get ticket ${ticketId}`,
          dispatch,
          this.getByID,
          this.dispatchTicketItemFailure,
          [userId, ticketId],
        );
        dispatch(loading.isLoading(false));
      }
    };
  };

  getAll = (status: TicketStatus, sortBy: Sort, startDate: string, endDate: string) => {
    const loading = new LoadingAction();
    return async (dispatch: Dispatch<ITicketAction>) => {
      try {
        dispatch(this.dispatchTicketListRequest(status, sortBy, startDate, endDate));
        dispatch(loading.isLoading(true));
        const response = await fetchRequest(
          "GET",
          apiConstants.API_GET_TICKETS,
          authHeader(),
          {},
          {
            sort: sortBy,
            filterStatus: status,
            startDate,
            endDate,
          },
        );
        if (response.success) {
          dispatch(this.dispatchTicketListSuccess(response.data.tickets));
          dispatch(loading.isLoading(false));
        } else {
          throw new Error();
        }
      } catch (error) {
        handleAxiosDispatch(error, "Failed to get tickets", dispatch, this.getAll, this.dispatchTicketListFailure, [
          status,
          sortBy,
          startDate,
          endDate,
        ]);
        dispatch(loading.isLoading(false));
      }
    };
  };

  getResponses = (userId: string, ticketId: string) => {
    return async (dispatch: Dispatch<ITicketResponsesAction>) => {
      try {
        const response = await fetchRequest(
          "GET",
          `${apiConstants.API_GET_TICKETS}/${ticketId}/user/${userId}/response`,
          authHeader(),
        );
        if (response.success) {
          dispatch(this.dispatchTicketResponseSuccess(response.data.responses));
        } else {
          throw new Error();
        }
      } catch (error) {
        handleAxiosDispatch(
          error,
          "Failed to get responses",
          dispatch,
          this.getResponses,
          this.dispatchTicketResponseFailure,
          [userId, ticketId],
        );
      }
    };
  };

  createTicket = async (userId: string, data: any) => {
    try {
      const response = await fetchRequest("POST", `${apiConstants.API_GET_TICKETS}`, authHeader(), { data });
      if (response.success) {
        return response.data.ticketId;
      } else {
        throw new Error("Failed to create new ticket. Please try later");
      }
    } catch (error) {
      return handleAxios(error, this.createTicket, [userId, data]);
    }
  };

  createResponse = async (userId: string, ticketId: string, data: any) => {
    try {
      const response = await fetchRequest(
        "POST",
        `${apiConstants.API_GET_TICKETS}/${ticketId}/user/${userId}/response`,
        authHeader(),
        { data },
      );
      if (response.success) {
        return response.data.responseId;
      } else {
        throw new Error();
      }
    } catch (error) {
      return handleAxios(error, this.createResponse, [userId, ticketId, data]);
    }
  };

  uploadTicket = (userId: string, ticketId: string, files: Array<any>) => {
    const loading = new LoadingAction();
    const alert = new AlertAction();
    return async (dispatch: Dispatch<any>) => {
      try {
        const destinationPath = `${userId}/helpdesk/${ticketId}`;
        const token = await createAccessToken();
        dispatch(loading.isLoading(true));
        await Promise.all(files.map((file) => this.uploadFileToStorage(file, destinationPath, token)));
        dispatch(alert.pass("Successfully Create a TicketAction"));
        dispatch(loading.isLoading(false));
        history.push(`/tickets/${userId}/${ticketId}`);
      } catch (error) {
        dispatch(loading.isLoading(false));
        dispatch(alert.fail("Failed to upload attachment files"));
        handleAxiosDispatch(error, "Failed to upload attachment files", dispatch, this.uploadTicket, null, [
          userId,
          ticketId,
          files,
        ]);
      }
    };
  };

  uploadResponse = async (userId: string, ticketId: string, responseId: string, files: Array<any>) => {
    try {
      const destinationPath = `${userId}/helpdesk/${ticketId}/responses/${responseId}`;
      const token = await createAccessToken();
      await Promise.all(files.map((file) => this.uploadFileToStorage(file, destinationPath, token)));
      return true;
    } catch (error) {
      return handleAxios(error, this.uploadResponse, [userId, ticketId, responseId, files]);
    }
  };

  uploadFileToStorage = async (file: any, destinationPath: string, token: string) => {
    try {
      const BUCKET_NAME = process.env.REACT_APP_BACKOFFICE_UPLOADS;
      const FILE_NAME = file.name;
      const FILE_TYPE = file.type;
      const url = `https://storage.googleapis.com/upload/storage/v1/b/${BUCKET_NAME}/o?uploadType=media&name=${destinationPath}/${FILE_NAME}`;
      return await fetchRequest(
        "POST",
        url,
        {
          Authorization: `Bearer ${token}`,
          "Content-Type": `${FILE_TYPE}`,
        },
        file,
      );
    } catch (error) {
      throw new Error("Failed to upload attachment");
    }
  };

  getAttachmentUrl = async (filePath: string) => {
    try {
      const response = await fetchRequest(
        "GET",
        `${apiConstants.API_DOWNLOAD_ATTACHMENT}?filePath=${filePath}`,
        authHeader(),
      );
      if (response.success) {
        return response.data.attachmentUrl;
      } else {
        throw new Error();
      }
    } catch (error) {
      return null;
    }
  };

  closeTicket = async (userId: string, ticketId: string) => {
    try {
      const response = await fetchRequest(
        "POST",
        `${apiConstants.API_CLOSE_TICKET}/${ticketId}/user/${userId}`,
        authHeader(),
      );
      if (response.success) {
        return;
      } else {
        throw new Error("Failed to close ticket. Please try later");
      }
    } catch (error) {
      return handleAxios(error, this.createTicket, [ticketId]);
    }
  };
}
