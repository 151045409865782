// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { LocalizationItem } from "..";
import { Loading } from "../../Loading";
import { NoDataFound } from "../../NoDataFound";
import { isNotEmpty } from "../../../_helpers";
import { AlertAction, LocalizationAction } from "../../../_actions";
import { ILocalizationProps } from "../../../interfaces/props";
import { AppState, ILoadingState, ILocalizationListState } from "../../../interfaces/state";

export function LocalizationList() {
  const dispatch = useDispatch();
  const alertAction = new AlertAction();
  const localizationAction = new LocalizationAction();

  const { locales, loading }: { locales: ILocalizationListState; loading: ILoadingState } = useSelector(
    (state: AppState) => ({
      locales: state.locales,
      loading: state.loading,
    }),
  );

  const [localizationProps, setLocalizationProps] = useState<ILocalizationProps>({
    file: null,
    flag: false,
    fileName: "",
  });

  useEffect(() => {
    dispatch(localizationAction.getKeys());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //TODO: can't update state with fileName
    const input = event.target.files![0];
    const reader: any = new FileReader();
    reader.onload = function () {
      setLocalizationProps((localizationProps) => ({
        ...localizationProps,
        fileName: input.name.split(".json")[0],
        file: reader.result,
        flag: true,
      }));
    };
    reader.readAsText(input);
  };

  const handleFileUpload = (id: string, file: any) => {
    dispatch(localizationAction.upload(id, file));
    setLocalizationProps((localizationProps) => ({
      ...localizationProps,
      flag: false,
    }));
  };

  const downloadTranslationTemplate = async () => {
    const data = await localizationAction.getTranslationTemplate(null);
    if (data) {
      const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "translation.json");
      document.body.appendChild(link);
      link.click();
      dispatch(alertAction.pass("Successfully Download Translation Template"));
    } else {
      dispatch(alertAction.fail("Failed to import translation template. Please try later"));
    }
  };

  const renderData = () => {
    if (loading.isLoading) {
      return (
        <tr>
          <td colSpan={5}>
            <Loading />
          </td>
        </tr>
      );
    } else {
      if (
        isNotEmpty(locales.items) &&
        locales.items !== null &&
        typeof locales.items === "object" &&
        locales.items.length !== 0
      ) {
        return locales.items.map((item: any, index: number) => {
          return <LocalizationItem key={index} title={item} index={index + 1} handleFileUpload={handleFileUpload} />;
        });
      } else {
        return (
          <tr>
            <td colSpan={5}>
              <NoDataFound data={"No Locale files are found"} />
            </td>
          </tr>
        );
      }
    }
  };

  return (
    <div className="container-fluid d-flex flex-column justify-content-between align-items-center">
      <div className="align-self-end" style={{ margin: "20px" }}>
        <Button variant="info" type="button" onClick={() => downloadTranslationTemplate()}>
          Download Translation Template
        </Button>
      </div>

      <table className="table table-striped table-bordered text-center">
        <thead>
          <tr>
            <th>#</th>
            <th>Type</th>
            <th>Download</th>
            <th>Upload</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>{renderData()}</tbody>
      </table>

      <div className="border border-secondary" style={{ margin: "15px", padding: "15px" }}>
        <div>Add Translation File</div>
        <input type="file" accept=".json" onChange={(event) => handleFileChange(event)} />
        {localizationProps.flag ? (
          <Button
            variant="info"
            type="button"
            onClick={() => handleFileUpload(localizationProps.fileName!, localizationProps.file)}
          >
            Import JSON
          </Button>
        ) : (
          <Button variant="info" type="button" disabled={true}>
            Import JSON
          </Button>
        )}
      </div>
    </div>
  );
}
