// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Nav, Button, Dropdown, ButtonGroup } from "react-bootstrap";
import { Loading } from "../../Loading";
import { NoDataFound } from "../../NoDataFound";
import { getLocalDate, isNotEmpty } from "../../../_helpers";
import { VerificationAction } from "../../../_actions";
import { IMatchProps } from "../../../interfaces/props";
import { IUserVerificationState, ILoadingState, AppState } from "../../../interfaces/state";
import { IVerificationData } from "../../../interfaces/components/verification";

export function UserPageHistory({ match }: IMatchProps) {
  const dispatch = useDispatch();
  const verificationAction = new VerificationAction();

  const {
    userVerifications,
    loading,
  }: {
    userVerifications: IUserVerificationState;
    loading: ILoadingState;
  } = useSelector((state: AppState) => ({
    userVerifications: state.userVerifications,
    loading: state.loading,
  }));

  const {
    params: { userId },
  } = match;
  const [filterByTimeStamp, setFilterByTimeStampFlag] = useState("desc");

  useEffect(() => {
    dispatch(verificationAction.getByUser(userId!, filterByTimeStamp, null, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId, filterByTimeStamp]);

  const loadMoreVerifications = () => {
    if (
      isNotEmpty(userVerifications.items) &&
      userVerifications.items !== null &&
      typeof userVerifications.items === "object" &&
      userVerifications.items.length !== 0
    ) {
      dispatch(
        verificationAction.getByUser(
          userId!,
          filterByTimeStamp,
          userVerifications.items[userVerifications.items.length - 1].verificationId,
          true,
        ),
      );
    }
  };

  const showVerifications = (value: string) => {
    setFilterByTimeStampFlag(value);
  };

  const renderData = () => {
    if (loading.isLoading) {
      return (
        <tr>
          <td colSpan={4}>
            <Loading />
          </td>
        </tr>
      );
    } else {
      if (
        isNotEmpty(userVerifications.items) &&
        userVerifications.items !== null &&
        typeof userVerifications.items === "object" &&
        userVerifications.items.length !== 0
      ) {
        return userVerifications.items.map((verification: IVerificationData, index: number) => {
          return (
            <tr key={index}>
              <td>{verification.type}</td>
              <td>{verification.status}</td>
              <td>{getLocalDate(verification.timestamp)}</td>
              <td style={{ cursor: "pointer" }}>
                <a href={`/users/${userId}/verifications/${verification.verificationId}`}>Display Details</a>
              </td>
            </tr>
          );
        });
      } else {
        return (
          <tr>
            <td colSpan={4}>
              <NoDataFound data={"No Verifications are found"} />
            </td>
          </tr>
        );
      }
    }
  };

  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
      <Nav>
        <Nav.Item>
          <Nav.Link href={`/users/${userId}`}>View User Page</Nav.Link>
        </Nav.Item>
      </Nav>

      <Dropdown as={ButtonGroup}>
        <Button variant="light">Sort : </Button>
        <Dropdown.Toggle variant="info" id="dropdown-filter">
          {filterByTimeStamp}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => showVerifications("asc")}>Ascending</Dropdown.Item>
          <Dropdown.Item onClick={() => showVerifications("desc")}>Descending</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <table className="table table-striped table-bordered text-center">
        <thead>
          <tr>
            <th>Type</th>
            <th>Status</th>
            <th>Date</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>{renderData()}</tbody>
      </table>

      <Button variant="light" onClick={() => loadMoreVerifications()}>
        Load More Data
      </Button>
    </div>
  );
}
