// eslint-disable-next-line
import React from "react";
import { IReasonProps } from "../../../interfaces/props";

export function ReasonsItem({ item }: IReasonProps) {
  return (
    <div className="form-check">
      <input className="form-check-input" type="checkbox" value={item.key} />
      <label className="form-check-label">{item.value}</label>
    </div>
  );
}
