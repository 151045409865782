import React, { useState } from "react";
import { CarOrigin } from "../../../_helpers";


export function CarOriginOptions({selectedOrigin, disabled, onChange}:{selectedOrigin: CarOrigin | undefined, disabled:boolean, onChange?:any}) {
  
  function selectOrigin(event:any) {
    const selectedOrigin = event.target.value;
    onChange(selectedOrigin);
  }

  return (
    <select disabled={disabled} defaultValue={selectedOrigin} onChange={selectOrigin} style={{fontSize:"18px"}}>
        <option key="chinese" value={CarOrigin.CHINESE}>
          Chinese
        </option>
        <option key="other" value={CarOrigin.OTHER} >
          Other
        </option>
    </select>
  );
}