import { Dispatch } from "redux";
import { reasonConstants, apiConstants } from "../_constants";
import { authHeader, fetchRequest, handleAxiosDispatch } from "../_helpers";
import { ICommentAction } from "../interfaces/actions";
import { IComment } from "../interfaces/components/comment";

export class RejectReasonsAction {
  private dispatchRequest = () => {
    return {
      type: reasonConstants.FETCH_REJECT_REASONS_REQUEST,
    };
  };

  private dispatchSuccess = (comments: Array<IComment>) => {
    return {
      type: reasonConstants.FETCH_REJECT_REASONS_SUCCESS,
      comments,
    };
  };

  private dispatchFailure = () => {
    return {
      type: reasonConstants.FETCH_REJECT_REASONS_FAILURE,
    };
  };

  get = (type: string) => {
    return async (dispatch: Dispatch<ICommentAction>) => {
      try {
        dispatch(this.dispatchRequest());
        const response = await fetchRequest(
          "GET",
          `${apiConstants.API_GET_COMMENTS}/${type}/rejectReasons`,
          authHeader(),
        );
        if (response.success) {
          dispatch(this.dispatchSuccess(response.data.reasons));
        } else {
          throw new Error();
        }
      } catch (error) {
        handleAxiosDispatch(error, "Failed to available rejection reasons", dispatch, this.get, this.dispatchFailure, [
          type,
        ]);
      }
    };
  };
}
