// eslint-disable-next-line
import React from "react";
import { IErrorBoundaryState } from "../../interfaces/state";
import { IErrorProps } from "../../interfaces/props";

export class ErrorBoundary extends React.Component<IErrorProps, IErrorBoundaryState> {
  constructor(props: IErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    return this.state.hasError ? (
      <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
        Something Went Wrong!!
      </div>
    ) : (
      this.props.children
    );
  }
}
