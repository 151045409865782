// eslint-disable-next-line
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Loading } from "../../Loading";
import { UserPageInfo, UserPageVerification } from "..";
import { NoDataFound } from "../../NoDataFound";
import { UserAction } from "../../../_actions";
import { isNotEmpty, history } from "../../../_helpers";
import { IMatchProps } from "../../../interfaces/props";
import { ILoadingState, IUserItemState, AppState } from "../../../interfaces/state";

export function UserPage({ match }: IMatchProps) {
  const dispatch = useDispatch();
  const userAction = new UserAction();

  const { user, loading }: { user: IUserItemState; loading: ILoadingState } = useSelector((state: AppState) => ({
    user: state.user,
    loading: state.loading,
  }));

  const {
    params: { userId },
  } = match;

  useEffect(() => {
    dispatch(userAction.getByID(userId!, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId]);

  const createTicket = () => {
    history.push(`/users/${userId}/tickets/create`);
  };

  const showVerificationHistory = () => {
    history.push(`/users/${userId}/verifications/history`);
  };

  const renderData = () => {
    if (loading.isLoading) {
      return <Loading />;
    } else {
      if (typeof user.item === "object" && user.item !== null && isNotEmpty(user.item)) {
        return (
          <div className="card d-flex flex-column justify-content-center align-items-center">
            <UserPageInfo />
            <div className="d-flex flex-row justify-content-center align-items-center">
              <Button variant="success" type="button" onClick={() => createTicket()}>
                Create New TicketAction
              </Button>
              &nbsp;
              <Button variant="info" type="button" onClick={() => showVerificationHistory()}>
                Show Verification History
              </Button>
            </div>
            <UserPageVerification user={user.item} userId={userId as string} />
          </div>
        );
      } else {
        return <NoDataFound data={"No User are found"} />;
      }
    }
  };

  return <div className="container-fluid">{renderData()}</div>;
}
