// eslint-disable-next-line
import React from "react";

export function NoMatching() {
  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
      <h3 className="text-black h4">Opps ... This Page not found</h3>
    </div>
  );
}
