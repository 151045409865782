// eslint-disable-next-line
import React from "react";
import { IPaginationProps } from "../../interfaces/props";
import ReactPaginate from "react-paginate";

export function PaginationBar({
  pagesCount,
  pageRangeDisplayed,
  marginPagesDisplayed,
  handlePageClick,
}: IPaginationProps) {
  return (
    <div className="pagination-wrapper d-flex flex-row justify-content-center">
      <ReactPaginate
        previousLabel={"← Previous"}
        nextLabel={"Next →"}
        pageCount={pagesCount}
        pageRangeDisplayed={pageRangeDisplayed}
        marginPagesDisplayed={marginPagesDisplayed}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
    </div>
  );
}
