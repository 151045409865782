// eslint-disable-next-line
import React from "react";
import { Button } from "react-bootstrap";
import { ITicketResponseProps } from "../../../interfaces/props";
import { AttachmentItem } from "../../Attachments";

export function TicketResponse({ flag, response, resendResponse }: ITicketResponseProps) {
  return flag === "state" ? (
    <div className="card">
      <div className="card-header d-flex flex-row justify-content-between">
        {response.author.agent || response.author.user}
        <p className="card-text">{new Date(response.timestamp).toUTCString()}</p>
      </div>
      <div className="card-body">
        <p className="card-text">{response.body}</p>
      </div>
      {response.attachments
        ? response.attachments.map((item: any, index: number) => {
            return <AttachmentItem key={index} item={item} />;
          })
        : null}
    </div>
  ) : (
    <div className="card">
      <div className="card-header d-flex flex-row justify-content-between">
        <p className="card-text">{new Date().toUTCString()}</p>
      </div>
      <div className="card-body">
        <p className="card-text">{response.responseBody}</p>
        {response.flag ? (
          <Button variant="success" type="button" onClick={() => resendResponse!(response.responseBody)}>
            Resend Response
          </Button>
        ) : null}
      </div>
    </div>
  );
}
