// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { Loading } from "../../Loading";
import { TicketResponse } from "..";
import { AttachmentItem } from "../../Attachments";
import { isNotEmpty } from "../../../_helpers";
import { TicketAction, AlertAction } from "../../../_actions";
import { IMatchProps, ITicketPageProps } from "../../../interfaces/props";
import { ITicketItemState, ITicketResponseState, AppState, ITicketListState } from "../../../interfaces/state";
import { ITicket } from "../../../interfaces/components/ticket";

export function TicketPage({ match }: IMatchProps) {
  const dispatch = useDispatch();
  const alertAction = new AlertAction();
  const ticketAction = new TicketAction();

  const {
    ticketItem,
    ticketList,
    responses,
  }: { ticketItem: ITicketItemState; ticketList: ITicketListState; responses: ITicketResponseState } = useSelector(
    (state: AppState) => ({
      ticketItem: state.ticket,
      ticketList: state.tickets,
      responses: state.responses,
    }),
  );

  const {
    params: { ticketId, userId },
  } = match;

  const [ticketPageProps, setTicketPageProps] = useState<ITicketPageProps>({
    selectedFiles: [],
    messages: [],
    spinner: false,
    createdResponseId: null,
    isClosed: false,
  });

  // check for ticket in store first. If not found, fetch ticket by id
  const matchingticket: ITicket = ticketList.items.filter((ticket) => ticket.id === ticketId)[0];
  const ticket = matchingticket || ticketItem.item;
  const responseKeys = responses.items ? Object.keys(responses.items) : [];

  useEffect(() => {
    if (!matchingticket) {
      dispatch(ticketAction.getByID(userId!, ticketId!));
    }
    dispatch(ticketAction.getResponses(userId!, ticketId!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ticketId]);

  const chooseAttachment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.files![0] as File;
    if (ticketPageProps.selectedFiles.filter((file: File) => file.name === input.name).length === 0) {
      setTicketPageProps((ticketPageProps) => ({
        ...ticketPageProps,
        selectedFiles: [...ticketPageProps.selectedFiles, input],
      }));
    }
  };

  const removeAttachment = (name: string) => {
    setTicketPageProps((ticketPageProps) => ({
      ...ticketPageProps,
      selectedFiles: [ticketPageProps.selectedFiles.filter((file: File) => file.name !== name)],
    }));
  };

  const submitResponse = async () => {
    if (ticketPageProps.createdResponseId) {
      return await submitResponseAttachment(ticketPageProps.createdResponseId);
    } else {
      return await submitResponseBodyAndAttachment();
    }
  };

  const closeTicket = async () => {
    try {
      await ticketAction.closeTicket(userId!, ticketId!);
      setTicketPageProps((ticketPageProps) => ({
        ...ticketPageProps,
        isClosed: true,
      }));
      dispatch(alertAction.pass("Ticket is closed"));
    } catch (error) {
      dispatch(alertAction.fail("Failed to close this ticket"));
    }
  };

  const submitResponseAttachment = async (responseId: string, responseBody?: string) => {
    const uploadFlag = await ticketAction.uploadResponse(
      ticket.uid,
      ticket.id,
      responseId,
      ticketPageProps.selectedFiles,
    );
    if (uploadFlag) {
      (document.getElementById("responseBody") as HTMLFormElement).value = "";
      setTicketPageProps((ticketPageProps) => ({
        ...ticketPageProps,
        messages: [...ticketPageProps.messages, { responseBody, flag: false }],
        createdResponseId: null,
        selectedFiles: [],
      }));
    } else {
      dispatch(alertAction.fail("Failed to add attachment"));
    }
  };

  const submitResponseBodyAndAttachment = async () => {
    const responseBody: string = (document.getElementById("responseBody") as HTMLFormElement).value;
    if (responseBody) {
      const formData = {
        body: responseBody,
      };

      setTicketPageProps((ticketPageProps) => ({
        ...ticketPageProps,
        spinner: true,
      }));
      const responseId = await ticketAction.createResponse(ticket.uid, ticket.id, formData);
      if (responseId) {
        setTicketPageProps((ticketPageProps) => ({
          ...ticketPageProps,
          createdResponseId: responseId,
        }));
        await submitResponseAttachment(responseId, responseBody);
        setTicketPageProps((ticketPageProps) => ({
          ...ticketPageProps,
          spinner: false,
        }));
      } else {
        setTicketPageProps((ticketPageProps) => ({
          ...ticketPageProps,
          spinner: false,
          messages: [...ticketPageProps.messages, { responseBody, flag: true }],
        }));
        dispatch(alertAction.fail("Failed to add response. Please try later"));
      }
    } else {
      dispatch(alertAction.fail("Please enter response body"));
    }
  };

  const resendResponse = async (responseBody: string) => {
    const formData = {
      body: responseBody,
    };
    const newResponse = await ticketAction.createResponse(ticket.uid, ticket.id, formData);
    if (newResponse) {
      const result = ticketPageProps.messages.filter((mes) => {
        if (mes.responseBody !== responseBody) {
          return mes;
        }
        return null;
      });
      setTicketPageProps((ticketPageProps) => ({
        ...ticketPageProps,
        spinner: false,
        messages: [...result, { responseBody, flag: false }],
      }));
      dispatch(alertAction.pass("Successfully add response"));
    } else {
      dispatch(alertAction.fail("Failed to add response. Please try later"));
    }
  };

  return (
    <div className="container-fluid">
      {typeof ticket === "object" && ticket !== null && isNotEmpty(ticket) ? (
        <div>
          <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
            <div className="col-md-12">
              <div className="panel panel-info">
                <div className="card panel-heading">
                  <div className="card-header">
                    <div className="d-flex justify-content-end" style={{ marginBottom: "10px" }}>
                      {ticketPageProps.isClosed || !ticket.isOpen ? (
                        <div className="card-header" style={{ backgroundColor: "#d9534f" }}>
                          Closed
                        </div>
                      ) : (
                        <Button variant="danger" type="button" onClick={() => closeTicket()}>
                          Close Ticket
                        </Button>
                      )}
                    </div>
                    <div className="input-group mb-3">
                      <label className="input-group-text bg-info">From:</label>
                      <div className="form-control">
                        {ticket.author?.hasOwnProperty("user")
                          ? ticket.author?.user || ticket.userEmail || ticket.uid
                          : `agent ( ${ticket.author?.agent || "Not Provided"} )`}
                      </div>
                    </div>
                    <div className="input-group mb-3">
                      <label className="input-group-text bg-info">To:</label>
                      <div className="form-control">
                        {ticket.author?.hasOwnProperty("user") ? "bringy-helpdesk" : ticket.userEmail || ticket.uid}
                      </div>
                    </div>
                    <div className="input-group mb-3">
                      <label className="input-group-text bg-info">Subject:</label>
                      <div className="form-control">{ticket.subject}</div>
                    </div>
                  </div>
                  <div className="card-body">
                    <p className="card-text">{ticket.body}</p>
                  </div>
                  {ticket.attachments
                    ? ticket.attachments.map((item, index) => {
                        return <AttachmentItem key={index} item={item} />;
                      })
                    : null}
                </div>

                <div className="panel-body">
                  <ul className="chat">
                    {isNotEmpty(responses.items)
                      ? responseKeys.map((key: any) => {
                          return (
                            <TicketResponse flag="state" key={key} responseId={key} response={responses.items[key]} />
                          );
                        })
                      : null}
                    {isNotEmpty(ticketPageProps.messages)
                      ? ticketPageProps.messages.map((response, key) => {
                          return (
                            <TicketResponse
                              key={key}
                              response={response}
                              resendResponse={resendResponse}
                              spinner={ticketPageProps.spinner}
                            />
                          );
                        })
                      : null}
                    {ticketPageProps.spinner ? (
                      <div>
                        {" "}
                        Sending....... <Loading />
                      </div>
                    ) : null}
                  </ul>
                </div>

                {ticketPageProps.isClosed || !ticket.isOpen ? null : (
                  <div className="panel-footer">
                    <form
                      className="d-flex flex-column justify-content-center align-items-center text-dark text-wrap bg-light"
                      style={{ padding: "10px" }}
                    >
                      <div className="input-group input-group-sm mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="inputGroup-sizing-sm">
                            Add Response
                          </span>
                        </div>
                        {ticketPageProps.spinner ? (
                          <textarea
                            id="responseBody"
                            disabled
                            data-type="textarea"
                            data-rows="10"
                            className="col-md-12"
                            aria-describedby="inputGroup-sizing-sm"
                          />
                        ) : (
                          <textarea
                            id="responseBody"
                            required
                            data-type="textarea"
                            data-rows="10"
                            className="col-md-12"
                            aria-describedby="inputGroup-sizing-sm"
                          />
                        )}
                        <input type="file" multiple name="Attach Files" onChange={(event) => chooseAttachment(event)} />
                      </div>
                      <div className="d-flex flex-column justify-content-center">
                        {ticketPageProps.selectedFiles.map((item, index) => {
                          return <AttachmentItem key={index} item={item} removeAttachment={removeAttachment} />;
                        })}
                      </div>
                      {ticketPageProps.spinner ? (
                        <Loading />
                      ) : (
                        <Button variant="success" type="button" onClick={() => submitResponse()}>
                          Submit Response
                        </Button>
                      )}
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
