// eslint-disable-next-line
import React from "react";
import { Link } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISidebarItemProps } from "../../../interfaces/props";

export function SidebarItem({ item }: ISidebarItemProps) {
  return (
    <div className="nav-item side-menu-item">
      <NavItem>
        <NavLink
          tag={Link}
          to={{ pathname: item.pathname, search: item.search }}
          style={{ color: "#fff" }}
          onClick={item.action}
        >
          <FontAwesomeIcon icon={item.icon} className="mr-2" />
          {item.title}
        </NavLink>
      </NavItem>
    </div>
  );
}
