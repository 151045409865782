// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { Dropdown, Button, ButtonGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { OrderItem } from "..";
import { Loading } from "../../Loading";
import { NoDataFound } from "../../NoDataFound";
import { SearchBar } from "../../SearchBar";
import { OrderAction } from "../../../_actions";
import { isNotEmpty, OrderType, OrderStatus, Sort, getCurrentDate } from "../../../_helpers";
import { IOrderListState, ILoadingState, AppState } from "../../../interfaces/state";
import { IOrderListProps } from "../../../interfaces/props";
import { IOrderData } from "../../../interfaces/components/order";
import { DateFilter } from "../../DateFilter";
import CreateManualOrder from "../CreateManualOrder/CreateManualOrder";

export function OrderList() {
  const dispatch = useDispatch();
  const orderAction = new OrderAction();
  const policyTypes = Object.keys(OrderType).map((key) => OrderType[key as keyof typeof OrderType]);
  const policyStatus = Object.keys(OrderStatus).map((key) => OrderStatus[key as keyof typeof OrderStatus]);
  const sortingBy = Object.keys(Sort).map((key) => Sort[key as keyof typeof Sort]);

  const {
    orders,
    loading,
  }: {
    orders: IOrderListState;
    loading: ILoadingState;
  } = useSelector((state: AppState) => ({
    orders: state.orders,
    loading: state.loading,
  }));

  const [isModalOpened, setIsModalOpened] = useState(false);


  const [orderListProps, setOrderListProps] = useState<IOrderListProps>({
    flags: {
      type:
        isNotEmpty(orders.flags) && orders.flags !== null && typeof orders.flags === "object"
          ? orders.flags.type
          : OrderType.INSURANCE,
      status:
        isNotEmpty(orders.flags) && orders.flags !== null && typeof orders.flags === "object"
          ? orders.flags.status
          : OrderStatus.FULFILLED,
      orderBy:
        isNotEmpty(orders.flags) && orders.flags !== null && typeof orders.flags === "object"
          ? orders.flags.sortBy
          : "asc",
    },
    startDate: orders.startDate || getCurrentDate().start,
    endDate: orders.endDate || getCurrentDate().end,
    searchTerm: "",
    searchResults: [],
  });

  useEffect(() => {
    dispatch(
      orderAction.getAll(
        orderListProps.flags.type,
        orderListProps.flags.status,
        orderListProps.flags.orderBy,
        orderListProps.startDate,
        orderListProps.endDate,
      ),
    );
    setOrderListProps((orderListProps) => ({
      ...orderListProps,
      newData: true,
      searchTerm: "",
      searchResults: [],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, orderListProps.flags, orderListProps.startDate, orderListProps.endDate]);

  const handleSearchByUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (isNotEmpty(orders.items)) {
      const results = orders.items.filter((order: IOrderData) => order.userId?.includes(event.target.value));
      setOrderListProps((orderListProps) => ({
        ...orderListProps,
        searchTerm: event.target.value,
        searchResults: [...results],
      }));
    }
  };

  const showOrders = (flag: string, value: string) => {
    switch (flag) {
      case "type":
        setOrderListProps((orderListProps) => ({
          ...orderListProps,
          flags: { ...orderListProps.flags, type: value },
        }));
        break;
      case "status":
        setOrderListProps((orderListProps) => ({
          ...orderListProps,
          flags: { ...orderListProps.flags, status: value },
        }));
        break;
      case "sort":
        setOrderListProps((orderListProps) => ({
          ...orderListProps,
          flags: { ...orderListProps.flags, orderBy: value },
        }));
        break;
      default:
        break;
    }
  };

  const renderData = () => {
    if (loading.isLoading) {
      return (
        <tr>
          <td colSpan={6}>
            <Loading />
          </td>
        </tr>
      );
    } else {
      if (orderListProps.searchResults.length !== 0) {
        return orderListProps.searchResults.map((order: IOrderData, index: number) => (
          <OrderItem key={index} order={order} index={index}></OrderItem>
        ));
      }
      if (
        isNotEmpty(orders.items) &&
        orders.items !== null &&
        typeof orders.items === "object" &&
        orders.items.length !== 0 &&
        !orderListProps.searchTerm
      ) {
        return orders.items.map((order: IOrderData, index: number) => (
          <OrderItem key={index} order={order} index={index}></OrderItem>
        ));
      } else {
        return (
          <tr>
            <td colSpan={6}>
              <NoDataFound data={"No Orders are found"} />
            </td>
          </tr>
        );
      }
    }
  };

  const handleFormData = (event: any) => {
    event.preventDefault();
    const startDate: string = (document.getElementById("startDate") as HTMLFormElement).value;
    const endDate: string = (document.getElementById("endDate") as HTMLFormElement).value;
    dispatch(orderAction.addDateRange(startDate, endDate));
    setOrderListProps({ ...orderListProps, startDate, endDate });
  };

  function closeModal() {
    setIsModalOpened(false);
    showOrders("status", OrderStatus.All);
  }

  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center">

      {isModalOpened && <CreateManualOrder closeModalDelegate={closeModal} />}

      <DateFilter
        startDate={orderListProps.startDate}
        endDate={orderListProps.endDate}
        handleFormData={handleFormData}
      />
      <nav className="navbar navbar-light bg-light">
        <SearchBar title="Search by userId" value={orderListProps.searchTerm} action={handleSearchByUser} />

        <Dropdown as={ButtonGroup}>
          <Button variant="light">Order Type : </Button>
          <Dropdown.Toggle variant="info" id="dropdown-split-basic">
            {orderListProps.flags.type}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {policyTypes.map((type: any, index: number) => {
              return (
                <Dropdown.Item key={index} onClick={() => showOrders("type", type)}>
                  {type}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as={ButtonGroup}>
          <Button variant="light">Order Status : </Button>
          <Dropdown.Toggle variant="info" id="dropdown-filter">
            {orderListProps.flags.status}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {policyStatus.map((status: any, index: number) => {
              return (
                <Dropdown.Item key={index} onClick={() => showOrders("status", status)}>
                  {status}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as={ButtonGroup}>
          <Button variant="light">Sort : </Button>
          <Dropdown.Toggle variant="info" id="dropdown-filter">
            {orderListProps.flags.orderBy}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {sortingBy.map((sort: any, index: number) => {
              return (
                <Dropdown.Item key={index} onClick={() => showOrders("sort", sort)}>
                  {sort}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        {<button className="btn btn-success" style={{marginLeft:"8px"}} onClick={()=>setIsModalOpened(true)}>Create Manual Order</button>}
      </nav>

      <table className="table table-striped table-bordered text-center">
        <thead>
          <tr>
            <th>Order Id</th>
            <th>User Id</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Type</th>
            <th>TimeStamp</th>
          </tr>
        </thead>
        <tbody>{renderData()}</tbody>
      </table>
    </div>
  );
}
