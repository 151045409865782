import { loadingConstants } from "../_constants";
import { ILoadingAction } from "../interfaces/actions";
import { ILoadingState } from "../interfaces/state";

const initialLoadingState = {
  isLoading: false,
};

export function loading(state: ILoadingState = initialLoadingState, action: ILoadingAction) {
  switch (action.type) {
    case loadingConstants.IS_LOADING:
      return {
        isLoading: action.flag,
      };
    default:
      return state;
  }
}
