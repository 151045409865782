import { loadingConstants } from "../_constants";

export class LoadingAction {
  isLoading = (flag: boolean) => {
    return {
      type: loadingConstants.IS_LOADING,
      flag,
    };
  };
}
