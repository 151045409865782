// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { AttachmentItem } from "../../Attachments";
import { Loading } from "../../Loading";
import { AlertAction, TicketAction, UserAction } from "../../../_actions";
import { IMatchProps, ITicketCreationProps } from "../../../interfaces/props";
import { IUserListState, AppState, ILoadingState, IUserItemState } from "../../../interfaces/state";
import { IUserData } from "../../../interfaces/components/user";

export function TicketCreation({ match }: IMatchProps) {
  const dispatch = useDispatch();
  const alertAction = new AlertAction();
  const ticketAction = new TicketAction();
  const userAction = new UserAction();

  const [ticketCreationProps, setTicketCreationProps] = useState<ITicketCreationProps>({
    spinner: false,
    createdTicketId: null,
    selectedFiles: [],
  });

  const {
    userItem,
    userList,
    loading,
  }: { userItem: IUserItemState; userList: IUserListState; loading: ILoadingState } = useSelector(
    (state: AppState) => ({
      userItem: state.user,
      userList: state.users,
      loading: state.loading,
    }),
  );

  const {
    params: { userId },
  } = match;

  // check for matching user in store before dispatch user by id
  const matchingUser: IUserData = userList.items.filter((user: any) => user.uid === userId)[0];
  const user: IUserData = matchingUser || userItem.item.user;

  useEffect(() => {
    if (!matchingUser) {
      dispatch(userAction.getByID(userId!, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const chooseAttachment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.files![0] as File;
    if (ticketCreationProps.selectedFiles.filter((file: File) => file.name === input.name).length === 0) {
      setTicketCreationProps((ticketCreationProps) => ({
        ...ticketCreationProps,
        selectedFiles: [...ticketCreationProps.selectedFiles, input],
      }));
    }
  };

  const removeAttachment = (name: string) => {
    setTicketCreationProps((ticketCreationProps) => ({
      ...ticketCreationProps,
      selectedFiles: [ticketCreationProps.selectedFiles.filter((file: File) => file.name !== name)],
    }));
  };

  const submitTicket = async () => {
    if (ticketCreationProps.createdTicketId) {
      submitTicketAttachment(ticketCreationProps.createdTicketId);
    } else {
      await submitTicketBodyAndAttachment();
    }
  };

  const submitTicketAttachment = async (ticketId: string) => {
    dispatch(ticketAction.uploadTicket(userId!, ticketId, ticketCreationProps.selectedFiles!));
  };

  const submitTicketBodyAndAttachment = async () => {
    const ticketSubject: string = (document.getElementById("ticketSubject") as HTMLFormElement).value;
    const ticketBody: string = (document.getElementById("ticketBody") as HTMLFormElement).value;
    if (ticketBody) {
      const ticketData = {
        subject: ticketSubject,
        body: ticketBody,
        uid: userId,
        userEmail: user.email || user.phoneNumber,
      };
      setTicketCreationProps((ticketCreationProps) => ({
        ...ticketCreationProps,
        spinner: true,
      }));
      const ticketId = await ticketAction.createTicket(userId!, ticketData);
      await submitTicketAttachment(ticketId);
      setTicketCreationProps((ticketCreationProps) => ({
        ...ticketCreationProps,
        createdTicketId: ticketId,
      }));
    } else {
      dispatch(alertAction.fail("Please enter ticket body"));
    }
  };

  return (
    <form
      className="d-flex flex-column justify-content-center text-dark text-wrap bg-light"
      style={{ padding: "10px" }}
    >
      <div className="card" style={{ margin: "10px" }}>
        <div className="card-header d-flex flex-row justify-content-between">
          {user ? user.email || user.phoneNumber : ""}
          <p className="card-text">{user ? user.displayName : ""}</p>
        </div>
      </div>

      <div className="input-group input-group-sm mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            Subject:
          </span>
        </div>
        {ticketCreationProps.spinner ? (
          <textarea
            id="ticketSubject"
            disabled
            data-type="textarea"
            data-rows="10"
            className="col-md-12"
            aria-describedby="inputGroup-sizing-sm"
          />
        ) : (
          <textarea
            id="ticketSubject"
            required
            data-type="textarea"
            data-rows="10"
            className="col-md-12"
            aria-describedby="inputGroup-sizing-sm"
          />
        )}
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            Body:
          </span>
        </div>
        {ticketCreationProps.spinner ? (
          <textarea
            id="ticketBody"
            disabled
            data-type="textarea"
            data-rows="10"
            className="col-md-12"
            aria-describedby="inputGroup-sizing-sm"
          />
        ) : (
          <textarea
            id="ticketBody"
            required
            data-type="textarea"
            data-rows="10"
            className="col-md-12"
            aria-describedby="inputGroup-sizing-sm"
          />
        )}
        <input type="file" multiple name="Attach Files" onChange={(event) => chooseAttachment(event)} />
      </div>
      <div className="d-flex flex-column justify-content-center">
        {ticketCreationProps.selectedFiles.map((item, index) => {
          return <AttachmentItem key={index} item={item} removeAttachment={removeAttachment} />;
        })}
      </div>
      {loading.isLoading ? (
        <Loading />
      ) : (
        <Button variant="success" type="button" onClick={() => submitTicket()}>
          Submit TicketAction
        </Button>
      )}
    </form>
  );
}
