import { orderConstants, apiConstants } from "../_constants";
import { authHeader, handleAxiosDispatch, fetchRequest } from "../_helpers";
import { LoadingAction } from ".";
import { IOrderData, IOrderItem } from "../interfaces/components/order";
import { Dispatch } from "redux";
import { IOrderAction, IOrderListAction, ILoadingAction } from "../interfaces/actions";

export class OrderAction {
  private dispatchOrderItemRequest = () => {
    return {
      type: orderConstants.FETCH_ORDER_ITEM_REQUEST,
    };
  };

  private dispatchOrderItemSuccess = (order: IOrderItem) => {
    return {
      type: orderConstants.FETCH_ORDER_ITEM_SUCCESS,
      order,
    };
  };

  private dispatchOrderItemFailure = () => {
    return {
      type: orderConstants.FETCH_ORDER_ITEM_FAILURE,
    };
  };

  private dispatchOrderListRequest = (
    type: string,
    status: string,
    sortBy: string,
    startDate: string,
    endDate: string,
  ) => {
    return {
      type: orderConstants.FETCH_ORDER_LIST_REQUEST,
      flags: {
        type,
        status,
        sortBy,
      },
      startDate,
      endDate,
    };
  };

  private dispatchOrderListSuccess = (orders: Array<IOrderData>) => {
    return {
      type: orderConstants.FETCH_ORDER_LIST_SUCCESS,
      orders,
    };
  };

  private dispatchOrderListFailure = () => {
    return {
      type: orderConstants.FETCH_ORDER_LIST_FAILURE,
      orders: [],
    };
  };

  addDateRange = (startDate: string, endDate: string) => {
    return {
      type: orderConstants.ADD_ORDER_DATE_RANGE,
      startDate,
      endDate,
    };
  };

  getAll = (type: string, status: string, sortBy: string, startDate: string, endDate: string) => {
    const loading = new LoadingAction();
    return async (dispatch: Dispatch<IOrderListAction>) => {
      try {
        dispatch(this.dispatchOrderListRequest(type, status, sortBy, startDate, endDate));
        dispatch(loading.isLoading(true));

        const response = await fetchRequest(
          "GET",
          apiConstants.API_GET_ORDERS,
          authHeader(),
          {},
          {
            sort: sortBy,
            filterStatus: status,
            filterType: type,
            startDate,
            endDate,
          },
        );

        if (response.success) {
          dispatch(this.dispatchOrderListSuccess(response.data));
          dispatch(loading.isLoading(false));
        } else {
          throw new Error();
        }
      } catch (error) {
        handleAxiosDispatch(error, "Failed to get orders", dispatch, this.getAll, this.dispatchOrderListFailure, [
          type,
          status,
          sortBy,
          startDate,
          endDate,
        ]);
        dispatch(loading.isLoading(false));
      }
    };
  };

  getByID = (orderId: string, userId: string) => {
    const loading = new LoadingAction();
    return async (dispatch: Dispatch<ILoadingAction | IOrderAction>) => {
      try {
        dispatch(this.dispatchOrderItemRequest());
        dispatch(loading.isLoading(true));
        const response = await fetchRequest(
          "GET",
          `${apiConstants.API_GET_ORDERS}/${orderId}`,
          authHeader(),
          {},
          {
            userId,
          },
        );
        if (response.success) {
          dispatch(this.dispatchOrderItemSuccess(response.data));
          dispatch(loading.isLoading(false));
        } else {
          throw new Error();
        }
      } catch (error) {
        handleAxiosDispatch(
          error,
          `Failed to get order data for id: ${orderId} and userId: ${userId}`,
          dispatch,
          this.getByID,
          this.dispatchOrderItemFailure,
          [orderId, userId],
        );
        dispatch(loading.isLoading(false));
      }
    };
  };
}
