import { Dispatch } from "redux";
import { apiConstants, authenticationConstants } from "../_constants";
import { AlertAction } from ".";
import { history, authHeader, fetchRequest, getProfileInfo } from "../_helpers";
import { IAuthenticationAction, IAlertAction } from "../interfaces/actions";

export class AuthenticationAction {
  private dispatchLoginSuccess = (token: string) => {
    return {
      type: authenticationConstants.LOGIN_SUCCESS,
      token,
    };
  };

  private dispatchLoginFailure = () => {
    return {
      type: authenticationConstants.LOGIN_FAILURE,
    };
  };

  private dispatchLogoutSuccess = () => {
    return {
      type: authenticationConstants.LOGOUT_SUCCESS,
    };
  };

  private dispatchLogoutFailure = () => {
    return {
      type: authenticationConstants.LOGOUT_FAILURE,
    };
  };

  private dispatchAgentProfile = (profile: any) => {
    return {
      type: authenticationConstants.AGENT_PROFILE,
      profile,
    };
  };

  private acceptLogin = (dispatch: Dispatch<IAuthenticationAction | IAlertAction>, token: string, location: any) => {
    dispatch(this.dispatchLoginSuccess(token));
    localStorage.setItem("tokenId", token);
    if (location.state) {
      window.location.assign(location.state.from.pathname);
    } else {
      history.push("/");
    }
  };

  private preventLogin = (dispatch: Dispatch<IAuthenticationAction | IAlertAction>) => {
    const alert = new AlertAction();
    dispatch(this.dispatchLoginFailure());
    dispatch(alert.fail("Login Failure. Please Login with bringy account"));
  };

  private acceptLogout = (dispatch: Dispatch<IAuthenticationAction | IAlertAction>) => {
    dispatch(this.dispatchLogoutSuccess());
    localStorage.clear();
    history.push("/");
  };

  private preventLogout = (dispatch: Dispatch<IAuthenticationAction | IAlertAction>) => {
    const alert = new AlertAction();
    dispatch(this.dispatchLogoutFailure());
    dispatch(alert.fail("Failed to logout. Please try again"));
  };

  toLogin = (tokenId: string, location: any) => {
    return async (dispatch: Dispatch<IAuthenticationAction | IAlertAction>) => {
      try {
        const response = await fetchRequest("POST", `${apiConstants.API_LOGIN}`, { authorization: tokenId });
        if (response.success) {
          console.log("inside success login");
          this.acceptLogin(dispatch, response.data.token, location);
        } else {
          throw new Error();
        }
      } catch (fail) {
        this.preventLogin(dispatch);
      }
    };
  };

  reLogin = async (tokenId: string) => {
    try {
      const response = await fetchRequest("POST", `${apiConstants.API_LOGIN}`, { authorization: tokenId });
      localStorage.setItem("tokenId", response.data.token);
      return true;
    } catch (fail) {
      return false;
    }
  };

  toLogout = () => {
    return async (dispatch: Dispatch<IAuthenticationAction | IAlertAction>) => {
      try {
        const response = await fetchRequest("GET", `${apiConstants.API_LOGOUT}`, authHeader());
        if (response.success) {
          this.acceptLogout(dispatch);
        } else {
          throw new Error();
        }
      } catch (fail) {
        this.preventLogout(dispatch);
      }
    };
  };

  getAgentProfile = () => {
    return async (dispatch: Dispatch<IAuthenticationAction>) => {
      try {
        const profile = await getProfileInfo();
        localStorage.setItem("image", profile.imageUrl);
        localStorage.setItem("email", profile.email);
        localStorage.setItem("name", profile.name);
        dispatch(this.dispatchAgentProfile(profile));
      } catch (error) {
        return;
      }
    };
  };
}
