export const userConstants = {
  FETCH_USER_LIST_REQUST: "FETCH_USER_LIST_REQUST",
  FETCH_USER_LIST_SUCCESS: "FETCH_USER_LIST_SUCCESS",
  FETCH_USER_LIST_FAILURE: "FETCH_USER_LIST_FAILURE",

  FETCH_USER_ITEM_REQUST: "FETCH_USER_ITEM_REQUST",
  FETCH_USER_ITEM_SUCCESS: "FETCH_USER_ITEM_SUCCESS",
  FETCH_USER_ITEM_FAILURE: "FETCH_USER_ITEM_FAILURE",

  UPDATE_USER_DATA: "UPDATE_USER_DATA",
  UPDATE_VERIFICATION_STATE: "UPDATE_VERIFICATION_STATE",

  ACTION_FAILURE: "ACTION_FAILURE",
};
