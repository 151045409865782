export const VerificationStatus = {
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  PENDING: "pending",
  AGENT: "agent",
  All: "All Status",
};

export const VerificationType = {
  NATIONALID: "nationalId",
  VIDEO: "video",
  All: "All Types",
};

export const UserStatus = {
  VERIFIED: "verified",
  UNVERIFIED: "unverified",
  PENDING: "pending",
};

export const ActionType = {
  ACCEPT: "accept",
  REJECT: "reject",
};

export enum OrderStatus {
  FULFILLED = "fulfilled",
  PAID = "paid",
  REFUNDED = "refunded",
  REJECTED = "rejected",
  EXPIRED = "expired",
  All = "All Status",
}

export enum OrderType {
  INSURANCE = "insurance",
  UPLOAD = "upload",
  All = "All Types",
}

export enum PolicyStatus {
  CANCELED = "canceled",
  All = "All Status",
}

export enum PolicyType {
  TRAVEL = "travel",
  ROADSIDEASSIST = "roadsideassist",
  PERSONAL_ACCIDENT = "personalAccident",
  CANCER = "cancer",
  FUNERAL = "funeral",
  HOME = "home",
  BUSINESS = "business",
  MOTOR = "motor",
  All = "All Types",
}

export enum Vendor {
  GIG = "GIG",
  AXA = "AXA",
  ALLIANZ = "ALLIANZ",
  SARWA = "SARWA",
  MISR_TKAFUL = "MISR_TAKAFUL",
  MOHANDES = "MOHANDES",
  WETHAQ = "WETHAQ",
  All = "All Vendors",
}

export enum ProductId {
  MOTOR = "motor",
}

export enum Sort {
  DESC = "desc",
  ASC = "asc",
}

export enum TicketStatus {
  OPENED = "opened",
  CLOSED = "closed",
  All = "All Status",
}

export enum CarOrigin {
  CHINESE = "chinese",
  OTHER = "other"
}

//All values in CarVendorsEnumForMapping should be lower-case
export enum CarVendorsEnumForMapping {
  AXA = "axa",
  ALLIANZ = "allianz",
  SARWA = "sarwa",
  WETHAQ = "wethaq"
}

