// eslint-disable-next-line
import React from "react";
import { IOrderItemProps } from "../../../interfaces/props";
import { getLocalDate } from "../../../_helpers";

export function OrderItem({ order }: IOrderItemProps) {
  return (
    <React.Fragment>
      <tr>
        <td style={{ cursor: "pointer" }}>
          <a href={`/orders/${order.orderId}/user/${order.userId}`}>{order.orderId}</a>
        </td>
        <td style={{ cursor: "pointer" }}>
          <a href={`/users/${order.userId}`}>{order.userId}</a>
        </td>
        <td>
          {order.amount} {order.currency}
        </td>
        <td>{order.status}</td>
        <td>{order.type}</td>
        <td>{getLocalDate(order.timestamp)}</td>
      </tr>
    </React.Fragment>
  );
}
