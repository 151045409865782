// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserItem } from "..";
import { Loading } from "../../Loading";
import { SearchBar } from "../../SearchBar";
import { NoDataFound } from "../../NoDataFound";
import { UserAction } from "../../../_actions";
import { isNotEmpty } from "../../../_helpers";
import { IUserListState, ILoadingState, AppState } from "../../../interfaces/state";
import { IUserData } from "../../../interfaces/components/user";
import { IUserListProps } from "../../../interfaces/props";
import { PaginationBar } from "../../PaginationBar";

export function UserList() {
  const dispatch = useDispatch();
  const userAction = new UserAction();
  const pageSize = 10;

  const { users, loading }: { users: IUserListState; loading: ILoadingState } = useSelector((state: AppState) => ({
    users: state.users,
    loading: state.loading,
  }));

  const [currentPage, setCurrentPage] = useState(0);
  const [userProps, setUserProps] = useState<IUserListProps>({
    searchTerm: "",
    searchResults: [],
    pagesCount: users.items.length ? Math.ceil(users.items.length / pageSize) : 10,
  });

  const handlePageClick = ({ selected: selectedPage }: { selected: number }) => {
    setCurrentPage(selectedPage);
  };

  const handleSearchByEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (isNotEmpty(users.items)) {
      const results = users.items.filter((user: IUserData) => {
        if (!user.email)
          return "not provided".toLowerCase().includes(event.target.value) || user.uid.includes(event.target.value);
        else return user.email.toLowerCase().includes(event.target.value) || user.uid.includes(event.target.value);
      });
      setUserProps((userProps) => ({
        ...userProps,
        searchTerm: event.target.value,
        searchResults: [...results],
        pagesCount: Math.ceil(results.length / pageSize),
      }));
      setCurrentPage(0);
    }
  };

  useEffect(() => {
    dispatch(userAction.getAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const renderData = () => {
    if (loading.isLoading) {
      return <Loading />;
    } else {
      if (userProps.searchResults.length !== 0) {
        return (
          <div>
            <table className="table table-striped table-bordered text-center">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Email Verification</th>
                  <th>Creation Time</th>
                </tr>
              </thead>
              <tbody>
                {userProps.searchResults
                  .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                  .map((user: IUserData, index: number) => (
                    <UserItem key={index} user={user} index={index + currentPage * pageSize + 1}></UserItem>
                  ))}
              </tbody>
            </table>
            <PaginationBar
              pagesCount={Math.ceil(userProps.searchResults.length / pageSize)}
              handlePageClick={handlePageClick}
              pageRangeDisplayed={5}
              marginPagesDisplayed={5}
            />
          </div>
        );
      } else if (
        isNotEmpty(users.items) &&
        users.items !== null &&
        typeof users.items === "object" &&
        users.items.length !== 0 &&
        !userProps.searchTerm
      ) {
        return (
          <div>
            <table className="table table-striped table-bordered text-center">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Email Verification</th>
                  <th>Creation Time</th>
                </tr>
              </thead>
              <tbody>
                {users.items
                  .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                  .map((user: IUserData, index: number) => (
                    <UserItem key={index} user={user} index={index + currentPage * pageSize + 1}></UserItem>
                  ))}
              </tbody>
            </table>
            <PaginationBar
              pagesCount={Math.ceil(users.items.length / pageSize)}
              handlePageClick={handlePageClick}
              pageRangeDisplayed={5}
              marginPagesDisplayed={5}
            />
          </div>
        );
      } else {
        return <NoDataFound data={"No Users are Found"} />;
      }
    }
  };

  return (
    <div className="container-fluid">
      <nav className="navbar navbar-light bg-light">
        <SearchBar title="Search by user email/id" value={userProps.searchTerm} action={handleSearchByEmail} />
      </nav>
      {renderData()}
    </div>
  );
}
