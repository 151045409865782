// eslint-disable-next-line
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { IPartnerItemProps } from "../../../interfaces/props";
import { PartnerAction, AlertAction } from "../../../_actions";
import { IPartnerListState, AppState } from "../../../interfaces/state";
import { IPartnerData } from "../../../interfaces/components/partner";
import { getLocalDate } from "../../../_helpers";
import { Input } from "reactstrap";
import { Loading } from "../../Loading";

export function PartnerItem({ index, user, showPartners }: IPartnerItemProps) {
  const dispatch = useDispatch();
  const { partners }: { partners: IPartnerListState } = useSelector((state: AppState) => ({
    partners: state.partners,
    loading: state.loading,
  }));

  const [partnerId, setVendorId] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState("");
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const partnerAction = new PartnerAction();
  const alertAction = new AlertAction();

  const handleVendorSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setVendorId(event.target.value);
  };

  const handleAddingCustomClaim = async () => {
    const addingClaim = await partnerAction.addCustomClaim(user.email, partnerId!);
    if (addingClaim) {
      dispatch(alertAction.pass("Sucessfuly adding claim"));
      showPartners(partnerId!);
    } else {
      dispatch(alertAction.fail("Failed to add claim"));
    }
  };

  const changePassword =async () => {
    if (newPassword && newPassword.length >= 8) {
      try {
        setResetPasswordLoading(true);
        await partnerAction.resetUserPassword(user.email, newPassword);
        alertAction.pass("Password changed sucessfuly");
      } catch (error) {
        alertAction.fail("Failed to reset password");
      }
    } else {
      alert("Password should be at least 8 chars");
    }

    setResetPasswordLoading(false);
  };

  const handleRemovingCustomClaim = async () => {
    const removingClaim = await partnerAction.removeCustomClaim(user.email, user.claims.vendor);
    if (removingClaim) {
      dispatch(alertAction.pass("Sucessfuly removing claim"));
      showPartners(user.claims.vendor);
    } else {
      dispatch(alertAction.fail("Failed to remove claim"));
    }
  };

  return (
    <React.Fragment>
      <tr>
        <td>{index}</td>
        <td>{user.email}</td>
        <td>{user.claims ? user.claims.vendor : "---"}</td>
        <td>{getLocalDate(user.timestamp)}</td>
        <td>{user.lastSignInTime ? getLocalDate(user.lastSignInTime) : "---"}</td>
        <td width={210}>
          {resetPasswordLoading ? <Loading /> :
            <div className="row">
              <div className="col-sm-7 ml-0 p-0">
                <Input type="text" onChange={(event)=>{setNewPassword(event.target.value);}}/>
              </div>
              <div className="col-sm-4 p-0">
                <button className="btn btn-warning" type="button" onClick={() => changePassword()}>Reset</button>
              </div>
            </div>
          }
        </td>
        {user.claims ? (
          <td>
            <button className="btn btn-danger" type="button" onClick={() => handleRemovingCustomClaim()}>
              <FontAwesomeIcon icon={faMinus} /> Remove
            </button>
          </td>
        ) : (
          <td>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="form-inline">
                <select
                  className="form-control"
                  defaultValue="Select Partner"
                  onChange={(event) => handleVendorSelection(event)}
                >
                  <option disabled value="Select Partner">
                    Select Partner
                  </option>
                  {partners.partnerList.map((partner: IPartnerData, index: number) => {
                    return (
                      <option key={index} value={partner.id}>
                        {partner.id}
                      </option>
                    );
                  })}
                </select>
              </div>
              {partnerId ? (
                <button className="btn btn-success" type="button" onClick={() => handleAddingCustomClaim()}>
                  <FontAwesomeIcon icon={faPlus} /> Add
                </button>
              ) : (
                <button className="btn btn-success" disabled type="button">
                  <FontAwesomeIcon icon={faPlus} /> Add
                </button>
              )}
            </div>
          </td>
        )}
      </tr>
    </React.Fragment>
  );
}
