import React, { useState } from "react";
import { CarModel } from "../../../Models/CarModel";
import { CarModelService } from "../../../Services/CarListServices/carModelService";
import { ConfirmationDialog } from "../../ConfirmationDialog/ConfirmationDialog";
import { Loading } from "../../Loading";
import { CarModelDetails } from "../CarModelDetailsPopup/CarModelDetails";

export function CarModelRow({carModel, carBrandKey, carModelModifiedDelegate}:{carModel:CarModel, carBrandKey:string | undefined, carModelModifiedDelegate:any}) {
  const [isEditModelOpened, setIsEditModelOpened] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  function deleteDialogCancelAction(){
    setShowDeleteConfirmation(false);
  }

  async function deleteDialogConfirmAction(){
    setShowDeleteConfirmation(false);
    try {
      setLoading(true);
      const carModelService = new CarModelService();
      await carModelService.deleteModel(carModel, carBrandKey);
      carModelModifiedDelegate();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  function closeCarModelDetailsDelegate() {
    carModelModifiedDelegate();
    setIsEditModelOpened(false);
  }

  return(
    <div className="container-fluid model-row">
      {isEditModelOpened && <CarModelDetails carModel={carModel} carBrandKey={carBrandKey} closeCarModelDetailsDelegate={closeCarModelDetailsDelegate}></CarModelDetails>}
      {showDeleteConfirmation && <ConfirmationDialog message="Are you sure ?" onCancelButtonAction={deleteDialogCancelAction} onConfirmButtonAction={deleteDialogConfirmAction}></ConfirmationDialog>}

      <div className="row">
        <span className="col my-auto"><b>{carModel.name}</b></span>
        <span style={(!carModel.vendorMapper.length ? {color:"red"} : {})}>{(carModel.vendorMapper.length) ? carModel.vendorMapperToString() : "not mapped"}</span>
        
        { loading ? 
        <Loading></Loading>
        :
        <div className="col my-auto" >
          <button className="btn btn-warning my-auto" onClick={()=>setIsEditModelOpened(true)}>Edit</button>
          <button className="btn btn-danger my-auto" onClick={()=>setShowDeleteConfirmation(true)}>Delete</button>
        </div>
        }
      </div>
    </div>
  );

}