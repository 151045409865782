import { CarBrand } from "../../Models/CarBrand";
import { apiConstants } from "../../_constants";
import { fetchRequest, authHeader } from "../../_helpers";
import { CarBrandAdapter } from "../ModelsAdapter/CarBrandAdapter";

export class CarBrandService {
  async addBrand(carBrand: CarBrand) : Promise<CarBrand> {
    const body = new CarBrandAdapter().toAPI(carBrand);
    const carListResponse = await fetchRequest("POST", apiConstants.API_ADD_BRAND_CAR_LIST, authHeader(), body, {});
    if (carListResponse.success) {
      return new CarBrandAdapter().fromAPI(carListResponse.data);
    } else {
      throw new Error("Something went wrong");
    }
  }
  
  async editBrand(carBrand: CarBrand) : Promise<CarBrand> {
    const body = new CarBrandAdapter().toAPI(carBrand);
    const carListResponse = await fetchRequest("PUT", apiConstants.API_EDIT_BRAND_LIST, authHeader(), body, {});
    
    if (carListResponse.success) {
      return new CarBrandAdapter().fromAPI(carListResponse.data);
    } else {
      throw new Error("Something went wrong");
    }
  }
  
  async deleteBrand(carBrand:CarBrand) : Promise<boolean> {
    const body = new CarBrandAdapter().toAPI(carBrand);
    const carListResponse = await fetchRequest("DELETE", apiConstants.API_DELETE_BRAND_LIST, authHeader(), body, {});
    
    if (carListResponse.success) {
      return true;
    } else {
      throw new Error("Something went wrong");
    }
  }
}