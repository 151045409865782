const initializeGapi = async () => {
  try {
    const apiKey = process.env.REACT_APP_API_KEY;
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    await window.gapi.auth2.init({
      apiKey: `${apiKey}`,
      clientId: `${clientId}`,
      scope: "https://www.googleapis.com/auth/devstorage.read_write",
    });
  } catch (error) {
    console.log("Failed to intialize gapi");
  }
};

export const createAccessToken = async () => {
  try {
    const gapi = await window.gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse();
    return gapi.access_token;
  } catch (error) {
    return null;
  }
};

export const getProfileInfo = async () => {
  try {
    const userProfile = await window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
    return {
      name: userProfile.getName(),
      email: userProfile.getEmail(),
      imageUrl: userProfile.getImageUrl(),
    };
  } catch (error) {
    return {
      name: "",
      email: "",
      imageUrl: "",
    };
  }
};

export const refreshAuth = async () => {
  try {
    const gapi = await window.gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse();
    return gapi.id_token;
  } catch (error) {
    return null;
  }
};

window.gapi.load("client", initializeGapi);
