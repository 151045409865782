// eslint-disable-next-line
import React from "react";
import { IVerificationItemProps } from "../../../interfaces/props";
import { getLocalDate, VerificationStatus } from "../../../_helpers";

export function VerificationItem({ verification }: IVerificationItemProps) {
  return (
    <React.Fragment>
      <tr>
        <td>{verification.userEmail}</td>
        <td>{verification.userName ? verification.userName : "No Available Name"}</td>
        {verification.status === VerificationStatus.PENDING || verification.status === VerificationStatus.AGENT ? (
          <td style={{ cursor: "pointer" }}>
            <a href={`/users/${verification.userId}`}>{verification.status}</a>
          </td>
        ) : (
          <td>{verification.status}</td>
        )}
        <td>{verification.type}</td>
        <td style={{ cursor: "pointer" }}>
          <a href={`/users/${verification.userId}/verifications/${verification.verificationId}`}>
            {getLocalDate(verification.timestamp)}
          </a>
        </td>
      </tr>
    </React.Fragment>
  );
}
