import { authenticationConstants } from "../_constants";
import { IAuthenticationAction } from "../interfaces/actions";
import { IAuthenticationState } from "../interfaces/state";

const initialAuthenticationState = {
  token: null,
  profile: null,
};

export function authentication(
  state: IAuthenticationState = initialAuthenticationState,
  action: IAuthenticationAction,
) {
  switch (action.type) {
    case authenticationConstants.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
      };
    case authenticationConstants.LOGIN_FAILURE:
      return {
        ...state,
        token: {},
      };
    case authenticationConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        token: {},
      };
    case authenticationConstants.LOGOUT_FAILURE:
      return {};
    case authenticationConstants.AGENT_PROFILE:
      return {
        ...state,
        profile: action.profile,
      };

    default:
      return state;
  }
}
