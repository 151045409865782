// eslint-disable-next-line
import React from "react";
import { Link } from "react-router-dom";
import { IUserItemProps } from "../../../interfaces/props";
import { getLocalDate, UserStatus } from "../../../_helpers";

export function UserItem({ index, user }: IUserItemProps) {
  return (
    <React.Fragment>
      <tr>
        <td>{index}</td>
        <td>{user.displayName ? user.displayName : "No Available Name"}</td>
        <td style={{ cursor: "pointer" }}>
          <Link to={`/users/${user.uid}`}>{user.email ? user.email : "not provided"}</Link>
        </td>
        <td>{user.emailVerified ? UserStatus.VERIFIED : UserStatus.UNVERIFIED}</td>
        <td>{user.metadata.creationTime ? getLocalDate(user.metadata.creationTime) : "not provided"}</td>
      </tr>
    </React.Fragment>
  );
}
