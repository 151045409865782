import { refreshAuth, history } from "./";
import { AlertAction, AuthenticationAction } from "../_actions";
import { authentication } from "../_reducers/authentication.reducer";

export const handleAxiosDispatch = (
  err,
  errMessage,
  dispatch,
  action,
  failure,
  actionParams = [],
  failureParams = [],
) => {
  const alert = new AlertAction();
  const authentication = new AuthenticationAction();
  if (err.response) {
    switch (err.response.status) {
      case 401:
        (async function () {
          try {
            const googleToken = await refreshAuth();
            const loginFlag = await authentication.reLogin(googleToken);
            loginFlag
              ? dispatch(action(...actionParams))
              : (function () {
                  localStorage.clear();
                  history.push("/login");
                })();
          } catch (e) {
            localStorage.clear();
            history.push("/login");
          }
        })();
        break;
      default:
        failure
          ? (function () {
              dispatch(failure(...failureParams));
              dispatch(alert.fail(errMessage));
            })()
          : dispatch(alert.fail(errMessage));
    }
  } else {
    failure
      ? (function () {
          dispatch(failure(...failureParams));
          dispatch(alert.fail(errMessage));
        })()
      : dispatch(alert.fail(errMessage));
  }
};

export const handleAxios = (err, action, params = []) => {
  if (err.response) {
    switch (err.response.status) {
      case 401:
        (async function () {
          const googleToken = await refreshAuth();
          const loginFlag = await authentication.reLogin(googleToken);
          loginFlag
            ? action(...params)
            : (function () {
                localStorage.clear();
                history.push("/login");
              })();
        })();
        break;
      default:
        return null;
    }
  } else {
    return null;
  }
};
