import {
  faUser,
  faBell,
  faQuestionCircle,
  faSignOutAlt,
  faGlobe,
  faTicketAlt,
  faIdCardAlt,
  faHandshake,
  faVoteYea,
  faThumbtack,
  faCarSide
} from "@fortawesome/free-solid-svg-icons";
import { AuthenticationAction, PartnerAction } from "../_actions";
import { store } from "../_helpers";

const handleLogout = () => {
  const authenticationAction = new AuthenticationAction();
  store.dispatch(authenticationAction.toLogout());
};

const fetchPartners = () => {
  const partnerAction = new PartnerAction();
  store.dispatch(partnerAction.getPartners());
};

export const SideBarComponents = [
  {
    pathname: "/users",
    icon: faUser,
    title: "Users",
  },
  {
    pathname: "/tickets",
    icon: faTicketAlt,
    title: "Tickets",
  },
  {
    pathname: "/verifications",
    icon: faIdCardAlt,
    title: "Verifications",
  },
  {
    pathname: "/orders",
    icon: faThumbtack,
    title: "Orders",
  },
  {
    pathname: "/policies",
    icon: faVoteYea,
    title: "Policies",
  },
  {
    pathname: "/partners",
    action: fetchPartners,
    icon: faHandshake,
    title: "Partners",
  },
  {
    pathname: "/notifications",
    icon: faBell,
    title: "Notifications",
  },
  {
    pathname: "/carlist",
    icon: faCarSide,
    title: "Car List",
  },
  {
    pathname: "/locales",
    icon: faGlobe,
    title: "Localization",
  },
  {
    pathname: "/help",
    icon: faQuestionCircle,
    title: "Help",
  },
  {
    pathname: "/",
    action: handleLogout,
    icon: faSignOutAlt,
    title: "Logout",
  },
];
