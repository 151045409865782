import { reasonConstants } from "../_constants";
import { ICommentAction } from "../interfaces/actions";
import { ICommentState } from "../interfaces/state";

const initialCommentState: ICommentState = {
  comments: [],
};

export function commentList(state: ICommentState = initialCommentState, action: ICommentAction) {
  switch (action.type) {
    case reasonConstants.FETCH_REJECT_REASONS_REQUEST:
      return {
        comments: [],
      };
    case reasonConstants.FETCH_REJECT_REASONS_SUCCESS:
      return {
        comments: action.comments,
      };
    case reasonConstants.FETCH_REJECT_REASONS_FAILURE:
      return {
        comments: [],
      };
    default:
      return state;
  }
}
