import { CarModel } from "../../Models/CarModel";
import { VendorMapper } from "../../Models/VendorMapper";
import { ModelAdapter } from "./ModelAdapter.interface";

export class CarModelAdapter implements ModelAdapter{
  /**
   * @param jsonObject from backend API
   * @returns Instance of CarModel after mapping jsonObject
   */
  fromAPI(jsonObject: any):CarModel {
    const aCarModel = new CarModel();

    aCarModel.key = jsonObject.key;
    aCarModel.name = jsonObject.name;
    
    if (jsonObject["vendorMapper"]) {
      for (const vendorMapperKey of Object.keys(jsonObject["vendorMapper"])) {
        aCarModel.vendorMapper.forEach(element => {
          if (element.vendorKey == vendorMapperKey) {
            element.mappingValue = jsonObject["vendorMapper"][`${vendorMapperKey}`];
          }
        });
      }
    }

    return aCarModel;
  }
  
  /**
   * @param carModel 
   * @return jsonObject as defined by backend APIs after mapping
   */
  toAPI(carModel:CarModel):any {
    const jsonObject: {key?:string, name: any, vendorMapper:any} = {
      name: carModel.name ,
      vendorMapper: {}
    };

    if (carModel.key && carModel.key.length) {
      jsonObject.key = carModel.key;
    }

    if (carModel.vendorMapper) {
      carModel.vendorMapper.forEach(vendorMapper => {
        if (vendorMapper.vendorKey && vendorMapper.mappingValue && vendorMapper.mappingValue.length) {
          jsonObject.vendorMapper[vendorMapper.vendorKey] = vendorMapper.mappingValue;
        }
      });
    }

    return jsonObject;
  }

}