import { Dispatch } from "redux";
import { userConstants, apiConstants } from "../_constants";
import {
  authHeader,
  handleAxiosDispatch,
  history,
  fetchRequest,
  VerificationType,
  VerificationStatus,
  ActionType,
} from "../_helpers";
import { AlertAction, LoadingAction, VerificationAction } from ".";
import {
  ILoadingAction,
  IUsersAction,
  IUserAction,
  IVerificationListAction,
  IAlertAction,
} from "../interfaces/actions";
import { IUserData, IUser, IdData } from "../interfaces/components/user";
import { IVerificationRequest } from "../interfaces/components/verification";

export class UserAction {
  private dispatchUserListRequest = () => {
    return {
      type: userConstants.FETCH_USER_LIST_REQUST,
    };
  };

  private dispatchUserListSuccess = (users: Array<IUserData>) => {
    return {
      type: userConstants.FETCH_USER_LIST_SUCCESS,
      users,
    };
  };

  private dispatchUserListFailure = () => {
    return {
      type: userConstants.FETCH_USER_LIST_FAILURE,
      users: {},
    };
  };

  private dispatchUserItemRequest = () => {
    return {
      type: userConstants.FETCH_USER_ITEM_REQUST,
    };
  };

  private dispatchUserItemSuccess = (user: IUser) => {
    return {
      type: userConstants.FETCH_USER_ITEM_SUCCESS,
      user,
    };
  };

  private dispatchUserItemFailure = () => {
    return {
      type: userConstants.FETCH_USER_ITEM_FAILURE,
    };
  };

  private dispatchData = (idData: IdData | null, type: string) => {
    return {
      type: userConstants.UPDATE_USER_DATA,
      idData,
      verificationType: type,
    };
  };

  private dispatchVerificationState = (type: string) => {
    return {
      type: userConstants.UPDATE_VERIFICATION_STATE,
      rejectType: type,
    };
  };

  private dispatchFailedVerification = (user: IUser) => {
    return {
      type: userConstants.ACTION_FAILURE,
      user,
    };
  };

  private verifyId = (
    verificationRequest: IVerificationRequest,
    dispatch: Dispatch<IUserAction | IVerificationListAction>,
  ) => {
    const verification = new VerificationAction();
    const idData = verificationRequest.info.data?.idData
      ? {
          firstName: verificationRequest.info.data.idData.firstName,
          familyName: verificationRequest.info.data.idData.familyName,
          fullName:
            verificationRequest.info.data.idData.firstName + " " + verificationRequest.info.data.idData.familyName,
          englishName: verificationRequest.info.data.idData.englishName,
          nationalIdNumber: verificationRequest.info.data.idData.nationalIdNumber,
          street: verificationRequest.info.data.idData.street,
          area: verificationRequest.info.data.idData.area,
          address: verificationRequest.info.data.idData.street + " " + verificationRequest.info.data.idData.area,
          dateOfBirth: verificationRequest.info.data.idData.dateOfBirth,
          expiryDate: verificationRequest.info.data.idData.expiryDate,
          gender: verificationRequest.info.data.idData.gender,
          profession: "",
        }
      : null;
    dispatch(this.dispatchData(idData, verificationRequest.info.type));
    dispatch(verification.dispatchStatus(verificationRequest.requestId, VerificationStatus.ACCEPTED));
  };

  private verifyVideo = (
    verificationRequest: IVerificationRequest,
    dispatch: Dispatch<IUserAction | IVerificationListAction>,
  ) => {
    const verification = new VerificationAction();
    dispatch(this.dispatchData(null, verificationRequest.info.type));
    dispatch(verification.dispatchStatus(verificationRequest.requestId, VerificationStatus.ACCEPTED));
  };

  getAll = () => {
    const loading = new LoadingAction();
    return async (dispatch: Dispatch<ILoadingAction | IUsersAction>) => {
      try {
        dispatch(this.dispatchUserListRequest());
        dispatch(loading.isLoading(true));
        const response = await fetchRequest("GET", `${apiConstants.API_GET_USERS}`, authHeader());
        if (response.success) {
          dispatch(this.dispatchUserListSuccess(response.data.users));
          dispatch(loading.isLoading(false));
        } else {
          throw new Error();
        }
      } catch (error) {
        handleAxiosDispatch(error, "Failed to get users", dispatch, this.getAll, this.dispatchUserListFailure);
        dispatch(loading.isLoading(false));
      }
    };
  };

  getByID = (userId: string, flag: boolean) => {
    const loading = new LoadingAction();
    return async (dispatch: Dispatch<ILoadingAction | IUserAction>) => {
      try {
        dispatch(this.dispatchUserItemRequest());
        dispatch(loading.isLoading(true));
        const response = await fetchRequest(
          "GET",
          `${apiConstants.API_GET_USER_ID}/${userId}`,
          authHeader(),
          {},
          { includeStatus: flag },
        );
        if (response.success) {
          dispatch(this.dispatchUserItemSuccess(response.data));
          dispatch(loading.isLoading(false));
        } else {
          throw new Error();
        }
      } catch (error) {
        handleAxiosDispatch(
          error,
          `Failed to get user ${userId}`,
          dispatch,
          this.getByID,
          this.dispatchUserItemFailure,
          [userId, flag],
        );
        dispatch(loading.isLoading(false));
      }
    };
  };

  verify = (verificationRequest: IVerificationRequest, userObject: IUser) => {
    const alert = new AlertAction();
    const verification = new VerificationAction();
    return async (dispatch: Dispatch<IUserAction | IVerificationListAction>) => {
      try {
        const response = await fetchRequest("POST", `${apiConstants.API_VERIFY_USER}`, authHeader(), {
          ...verificationRequest,
        });
        if (response.success) {
          if (verificationRequest.action === ActionType.ACCEPT) {
            if (verificationRequest.info.type === VerificationType.NATIONALID)
              this.verifyId(verificationRequest, dispatch);
            else if (verificationRequest.info.type === VerificationType.VIDEO)
              this.verifyVideo(verificationRequest, dispatch);
            else throw new Error();
            dispatch(alert.pass(`Successfully accept ${verificationRequest.info.type} verification`));
          } else {
            dispatch(this.dispatchVerificationState(verificationRequest.info.type));
            dispatch(verification.dispatchStatus(verificationRequest.requestId, VerificationStatus.REJECTED));
            dispatch(alert.pass(`Successfully reject ${verificationRequest.info.type} verification`));
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        handleAxiosDispatch(
          error,
          "Failed to verify this user",
          dispatch,
          this.verify,
          this.dispatchFailedVerification,
          [verificationRequest, userObject],
          [userObject],
        );
      }
    };
  };

  changeVerificationToPending = (userId: string, verificationId: string) => {
    const alert = new AlertAction();
    const verification = new VerificationAction();

    return async (dispatch: Dispatch<IVerificationListAction>) => {
      try {
        const response = await fetchRequest(
          "PUT",
          `${apiConstants.API_GET_USERS}/${userId}/verification/${verificationId}/editRejected`,
          authHeader(),
        );
        if (response.success) {
          dispatch(verification.dispatchStatus(verificationId, VerificationStatus.PENDING));
          history.push(`/users/${userId}`);
        } else {
          throw new Error();
        }
      } catch (error) {
        dispatch(alert.fail(`Can't update user verification: ${verificationId}`));
      }
    };
  };

  updateData = (userId: string, idData: IdData, verificationId: string, type: string) => {
    const alert = new AlertAction();
    return async (dispatch: Dispatch<IAlertAction | ILoadingAction | IUserAction>) => {
      try {
        const response = await fetchRequest(
          "PUT",
          `${apiConstants.API_GET_USERS}/${userId}/edit/${type}`,
          authHeader(),
          { data: { verificationId, idData } },
        );
        if (response.success) {
          dispatch(alert.pass("Edit User Successfully"));
          dispatch(this.dispatchData(response.data.userData, type));
        } else {
          throw new Error();
        }
      } catch (error) {
        dispatch(alert.fail(`Can't edit user ${userId}`));
      }
    };
  };
}
