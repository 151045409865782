import React, { useEffect, useState } from "react";
import { Button, Input, Modal } from "reactstrap";
import { CarModel } from "../../../Models/CarModel";
import { CarModelService } from "../../../Services/CarListServices/carModelService";
import { CarModelAdapter } from "../../../Services/ModelsAdapter/CarModelAdapter";
import { AlertAction } from "../../../_actions";
import { CarVendorMapper } from "../CarVendorMapper/CarVendorMapper";

import "./CarModelDetails.css";

export function CarModelDetails({carModel, carBrandKey, closeCarModelDetailsDelegate}:{carModel:CarModel | null, carBrandKey:string | undefined, closeCarModelDetailsDelegate:any}) {
  
const [isModalOpened, setIsModalOpened] = useState(true);
const [aCarModel, setACarModel] = useState(new CarModel());
const [loading, setLoading] = useState(false);
const [modelName, setModelName] = useState("");

useEffect(()=>{
    if (!carModel) {
      const newCarModel = new CarModel();
      newCarModel.name = "";
      setACarModel(newCarModel);
    } else {
      const carModelAdapter = new CarModelAdapter();
      const cloneModel = carModelAdapter.fromAPI(carModelAdapter.toAPI(carModel));
      setACarModel(cloneModel);
    }
  },[]);
  
  function closeModal() {
    setIsModalOpened(false);
  }
  
  async function saveModel() {
    try {
      setLoading(true);
      const carBrandService = new CarModelService();
      if (aCarModel.key && aCarModel.key.length) {
        //EDIT model
        carBrandService.editModel(aCarModel, carBrandKey);
      } else {
        //Create new brand
        await carBrandService.addModel(aCarModel, carBrandKey);
      }
      setLoading(false);
      closeModal();
    } catch (error) {
        const alert = new AlertAction();
        alert.fail(JSON.stringify(error));
        setLoading(false);
        closeModal();
    }
  }

  const onModelNameChange = (event:any) => {
    aCarModel.name = event.target.value;
    setModelName((aCarModel.name)?aCarModel.name:"");
  };

  return (
    <Modal
    isOpen={isModalOpened}
    onClosed={closeCarModelDetailsDelegate}
    >
      <div className="container-fluid car-model-details">
        <div className="row" style={{marginBottom:"5px"}}>
          <span className="col-2 my-auto">Model: </span>
          <Input className="col my-auto" type="text" value={aCarModel?.name} onChange={(event) => onModelNameChange(event)}/>
        </div>
        <div className="row">
          <CarVendorMapper vendorMapper={aCarModel.vendorMapper}></CarVendorMapper>
        </div>
        <div className="row" style={{marginTop:"10px"}}>
          <Button className="btn btn-success col" onClick={saveModel} disabled={loading}>Save</Button>
          <Button className="btn btn-warning col" onClick={()=>closeModal()} disabled={loading}>Cancel</Button>
        </div>
      </div>
    </Modal>
  );
}