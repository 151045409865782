import { Dispatch } from "redux";
import { localizationConstants, apiConstants } from "../_constants";
import { authHeader, handleAxiosDispatch, handleAxios, fetchRequest } from "../_helpers";
import { AlertAction, LoadingAction } from ".";
import { ILocalizationAction, ILoadingAction } from "../interfaces/actions";
import { ILocalizationKeys } from "../interfaces/components/localization";

export class LocalizationAction {
  private dispatchRequest = () => {
    return {
      type: localizationConstants.FETCH_LOCALIZATION_LIST_REQUEST,
    };
  };

  private dispatchSuccess = (locales: ILocalizationKeys) => {
    return {
      type: localizationConstants.FETCH_LOCALIZATION_LIST_SUCCESS,
      locales,
    };
  };

  private dispatchFailure = () => {
    return {
      type: localizationConstants.FETCH_LOCALIZATION_LIST_FAILURE,
      locales: {},
    };
  };

  getKeys = () => {
    const loading = new LoadingAction();
    return async (dispatch: Dispatch<ILocalizationAction | ILoadingAction>) => {
      try {
        dispatch(this.dispatchRequest());
        dispatch(loading.isLoading(true));
        const response = await fetchRequest("GET", `${apiConstants.API_GET_LOCALES}`, authHeader());
        if (response.success) {
          dispatch(this.dispatchSuccess(response.data.locales));
          dispatch(loading.isLoading(false));
        } else {
          throw new Error();
        }
      } catch (err) {
        handleAxiosDispatch(err, "Failed to get Locales", dispatch, this.getKeys, this.dispatchFailure);
        dispatch(loading.isLoading(false));
      }
    };
  };

  getDetails = async (id: string) => {
    try {
      const response = await fetchRequest("GET", `${apiConstants.API_GET_LOCALES}/${id}`, authHeader());
      if (response.success) {
        return response.data.fileDetails;
      } else {
        throw new Error();
      }
    } catch (error) {
      return handleAxios(error, this.getDetails, [id]);
    }
  };

  getTranslationTemplate = async (id: string | null) => {
    try {
      const response = await fetchRequest("GET", `${apiConstants.API_GET_TRANSLATION_TEMPLATE}/${id}`, authHeader());
      if (response.success) {
        return response.data.template;
      } else {
        throw new Error();
      }
    } catch (error) {
      return handleAxios(error, this.getTranslationTemplate, [id]);
    }
  };

  upload = (id: string, data: any) => {
    const alert = new AlertAction();
    return async (dispatch: Dispatch<any>) => {
      try {
        await fetchRequest("POST", `${apiConstants.API_UPLOAD_LOCALE}/${id}`, authHeader(), { data });
        dispatch(alert.pass("Successfully upload Locale"));
        dispatch(this.getKeys());
      } catch (error) {
        handleAxiosDispatch(error, `Failed to upload Locale ${id}`, dispatch, this.upload, null, [id, data]);
      }
    };
  };

  remove = (id: string) => {
    const alert = new AlertAction();
    return async (dispatch: Dispatch<any>) => {
      try {
        await fetchRequest("DELETE", `${apiConstants.API_REMOVE_LOCALE}/${id}`, authHeader());
        dispatch(alert.pass("Successfully remove Locale"));
        dispatch(this.getKeys());
      } catch (error) {
        handleAxiosDispatch(error, `Failed to remove Locale ${id}`, dispatch, this.remove, null, [id]);
      }
    };
  };
}
