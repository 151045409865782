/* eslint-disable react/prop-types */
import React, { useState, createContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { SideBar } from "../SideBar";
import { NavBar } from "../Navbar";
import { ErrorBoundary } from "../ErrorBoundary";

export const ToggleContext = createContext({});

export function PrivateRoute({ component: Component, ...rest }) {
  const [isOpen, setOpen] = useState(false); // by defalut sidebar is opened
  const toggle = () => setOpen(!isOpen);

  const ToggleObj = {
    isOpen,
    toggle,
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("tokenId") ? (
          <ToggleContext.Provider value={ToggleObj}>
            {
              <div
                className="container-fluid h-100 d-flex flex-row"
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <SideBar />
                <div className="container-fluid overflow-auto" style={{ minWidth: "800px" }}>
                  <NavBar toggle={ToggleObj.toggle} />
                  <ErrorBoundary>
                    <Component {...props} />
                  </ErrorBoundary>
                </div>
              </div>
            }
          </ToggleContext.Provider>
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  );
}
