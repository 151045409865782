import { Home } from "../Components/Home";
import { UserList, UserPage, UserPageHistory } from "../Components/Users";
import { NotificationList } from "../Components/Notifications";
import { Help } from "../Components/Help";
import { LocalizationList } from "../Components/Localizations";
import { TicketList, TicketPage, TicketCreation } from "../Components/Tickets";
import { VerificationList, VerificationPage } from "../Components/Verifications";
import { PartnerList, PartnerCreation, UserCreation } from "../Components/Partners";
import { PolicyList } from "../Components/Policies";
import { OrderList, OrderPage } from "../Components/Orders";
import { CarListAdministrationPage } from "../Components/CarListAdministration/CarListPage/CarListAdministrationPage";

export const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/users",
    component: UserList,
  },
  {
    path: "/users/:userId",
    component: UserPage,
  },
  {
    path: "/users/:userId/tickets/create",
    component: TicketCreation,
  },
  {
    path: "/users/:userId/verifications/history",
    component: UserPageHistory,
  },
  {
    path: "/users/:userId/verifications/:verificationId",
    component: VerificationPage,
  },
  {
    path: "/notifications",
    component: NotificationList,
  },
  {
    path: "/locales",
    component: LocalizationList,
  },
  {
    path: "/help",
    component: Help,
  },
  {
    path: "/tickets",
    component: TicketList,
  },
  {
    path: "/tickets/:userId/:ticketId",
    component: TicketPage,
  },
  {
    path: "/verifications",
    component: VerificationList,
  },
  {
    path: "/partners",
    component: PartnerList,
  },
  {
    path: "/partners/createPartner",
    component: PartnerCreation,
  },
  {
    path: "/partners/createUser",
    component: UserCreation,
  },
  {
    path: "/orders",
    component: OrderList,
  },
  {
    path: "/orders/:orderId/user/:userId",
    component: OrderPage,
  },
  {
    path: "/policies",
    component: PolicyList,
  },
  {
    path: "/carlist",
    component: CarListAdministrationPage,
  },
];
