// eslint-disable-next-line
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { checkState, UserStatus } from "../../../_helpers";
import { IUserPageVerificationProps } from "../../../interfaces/props";

export function UserPageVerificationEmail({ user }: IUserPageVerificationProps) {
  return (
    <div
      className={"card-body  text-white"}
      style={
        checkState(user.user.emailVerified, true)
          ? { backgroundColor: "darkseagreen", margin: "10px", padding: "10px" }
          : { backgroundColor: "darkgray", margin: "10px", padding: "10px" }
      }
    >
      <h3 className="h6">Email Verification</h3>
      <FontAwesomeIcon icon={user.user.emailVerified ? faCheckCircle : faExclamationCircle} />
      &nbsp;&nbsp;{user.user.emailVerified ? UserStatus.VERIFIED : UserStatus.UNVERIFIED}
    </div>
  );
}
