// eslint-disable-next-line
import React from "react";
import { IDataElement } from "../../interfaces/props";

export function DataElement({ title, value }: IDataElement) {
  return (
    <div className="input-group-text">
      {title ? (
        <span className="text-truncate" style={{ margin: "5px", padding: "5px" }}>
          {title}: {value}
        </span>
      ) : (
        <span className="text-truncate" style={{ margin: "5px", padding: "5px" }}>
          {value}
        </span>
      )}
    </div>
  );
}
