// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { TicketItem } from "..";
import { Loading } from "../../Loading";
import { SearchBar } from "../../SearchBar";
import { NoDataFound } from "../../NoDataFound";
import { isNotEmpty, Sort, TicketStatus, getCurrentDate } from "../../../_helpers";
import { TicketAction } from "../../../_actions";
import { ITicket } from "../../../interfaces/components/ticket";
import { ITicketListProps } from "../../../interfaces/props";
import { ILoadingState, AppState, ITicketListState } from "../../../interfaces/state";
import { PaginationBar } from "../../PaginationBar";
import { DateFilter } from "../../DateFilter";

export function TicketList() {
  const dispatch = useDispatch();
  const ticketAction = new TicketAction();
  const ticketStatus = Object.keys(TicketStatus).map((key) => TicketStatus[key as keyof typeof TicketStatus]);
  const sortingBy = Object.keys(Sort).map((key) => Sort[key as keyof typeof Sort]);

  const pageSize = 10;

  const { tickets, loading }: { tickets: ITicketListState; loading: ILoadingState } = useSelector(
    (state: AppState) => ({
      tickets: state.tickets,
      loading: state.loading,
    }),
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [ticketProps, setTicketProps] = useState<ITicketListProps>({
    flags: {
      status:
        isNotEmpty(tickets.flags) && tickets.flags !== null && typeof tickets.flags === "object"
          ? tickets.flags.status
          : TicketStatus.OPENED,
      orderBy:
        isNotEmpty(tickets.flags) && tickets.flags !== null && typeof tickets.flags === "object"
          ? tickets.flags.sortBy
          : Sort.DESC,
    },
    startDate: tickets.startDate || getCurrentDate().start,
    endDate: tickets.endDate || getCurrentDate().end,
    searchTerm: "",
    searchResults: [],
    pagesCount: tickets.items.length ? Math.ceil(tickets.items.length / pageSize) : 10,
  });

  const handlePageClick = ({ selected: selectedPage }: { selected: number }) => {
    setCurrentPage(selectedPage);
  };

  const handleSearchByEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (isNotEmpty(tickets.items)) {
      const results = tickets.items.filter(
        (ticket: ITicket) =>
          ticket.author.agent?.toLowerCase()?.includes(event.target.value) ||
          ticket.userEmail?.toLowerCase().includes(event.target.value),
      );
      setTicketProps((ticketProps) => ({
        ...ticketProps,
        searchTerm: event.target.value,
        searchResults: [...results],
        pagesCount: Math.ceil(results.length / pageSize),
      }));
      setCurrentPage(0);
    }
  };

  useEffect(() => {
    dispatch(
      ticketAction.getAll(
        ticketProps.flags.status,
        ticketProps.flags.orderBy,
        ticketProps.startDate,
        ticketProps.endDate,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ticketProps.flags, ticketProps.startDate, ticketProps.endDate]);

  const showTickets = (flag: string, value: string) => {
    switch (flag) {
      case "status":
        setTicketProps((ticketProps) => ({
          ...ticketProps,
          flags: { ...ticketProps.flags, status: value as TicketStatus },
        }));
        break;
      case "sort":
        setTicketProps((ticketProps) => ({ ...ticketProps, flags: { ...ticketProps.flags, orderBy: value as Sort } }));
        break;
      default:
        break;
    }
  };

  const renderData = () => {
    if (loading.isLoading) {
      return <Loading />;
    } else {
      if (ticketProps.searchResults.length !== 0) {
        return (
          <div>
            <table className="table table-striped table-bordered text-center">
              <thead>
                <tr>
                  <th>Ticket ID</th>
                  <th>Subject</th>
                  <th>User ID</th>
                  <th>Created by</th>
                  <th>Creation Time</th>
                  <th>Has New Responses</th>
                  <th>State</th>
                </tr>
              </thead>
              <tbody>
                {ticketProps.searchResults
                  .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                  .map((ticket: ITicket, index: number) => (
                    <TicketItem key={index} ticket={ticket}></TicketItem>
                  ))}
              </tbody>
            </table>
            <PaginationBar
              pagesCount={Math.ceil(ticketProps.searchResults.length / pageSize)}
              handlePageClick={handlePageClick}
              pageRangeDisplayed={5}
              marginPagesDisplayed={5}
            />
          </div>
        );
      } else if (
        isNotEmpty(tickets.items) &&
        tickets.items !== null &&
        typeof tickets.items === "object" &&
        tickets.items.length !== 0 &&
        !ticketProps.searchTerm
      ) {
        return (
          <div>
            <table className="table table-striped table-bordered text-center">
              <thead>
                <tr>
                  <th>Ticket ID</th>
                  <th>Subject</th>
                  <th>User ID</th>
                  <th>Created by</th>
                  <th>Creation Time</th>
                  <th>Has New Responses</th>
                  <th>State</th>
                </tr>
              </thead>
              <tbody>
                {tickets.items
                  .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                  .map((ticket: ITicket, index: number) => (
                    <TicketItem key={index} ticket={ticket}></TicketItem>
                  ))}
              </tbody>
            </table>
            <PaginationBar
              pagesCount={Math.ceil(tickets.items.length / pageSize)}
              handlePageClick={handlePageClick}
              pageRangeDisplayed={5}
              marginPagesDisplayed={5}
            />
          </div>
        );
      } else {
        return <NoDataFound data={"No tickets are found"} />;
      }
    }
  };

  const handleFormData = (event: any) => {
    event.preventDefault();
    const startDate: string = (document.getElementById("startDate") as HTMLFormElement).value;
    const endDate: string = (document.getElementById("endDate") as HTMLFormElement).value;
    dispatch(ticketAction.addDateRange(startDate, endDate));
    setTicketProps({ ...ticketProps, startDate, endDate });
  };

  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
      <DateFilter startDate={ticketProps.startDate} endDate={ticketProps.endDate} handleFormData={handleFormData} />
      <nav className="navbar navbar-light bg-light">
        <SearchBar title="Search by author/user email" value={ticketProps.searchTerm} action={handleSearchByEmail} />

        <Dropdown as={ButtonGroup}>
          <Button variant="light">Ticket Status : </Button>
          <Dropdown.Toggle variant="info" id="dropdown-filter">
            {ticketProps.flags.status}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {ticketStatus.map((status: any, index: number) => {
              return (
                <Dropdown.Item key={index} onClick={() => showTickets("status", status)}>
                  {status}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as={ButtonGroup}>
          <Button variant="light">Sort : </Button>
          <Dropdown.Toggle variant="info" id="dropdown-filter">
            {ticketProps.flags.orderBy}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {sortingBy.map((sort: any, index: number) => {
              return (
                <Dropdown.Item key={index} onClick={() => showTickets("sort", sort)}>
                  {sort}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </nav>
      {renderData()}
    </div>
  );
}
