// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { Dropdown, Button, ButtonGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { VerificationItem } from "..";
import { Loading } from "../../Loading";
import { NoDataFound } from "../../NoDataFound";
import { SearchBar } from "../../SearchBar";
import { VerificationAction } from "../../../_actions";
import { isNotEmpty, VerificationType, VerificationStatus } from "../../../_helpers";
import { IVerificationListState, ILoadingState, AppState } from "../../../interfaces/state";
import { IVerificationListProps } from "../../../interfaces/props";
import { IVerificationData } from "../../../interfaces/components/verification";

export function VerificationList() {
  const dispatch = useDispatch();
  const verificationAction = new VerificationAction();

  const {
    verifications,
    loading,
  }: {
    verifications: IVerificationListState;
    loading: ILoadingState;
  } = useSelector((state: AppState) => ({
    verifications: state.verifications,
    loading: state.loading,
  }));

  const [verificationListProps, setVerificationListProps] = useState<IVerificationListProps>({
    flags: {
      type:
        isNotEmpty(verifications.flags) && verifications.flags !== null && typeof verifications.flags === "object"
          ? verifications.flags.type
          : VerificationType.NATIONALID,
      status:
        isNotEmpty(verifications.flags) && verifications.flags !== null && typeof verifications.flags === "object"
          ? verifications.flags.status
          : VerificationStatus.AGENT,
      orderBy:
        isNotEmpty(verifications.flags) && verifications.flags !== null && typeof verifications.flags === "object"
          ? verifications.flags.sortBy
          : "asc",
    },
    append: true,
    newData: true,
    searchTerm: "",
    searchResults: [],
  });

  useEffect(() => {
    dispatch(
      verificationAction.getAll(
        verificationListProps.flags.type,
        verificationListProps.flags.status,
        verificationListProps.flags.orderBy,
        verifications.items,
        verificationListProps.append,
        verificationListProps.newData,
      ),
    );
    setVerificationListProps((verificationListProps) => ({
      ...verificationListProps,
      newData: true,
      searchTerm: "",
      searchResults: [],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, verificationListProps.flags, verificationListProps.append, verificationListProps.newData]);

  const handleSearchByUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (isNotEmpty(verifications.items)) {
      const results = verifications.items.filter(
        (verification: IVerificationData) =>
          verification.userEmail?.toLowerCase().includes(event.target.value) ||
          verification.userName?.toLowerCase().includes(event.target.value),
      );
      setVerificationListProps((verificationListProps) => ({
        ...verificationListProps,
        searchTerm: event.target.value,
        searchResults: [...results],
      }));
    }
  };

  const loadMoreVerifications = () => {
    setVerificationListProps((verificationListProps) => ({
      ...verificationListProps,
      append: true,
      newData: false,
    }));
  };

  const showVerifications = (flag: string, value: string) => {
    switch (flag) {
      case "type":
        setVerificationListProps((verificationListProps) => ({
          ...verificationListProps,
          flags: { ...verificationListProps.flags, type: value },
          append: false,
          newData: false,
        }));
        break;
      case "status":
        setVerificationListProps((verificationListProps) => ({
          ...verificationListProps,
          flags: { ...verificationListProps.flags, status: value },
          append: false,
          newData: false,
        }));
        break;
      case "sort":
        setVerificationListProps((verificationListProps) => ({
          ...verificationListProps,
          flags: { ...verificationListProps.flags, orderBy: value },
          append: false,
          newData: false,
        }));
        break;
      default:
        break;
    }
  };

  const renderData = () => {
    if (loading.isLoading) {
      return (
        <tr>
          <td colSpan={5}>
            <Loading />
          </td>
        </tr>
      );
    } else {
      if (verificationListProps.searchResults.length !== 0) {
        return verificationListProps.searchResults.map((verification: IVerificationData, index: number) => (
          <VerificationItem key={index} verification={verification} index={index}></VerificationItem>
        ));
      }
      if (
        isNotEmpty(verifications.items) &&
        verifications.items !== null &&
        typeof verifications.items === "object" &&
        verifications.items.length !== 0 &&
        !verificationListProps.searchTerm
      ) {
        return verifications.items.map((verification: IVerificationData, index: number) => (
          <VerificationItem key={index} verification={verification} index={index}></VerificationItem>
        ));
      } else {
        return (
          <tr>
            <td colSpan={5}>
              <NoDataFound data={"No Verifications are found"} />
            </td>
          </tr>
        );
      }
    }
  };

  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
      <nav className="navbar navbar-light bg-light">
        <SearchBar
          title="Search by name / email"
          value={verificationListProps.searchTerm}
          action={handleSearchByUser}
        />

        <Dropdown as={ButtonGroup}>
          <Button variant="light">Verification Type : </Button>
          <Dropdown.Toggle variant="info" id="dropdown-split-basic">
            {verificationListProps.flags.type}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => showVerifications("type", VerificationType.NATIONALID)}>
              National ID &nbsp;
            </Dropdown.Item>
            <Dropdown.Item onClick={() => showVerifications("type", VerificationType.VIDEO)}>Video </Dropdown.Item>
            <Dropdown.Item onClick={() => showVerifications("type", VerificationType.All)}>All Types </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as={ButtonGroup}>
          <Button variant="light">Verification Status : </Button>
          <Dropdown.Toggle variant="info" id="dropdown-filter">
            {verificationListProps.flags.status}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => showVerifications("status", VerificationStatus.ACCEPTED)}>
              Accepted
            </Dropdown.Item>
            <Dropdown.Item onClick={() => showVerifications("status", VerificationStatus.REJECTED)}>
              Rejected
            </Dropdown.Item>
            <Dropdown.Item onClick={() => showVerifications("status", VerificationStatus.PENDING)}>
              Pending
            </Dropdown.Item>
            <Dropdown.Item onClick={() => showVerifications("status", VerificationStatus.AGENT)}>Agent</Dropdown.Item>
            <Dropdown.Item onClick={() => showVerifications("status", VerificationStatus.All)}>
              All Status
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as={ButtonGroup}>
          <Button variant="light">Sort : </Button>
          <Dropdown.Toggle variant="info" id="dropdown-filter">
            {verificationListProps.flags.orderBy}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => showVerifications("sort", "asc")}>Ascending</Dropdown.Item>
            <Dropdown.Item onClick={() => showVerifications("sort", "desc")}>Descending</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </nav>

      <table className="table table-striped table-bordered text-center">
        <thead>
          <tr>
            <th>User Email</th>
            <th>User Name</th>
            <th>Status</th>
            <th>Type</th>
            <th>TimeStamp</th>
          </tr>
        </thead>
        <tbody>{renderData()}</tbody>
      </table>

      <Button variant="light" onClick={() => loadMoreVerifications()}>
        Load More Data
      </Button>
    </div>
  );
}
