// eslint-disable-next-line
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { UserPageAction } from "..";
import { checkState, UserStatus, VerificationType } from "../../../_helpers";
import { IUserPageVerificationProps } from "../../../interfaces/props";

export function UserPageVerificationVideo({ user, userId }: IUserPageVerificationProps) {
  const videoState: string = user.video.state;

  return (
    <div
      className={"card-body text-white"}
      style={
        checkState(videoState, UserStatus.VERIFIED)
          ? { backgroundColor: "darkseagreen", margin: "10px", padding: "10px" }
          : { backgroundColor: "darkgray", margin: "10px", padding: "10px" }
      }
    >
      <div>
        <h3 className="text-black h6">Video Verification</h3>
        <FontAwesomeIcon icon={checkState(videoState, UserStatus.VERIFIED) ? faCheckCircle : faExclamationCircle} />
        &nbsp;&nbsp;{videoState}
      </div>

      {checkState(videoState, UserStatus.PENDING) ? (
        <UserPageAction
          userId={userId}
          requestId={user.video.request!.requestId!}
          type={VerificationType.VIDEO}
          timestamp={user.video.request!.timestamp!}
          bucketPath={user.video.request!.bucketPath!}
        />
      ) : null}
    </div>
  );
}
