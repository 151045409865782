import { CarOrigin, CarVendorsEnumForMapping } from "../_helpers";
import { CarModel } from "./CarModel";
import { VendorMapper } from "./VendorMapper";

export class CarBrand {
  key:string | undefined;
  name: string | undefined;
  origin: CarOrigin | undefined;
  models: Array<CarModel> = [];
  vendorMapper: Array<VendorMapper> = [];

  constructor(){ 
    Object.values(CarVendorsEnumForMapping).forEach(element => {
      const vendorMapper = new VendorMapper();
      vendorMapper.vendorKey = element;
      vendorMapper.mappingValue = "";
      this.vendorMapper.push(vendorMapper);
    });
  }

  vendorMapperToString() {
    let mapToString = "";
    this.vendorMapper.forEach(element => {
      if (element.mappingValue && element.mappingValue.length) {
        mapToString += element.vendorKey + " = " + element.mappingValue + " , ";
      }
    });
    return mapToString;
  }
}