// the api's provided from backend
const API_URI = process.env.REACT_APP_API_URI_BACK;

export const apiConstants = {
  API_URI: API_URI,

  API_LOGIN: `${API_URI}/api/google/login`,
  API_LOGOUT: `${API_URI}/api/google/signout`,

  API_GET_USERS: `${API_URI}/api/users`,
  API_GET_USER_ID: `${API_URI}/api/users`,
  API_VERIFY_USER: `${API_URI}/api/users/verify`,

  API_GET_LOCALES: `${API_URI}/api/locales`,
  API_UPLOAD_LOCALE: `${API_URI}/api/locales/upload`,
  API_REMOVE_LOCALE: `${API_URI}/api/locales/remove`,
  API_GET_TRANSLATION_TEMPLATE: `${API_URI}/api/locales/create/template`,

  API_GET_TICKETS: `${API_URI}/api/tickets`,
  API_DOWNLOAD_ATTACHMENT: `${API_URI}/api/tickets/download/attachment`,
  API_CLOSE_TICKET: `${API_URI}/api/tickets/close`,

  API_GET_VERIFICATIONS: `${API_URI}/api/verification`,
  API_GET_NEW_VERIFICATIONS: `${API_URI}/api/verification/new`,
  API_GET_COMMENTS: `${API_URI}/api/verification`,

  API_GET_ORDERS: `${API_URI}/api/order`,
  API_PARTNERS: `${API_URI}/api/partner`,
  API_GET_POLICIES: `${API_URI}/api/policy`,

  API_CREATE_MANUAL_ORDER: `${API_URI}/api/order/manualOrder`,

  API_GET_CAR_LIST: `${API_URI}/carList`,
  API_ADD_BRAND_CAR_LIST: `${API_URI}/carlist/brand`,
  API_EDIT_BRAND_LIST: `${API_URI}/carlist/brand`,
  API_DELETE_BRAND_LIST: `${API_URI}/carlist/brand`,
  API_ADD_MODEL_CAR_LIST: `${API_URI}/carlist/:brand/model`,
  API_EDIT_MODEL_CAR_LIST: `${API_URI}/carlist/:brand/model`,
  API_DELETE_MODEL_CAR_LIST: `${API_URI}/carlist/:brand/model`,
};
