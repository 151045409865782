export const verificationConstants = {
  FETCH_VERIFICATION_LIST_REQUEST: "FETCH_VERIFICATION_LIST_REQUEST",
  FETCH_VERIFICATION_LIST_SUCCESS: "FETCH_VERIFICATION_LIST_SUCCESS",
  FETCH_VERIFICATION_LIST_FAILURE: "FETCH_VERIFICATION_LIST_FAILURE",

  FETCH_USER_VERIFICATIONS_REQUEST: "FETCH_USER_VERIFICATIONS_REQUEST",
  FETCH_USER_VERIFICATIONS_SUCCESS: "FETCH_USER_VERIFICATIONS_SUCCESS",
  FETCH_USER_VERIFICATIONS_FAILURE: "FETCH_USER_VERIFICATIONS_FAILURE",

  FETCH_VERIFICATION_DETAILS_SUCCESS: "FETCH_VERIFICATION_DETAILS_SUCCESS",
  FETCH_VERIFICATION_DETAILS_FAILURE: "FETCH_VERIFICATION_DETAILS_FAILURE",

  UPDATE_VERIFICATION_STATUS: "UPDATE_VERIFICATION_STATUS",
};
