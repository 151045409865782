// eslint-disable-next-line
import React from "react";
import { UserPageVerificationEmail, UserPageVerificationID, UserPageVerificationVideo } from "..";
import { IUserPageVerificationProps } from "../../../interfaces/props";

export function UserPageVerification({ user, userId }: IUserPageVerificationProps) {
  return (
    <div className="container-fluid text-center">
      <UserPageVerificationEmail user={user} userId={userId} />
      <UserPageVerificationID user={user} userId={userId} />
      <UserPageVerificationVideo user={user} userId={userId} />
    </div>
  );
}
