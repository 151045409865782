// eslint-disable-next-line
import React from "react";

interface ISearchBarProps {
  title: string;
  value: string;
  action: Function;
}

export function SearchBar({ title, value, action }: ISearchBarProps) {
  return (
    <form className="form-inline">
      <input
        className="form-control mr-sm-2"
        type="search"
        placeholder={title}
        value={value}
        onChange={(event) => action(event)}
      />
    </form>
  );
}
