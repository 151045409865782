import { CarBrand } from "../../Models/CarBrand";
import { apiConstants } from "../../_constants";
import { authHeader, fetchRequest } from "../../_helpers";
import { CarBrandAdapter } from "../ModelsAdapter/CarBrandAdapter";

export class CarListService {

  async getCarList():Promise<Array<CarBrand>> {
    const carListResponse = await fetchRequest("GET", apiConstants.API_GET_CAR_LIST, authHeader(), {}, {});
    if (carListResponse.success) {
      return this.adaptCarListResponse(carListResponse.data);
    } else {
      throw new Error("Something went wrong");
    }
  }
  
  adaptCarListResponse(apiDataResponse:[]):Array<CarBrand>{
    const brandsList: CarBrand[] = [];
    const aCarBrandAdapter = new CarBrandAdapter();

    if (apiDataResponse) {
      for (const aBrand of apiDataResponse) {
        brandsList.push(aCarBrandAdapter.fromAPI(aBrand));
      }
    }
    return brandsList;
  }

}